'use strict';

const tcomb = require ('tcomb');
const {LocalDateTime, ZonedDateTime, Instant, ZoneId} = require ('@js-joda/core');
const {subtype, alias, instance, laden, enumeration} = require ('../utils/domain');

// eslint-disable-next-line
const ISO8601_MATCH = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;
const URI_MATCH = /^[a-z]+:[/][/].+$/;

const isIso8601 = str => ISO8601_MATCH.test (str);

exports.Instant = instance ('Instant') (Instant);

exports.LocalDateTime = instance ('LocalDateTime') (LocalDateTime);

exports.ZonedDateTime = instance ('ZonedDateTime') (ZonedDateTime);

exports.ZoneId = instance ('ZoneId') (ZoneId);

exports.Buffer = instance ('Buffer') (Buffer);

exports.Sha256 = subtype ('Sha256') (exports.Buffer) ({
  'must be exactly 32 bytes long': x => x.byteLength === 32,
});

exports.Md5 = subtype ('Md5') (exports.Buffer) ({
  'must be exactly 16 bytes long': x => x.byteLength === 16,
});

exports.DateTime = subtype ('DateTime') (tcomb.String) ({
  'must be an Iso8601 formatted datetime': isIso8601,
  'must have time': x => x.includes ('T'),
});

exports.Ymd = subtype ('Ymd') (exports.DateTime) ({
  'may not include time': x => !x.includes ('T'),
});

exports.URI = subtype ('URI') (tcomb.String) ({
  'must look somewhat like a URI': x => URI_MATCH.test (x),
});

exports.Email = subtype ('Email') (tcomb.String) ({
  'cannot exceed 254 characters': x => x.length <= 254,
  'must contain an @ symbol': x => x.includes ('@'),
  'cannot start with an @ symbol': x => !x.startsWith ('@'),
  'cannot end in an @ symbol': x => !x.endsWith ('@'),
});

exports.CompanyName = alias ('CompanyName') (laden (tcomb.String));

exports.HumanName = alias ('HumanName') (laden (tcomb.String));

exports.PositiveNumber = subtype ('PositiveNumber') (tcomb.Number) ({
  'must be zero or greater': x => x >= 0,
});

exports.PositiveInteger = subtype ('PositiveInteger') (tcomb.Integer) ({
  'must be zero or greater': x => x >= 0,
});

exports.Timezone = subtype ('Timezone') (tcomb.String) ({
  'must be a valid timezone': x => ZoneId.getAvailableZoneIds ().includes (x),
});

exports.Latitude = subtype ('Latitude') (tcomb.Number) ({
  'cannot be lower than -90': x => x >= -90,
  'cannot be higher than 90': x => x <= 90,
});

exports.Longitude = subtype ('Longitude') (tcomb.Number) ({
  'cannot be lower than -180': x => x >= -180,
  'cannot be higher than 180': x => x <= 180,
});

exports.UpdateSource = enumeration ('UpdateSource') ({
  box: null,
  agent: null,
  operator: null,
});
