import axios from 'axios';
import {actions} from '../reducers/session';
import {store} from '../store';

axios.defaults.baseURL = process.env.API;

const constructError = data => ({response: {data}});

const unknownError = constructError({
  name: 'UnknownError',
  message: 'An unknown error has occurred',
});

const authError = constructError({
  name: 'AuthError',
  message: 'Your session has expired',
});

const tooManyRequestsError = constructError({
  name: 'TooManyRequestsError',
  message: 'Too many requests',
});

const handleSuccess = response => response;
const handleError = error => {
  if(!error || !error.response) {
    return Promise.reject(unknownError);
  }
  if(error.response.status === 401) {
    store.dispatch(actions.setSession({}));
    return Promise.reject(authError);
  }
  if(error.response.status === 429) {
    return Promise.reject(tooManyRequestsError);
  }
  return Promise.reject(error);
};

axios.interceptors.response.use(handleSuccess, handleError);

export const setupSession = token => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};
