import {always, compose, concat, lensProp, set, over} from 'ramda';
import {createReducer, noopAction} from 'warped-reducers';
import {equal} from '../services/operators.saga';
import {defaultTo, unset} from '../utils/state';

export const baseLens = lensProp('operators');

export const isPendingState = compose(
  baseLens,
  lensProp('isPending'),
  defaultTo(false),
);

export const listState = compose(
  baseLens,
  lensProp('list'),
  defaultTo([]),
);

export const {reducer, types, actions} = createReducer('operators')({
  getOperatorsList: always(compose(
    unset(listState),
    set(isPendingState, true),
  )),
  setOperatorsList: payload => compose(
    set(listState, payload),
    set(isPendingState, false),
  ),
  updateOperator: noopAction,
  setOperator: payload => over(listState, list => list.map(item => (
    equal(item, payload)
      ? Object.assign({}, item, payload)
      : item
  ))),
  createOperator: noopAction,
  addOperator: payload => over(listState, concat([payload])),
});
