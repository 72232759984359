import {createReducer, noopAction} from 'warped-reducers';
import {
  ascend,
  compose,
  dec,
  evolve,
  inc,
  lensProp,
  map,
  over,
  pipe,
  prop,
  set,
  sortWith,
} from 'ramda';

import {defaultTo, normalize, filterLens} from '../utils/state';

export const baseLens = lensProp('voyages');

export const vesselLens = vessel => filterLens(voyage => voyage.vessel === vessel);

export const completeState = compose(
  baseLens,
  lensProp('complete'),
  defaultTo(false),
);

export const voyagesState = compose(
  baseLens,
  lensProp('voyages'),
  defaultTo([]),
  normalize(pipe(
    sortWith([ascend(prop('vessel')), ascend(prop('voyage'))]),
    map(evolve({itinerary: sortWith([ascend(prop('sequence'))])})),
  )),
);

export const updateVesselVoyageState = compose(
  baseLens,
  lensProp('updateVesselVoyage'),
  defaultTo(null),
);

export const updateVesselVoyageErrorState = compose(
  updateVesselVoyageState,
  lensProp('updateVesselVoyageError'),
);

export const activeVoyagesState = compose(
  voyagesState,
  filterLens(voyage => voyage.status !== 'Completed' && voyage.status !== 'Closed'),
);

export const vesselVoyagesState = vessel => compose(
  voyagesState,
  vesselLens(vessel),
);

export const activeVesselVoyagesState = vessel => compose(
  activeVoyagesState,
  vesselLens(vessel),
);

export const loaderState = compose(
  baseLens,
  lensProp('voyagesLoader'),
  defaultTo(0),
);

export const {reducer, types, actions} = createReducer('voyages')({
  loadVoyages: noopAction,
  loadVesselVoyage: noopAction,
  setUpdateVesselVoyage: set(updateVesselVoyageState),
  setUpdateVesselVoyageError: set(updateVesselVoyageErrorState),
  setVoyages: voyages => compose(
    set(voyagesState, voyages),
    set(completeState, true),
  ),
  loadActiveVoyages: noopAction,
  setActiveVoyages: set(activeVoyagesState),
  loadVesselVoyages: noopAction,
  setVesselVoyages: ({vessel, voyages}) => set(vesselVoyagesState(vessel), voyages),
  loadActiveVesselVoyages: noopAction,
  setActiveVesselVoyages: ({vessel, voyages}) => set(activeVesselVoyagesState(vessel), voyages),
  setLoader: up => over(loaderState, up ? inc : dec),
});
