import axios from 'axios';
import {objEq} from './utils';

const equal = objEq(['company', 'cargo', 'port', 'portType', 'contractType']);

const list = () => ({
  type: 'CLIENT_LIST',
  payload: axios.get('/contacts').then(res => res.data),
});

const update = updateObject => ({
  type: 'CLIENT_UPDATE',
  payload: axios.patch('/contacts', updateObject).then(() => updateObject),
});

const setClientColumnFilter = ({field, value}) => ({
  type: 'SET_CLIENT_COLUMN_FILTER',
  payload: {field, value},
});

const clearClientColumnFilter = field => ({
  type: 'CLEAR_CLIENT_COLUMN_FILTER',
  payload: field,
});

export {
  list,
  update,
  equal,
  setClientColumnFilter,
  clearClientColumnFilter,
};

