import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {$gray} from '../styles/variables';

const Flex = styled.div`
  display: flex;
  flex: 1;
`;

const Table = styled(Flex)`
  flex-direction: column;
`;

const Row = styled(Flex)`
  padding: 15px;
  border-bottom: 1px solid ${$gray};
`;

const Cell = styled(Flex)`
  font-size: 0.9em;
`;

const Header = styled(Row)`
  font-weight: bold;
`;

const SimpleTable = ({data, columns, idKey}) => (
  <Table>
    <Header>
      {columns.map(column => <Cell key={`header-col-${column.key}`}>{column.label}</Cell>)}
    </Header>
    {data.map(row => (
      <Row key={idKey ? row[idKey] : row.id}>
        {columns.map(({key, render}) => (
          <Cell key={`row-cell-${row[key]}`}>{render ? render(row[key]) : row[key]}</Cell>
        ))}
      </Row>
    ))}
  </Table>
);

SimpleTable.propTypes = {
  idKey: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    render: PropTypes.func,
  })).isRequired,
};

export default SimpleTable;
