import {put, takeEvery, call} from 'redux-saga/effects';
import axios from 'axios';

import {types, actions} from '../reducers/voyages';
import {actions as errorActions} from '../reducers/error';

const timeout = ms => new Promise(res => setTimeout(res, ms));

const updateVesselVoyages = all => function* ({payload}) {
  const action = all ? actions.setVesselVoyages : actions.setActiveVesselVoyages;
  yield put(actions.setLoader(true));
  try {
    //TEMP: Adding a timeout here to prevent AWS from sending us a 429 status
    yield call(timeout, 1000);
    const response = yield call(axios.get, `/vessels/${payload}/voyages${all ? '?all=1' : ''}`);
    yield put(action({vessel: payload, voyages: response.data}));
  } catch (e) {
    yield put(errorActions.setError(e));
  } finally {
    yield put(actions.setLoader(false));
  }
};

const updateVoyages = all => function* () {
  const action = all ? actions.setVoyages : actions.setActiveVoyages;
  yield put(actions.setLoader(true));
  try {
    const response = yield call(axios.get, `/voyages${all ? '?all=1' : ''}`);
    yield put(action(response.data));
  } catch (e) {
    yield put(errorActions.setError(e));
  } finally {
    yield put(actions.setLoader(false));
  }
};

const getVesselVoyage = () => function* ({payload: {vessel, voyage}}) {
  yield put(actions.setLoader(true));
  yield put(actions.setUpdateVesselVoyageError(null));
  try {
    const response = yield call(axios.get, `/vessels/${vessel}/voyages/${voyage}`);
    yield put(actions.setUpdateVesselVoyage(response.data));
  } catch (e) {
    yield put(actions.setUpdateVesselVoyageError(e?.response?.data ?? 'error'));
  } finally {
    yield put(actions.setLoader(false));
  }
};

export default function* () {
  yield takeEvery(types.loadVoyages, updateVoyages(true));
  yield takeEvery(types.loadVesselVoyage, getVesselVoyage());
  yield takeEvery(types.loadActiveVoyages, updateVoyages(false));
  yield takeEvery(types.loadVesselVoyages, updateVesselVoyages(true));
  yield takeEvery(types.loadActiveVesselVoyages, updateVesselVoyages(false));
}
