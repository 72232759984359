import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {$white} from '../../../styles/variables';
import {SystemStatusTypes} from '../../../shapes';

import SystemDownImage from './systemDown.svg';
import SystemUpImage from './systemUp.svg';
import {formatTimestamp} from '../../../utils/time';
import {ElevatedContainerStyle} from '../../../styles/global';

const systemStatusMap = Object.freeze({
  running: 'System is up & running',
  unknown: 'Unknown',
  down: 'The system is down',
});

const Container = styled.div`
  padding: 25px;
  display: flex;
  background-color: ${$white};
  flex-wrap: wrap;
  ${ElevatedContainerStyle};
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  padding-bottom: 25px;
  align-items: left;
  display: flex;
  align-items: center;
`;

const SystemBox = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  img {
    margin: 16px 0;
  }
  .status {
    font-size: 24px;
    margin-bottom: 8px;
    font-weight: bold;
  }
  .last-sent {
    opacity: 0.5;
  }
`;

const StatusImage = styled.img`
  padding: 0 15px;
`;

const StatusDescription = styled.div`
  flex: 1;
  padding: 0 15px;
`;

const formatLastSend = lastSent => lastSent ? formatTimestamp(lastSent) : '';

const getImgSrc = status => status === SystemStatusTypes.RUNNING ? SystemUpImage : SystemDownImage;

const SystemStatusBox = ({className, status, lastSent}) => (
  <Container className={className}>
    <Title>System status</Title>
    <SystemBox>
      <StatusImage alt="Status" src={getImgSrc(status)} />
      <StatusDescription>
        <div className="status">{systemStatusMap[status]}</div>
        <div className="last-sent">
          Last daily sent: {formatLastSend(lastSent)}
        </div>
      </StatusDescription>
    </SystemBox>
  </Container>
);

SystemStatusBox.propTypes = {
  className: PropTypes.string,
  lastSent: PropTypes.number,
  status: PropTypes.string.isRequired,
};

export default SystemStatusBox;
