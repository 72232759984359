'use strict';

const {LocalDateTime, ZonedDateTime, nativeJs} = require ('@js-joda/core');

const {applyTo} = require ('ramda');
const lazydo = require ('lazy-do');
const {forkCatch, reject, resolve, node, cache, go} = require ('fluture');
const {randomBytes} = require ('crypto');
const {env: flutureEnv} = require ('fluture-sanctuary-types');
const {env: sanctuaryEnv, create: createSanctuary} = require ('sanctuary');
const {env: nodeEnv} = process.env.IS_WEBPACK ? {env: []} : require ('./types');
const avcp = require ('debug') ('avcp');

const fn = module.exports = Object.create (createSanctuary ({
  checkTypes: process.env.NODE_ENV !== 'production',
  env: sanctuaryEnv.concat (flutureEnv, nodeEnv),
}));

fn.log = namespace => {
  const debug = avcp.extend (`debug:${namespace}`);
  const info = avcp.extend (`info:${namespace}`);
  const error = avcp.extend (`error:${namespace}`);
  return {debug, info, error};
};

fn.co = {
  maybe: gen => lazydo (gen, fn.Maybe),
  either: gen => lazydo (gen, fn.Either),
  future: go,
};

const log = fn.log ('global');

fn.encode = encoding => buf => buf.toString (encoding);

fn.randomString = len => fn.map (buf => fn.encode ('hex') (buf.slice (0, len)))
                                (node (done => randomBytes (Math.ceil (len / 2), done)));

fn.pump = fn.flip (fn.pipe);

fn.mapTo = fn.compose (fn.map) (fn.K);

fn.flap = fn.flip (fn.ap);

// has :: String -> StrMap a -> Boolean
fn.has = fn.compose (fn.compose (fn.isJust)) (fn.value);

fn.propEq = k => x => o => fn.equals (x) (fn.prop (k) (o));

fn.addHours = hours => date => new Date (
  date.getTime () + hours * 60 * 60 * 1000,
);

fn.both = predA => predB => x => predA (x) && predB (x);

fn.one = predA => predB => x => predA (x) || predB (x);

fn.xor = a => b => fn.and (!fn.and (a) (b)) (fn.or (a) (b));

fn.conditionally = f => x => fn.boolean (fn.Nothing) (fn.Just (x)) (f (x));

// maybeTo :: (Monoid f, Applicative f) => TypeRep f -> Maybe a -> f a
fn.maybeTo = rep => fn.maybe (fn.empty (rep)) (fn.of (rep));

// alternatively :: (Applicative f, Alt f) => a => f a => f a
fn.alternatively = x => m => fn.alt (fn.of (m.constructor) (x)) (m);

fn.maybeFromNullable = fn.conditionally (x => x !== null);

fn.justOrBust = fn.maybe_ (_ => { throw new Error ('Unsafe usage of Nothing'); }) (fn.I);

fn.error = message => new Error (message);

fn.eitherToFuture = fn.either (reject) (resolve);

fn.maybeToFuture = fn.compose (fn.flip (fn.maybe) (resolve)) (reject);

fn.indent = n => fn.pipe ([
  fn.lines,
  fn.map (fn.concat (' '.repeat (n))),
  fn.unlines,
]);

fn.cacheUntil = f => m => fn.chain (x => f (x) ? fn.cacheUntil (f) (m) : resolve (x)) (cache (m));

fn.toLambda = prepare => main => (...args) => new Promise ((res, rej) => {
  forkCatch (err => {
    log.error ('Unexpected exception:', err.stack);
    rej (fn.error ('An unexpected exception occurred, see lamda logs'));
  }) (rej) (res) (fn.map (prepare) (main (...args)));
});

// Strictly parses a date according to the format determined by the given JSJoda constructor.
fn.parseDateAs = ctr => fn.pipe ([
  fn.unchecked.encase (ctr.parse),
  fn.leftMap (e => { log.error (`Failure to parse a date as ${ctr.name}: ${e.message}`); }),
  fn.eitherToMaybe,
]);

// Loosely parses a date best-effort-wise, and returns an instance of the given JSJoda constructor.
fn.parseDateTo = ctr => fn.pipe ([
  fn.parseDate,
  fn.map (nativeJs),
  fn.map (ctr.from),
]);

fn.parseZonedDateTime = tz => fn.pipe ([
  fn.parseDateAs (LocalDateTime),
  fn.map (ldt => ZonedDateTime.of (ldt, tz)),
]);

fn.assoc = key => value => obj => ({...obj, [key]: value});

fn.Row = values => {
  const row = Object.create (Array.prototype);
  row.constructor = {'@@type': 'avcp/Row@1'};
  values.forEach (item => row.push (item));
  return row;
};

fn.isEmpty = x => fn.equals (x) (fn.empty (x.constructor));

fn.isLaden = fn.complement (fn.isEmpty);

fn.tagEmpty = fn.conditionally (fn.isLaden);

fn.replace = pattern => replacement => str => str.replace (pattern, replacement);

fn.leftMap = fn.flip (fn.bimap) (fn.I);

fn.prepare = sql => rows => applyTo ({sql, values: Array.of (
  fn.unchecked.map (Array.from) (rows),
)});

fn.array2 = f => ([a, b]) => f (a) (b);

fn.unary = f => x => f (x);

fn.chainNothing = func => fn.pipe ([
  fn.maybe_ (func) (fn.pipe ([fn.Just, fn.Just])),
  fn.join,
]);

fn.minBy = f => a => b => fn.boolean (a) (b) (fn.on (fn.lt) (f) (a) (b));

fn.objectWithoutPrototype = o => Object.assign (Object.create (null), o);
