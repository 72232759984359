import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {view} from 'ramda';
import {createStructuredSelector} from 'reselect';

import {Table, PageContainer, FontIcon, Loader, PageTitleSearchBar} from '../../components';
import {equal as operatorEqual} from '../../services/operators.saga';
import {hasWritePermission} from '../../reducers/session';
import {actions as documentActions} from '../../reducers/documentTitle';
import {actions, isPendingState, listState} from '../../reducers/operators';

const Actions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 15px 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Input = styled(TextField)`
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
`;

class Operators extends Component {

  static propTypes = {
    getOperatorsList: PropTypes.func.isRequired,
    updateOperator: PropTypes.func.isRequired,
    createOperator: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired,
    operators: PropTypes.array.isRequired,
    isPending: PropTypes.bool.isRequired,
    hasWritePermission: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.getOperatorsList();
    this.props.setDocumentTitle('Operators');
  }

  constructor(props) {
    super(props);
    this.state = {
      activeEdit: null,
      newOperator: null,
      query: '',
      columns: [
        {prop: 'name', label: 'Operator', width: 30, sortable: true},
        {prop: 'phone', label: 'Phone', width: 30, sortable: true},
        {prop: 'mobile', label: 'Mobile', width: 30, sortable: true},
        {prop: 'icon', label: '', width: 10, align: 'center'},
      ],
    };
  }
  search = query => this.setState({query});
  onEdit = operator => () => this.setState({activeEdit: operator, newOperator: null});

  updateProp = prop => ({target}) => {
    const activeEdit = Object.assign({}, this.state.activeEdit, {[prop]: target.value});
    this.setState({activeEdit});
  };

  inputEdit = (prop, value) => (
    <Input
      multiline
      onChange={this.updateProp(prop)}
      value={value}
    />
  );

  saveEdit = () => {
    // patch
    if (this.state.newOperator) {
      this.props.createOperator(this.state.activeEdit);
    } else {
      this.props.updateOperator(this.state.activeEdit);
    }
    this.setState({newOperator: null, activeEdit: null});
  };
  cancelEdit = () => {
    this.setState({newOperator: null, activeEdit: null});
  };
  addOperator = () => {
    if (this.state.newOperator) {
      return;
    }
    const newOperator = {
      name: '',
      phone: '',
      mobile: '',
    };
    this.setState({
      newOperator,
      activeEdit: newOperator,
    });
  };

  saveIcons = () => (
    <div>
      <FontIcon
        onClick={this.saveEdit}
        value="check"
      />
      <FontIcon
        onClick={this.cancelEdit}
        value="close2"
      />
    </div>
  );

  isActiveEdit = operator => operator === this.state.newOperator
    || operatorEqual(operator, this.state.activeEdit);

  render() {
    const {hasWritePermission} = this.props;
    const tableData = [
      ...this.props.operators,
      this.state.newOperator,
    ].filter(Boolean).map(operator => Object.assign({}, operator,
      this.isActiveEdit(operator)
        ? {
          name: this.inputEdit('name', this.state.activeEdit.name),
          phone: this.inputEdit('phone', this.state.activeEdit.phone),
          mobile: this.inputEdit('mobile', this.state.activeEdit.mobile),
          icon: this.saveIcons(),
          [Table.sortOverride]: operator === this.state.newOperator ? Table.sortEnd : undefined,
          [Table.rawValues]: {
            name: operator.name,
            phone: operator.phone,
            mobile: operator.mobile,
          },
        }
        : {
          icon: hasWritePermission && <FontIcon onClick={this.onEdit(operator)} value="pencil" />,
        },
    ));

    if (this.props.isPending) {
      return <Loader />;
    }

    return (
      <PageContainer>
        <PageTitleSearchBar title="Operators" onSearchChange={this.search} />
        <Content>
          <Table
            data={tableData}
            columns={this.state.columns}
            query={this.state.query}
            onRowClick={this.onRowClickHandler}
          />
          <Actions>
            {hasWritePermission && (
              <Button
                variant="contained"
                disabled={Boolean(this.state.newOperator)}
                onClick={this.addOperator}
              >
                Add
              </Button>
            )}
          </Actions>
        </Content>
      </PageContainer>
    );
  }
}

export default connect(
  createStructuredSelector({
    operators: view(listState),
    isPending: view(isPendingState),
    hasWritePermission,
  }),
  {
    ...actions,
    ...documentActions,
  },
)(Operators);
