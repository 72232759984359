import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Switch} from '@material-ui/core';
import {view} from 'ramda';
import {connect} from 'react-redux';
import {actions, localTimezoneLens} from '../reducers/navigation';
import PageContainer from './PageContainer';
import {$text, $nav} from '../styles/variables';
import {ElevatedBoxShadow} from '../styles/global';

const Container = styled.div`
  background: rgba(255, 255, 255, 1);
  width: 100%;
  position: sticky;
  top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
  ${ElevatedBoxShadow};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 30px;
  color: ${$nav};
`;

const TimeZoneToggle = styled.div`
  margin-left: auto;
  color: ${$text};
`;

const Header = ({title, children, setLocalTimezone, useLocalTimezone}) =>
  <Container>
    <PageContainer>
      <Row>
        {title && <Title>{title}</Title>}
        <TimeZoneToggle>
          <span
            onClick={() => setLocalTimezone(false)}
            style={{cursor: 'pointer'}}
          >
            Port timezone
          </span>
          <Switch
            checked={useLocalTimezone}
            onChange={() => setLocalTimezone(!useLocalTimezone)}
            color="primary"
          />
          <span
            onClick={() => setLocalTimezone(true)}
            style={{cursor: 'pointer'}}
          >
            Your computer&apos;s timezone
          </span>
        </TimeZoneToggle>
      </Row>
      {children && <Row>{children}</Row>}
    </PageContainer>
  </Container>;

Header.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  setLocalTimezone: PropTypes.func.isRequired,
  useLocalTimezone: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    useLocalTimezone: view(localTimezoneLens, state),
  }),
  actions,
)(Header);
