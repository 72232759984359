'use strict';

const tcomb = require ('tcomb');
const {alias, enumeration, model, maybe, subtype, laden} = require ('../utils/domain');
const {PositiveInteger, PositiveNumber, Timezone} = require ('./common');
const fn = require ('../functions');

const add = (a, b) => a + b;

exports.VoyageNumber = alias ('VoyageNumber') (tcomb.Number);

exports.PortName = alias ('PortName') (laden (tcomb.String));

exports.PortOrder = alias ('PortOrder') (tcomb.Number);

exports.PortType = enumeration ('PortType') ({
  load: null,
  discharge: null,
  transit: null,
});

exports.VesselName = alias ('VesselName') (laden (tcomb.String));

exports.BerthName = alias ('BerthName') (laden (tcomb.String));

exports.PortCode = alias ('PortCode') (laden (tcomb.String));

exports.CallNumber = alias ('CallNumber') (tcomb.String);

exports.Protest = alias ('Protest') (tcomb.String);

exports.Cargo = alias ('Cargo') (laden (tcomb.String));

exports.Deadweight = alias ('Deadweight') (PositiveNumber);

exports.IMO = subtype ('IMO') (tcomb.String) ({
  'must contain a numeric value': isFinite,
  'must have at least two characters': x => x.length >= 2,
  'must have a correct checksum digit': x => {
    const checksum = (
      x.slice (0, -1)
      .split ('')
      .map (Number)
      .reverse ()
      .map ((x, i) => x * (i + 2))
      .reduce (add, 0)
    );
    return x % 10 === checksum % 10;
  },
});

exports.TimeField = enumeration ('TimeField') ({
  ETA: {
    isActual: false,
    isEstimation: true,
    description: 'Estimated Time of Arrival',
    correspondsTo: 'ATA',
    order: 1,
    stage: 'Arrival',
  },
  ETB: {
    isActual: false,
    isEstimation: true,
    description: 'Estimated Time of Berthing',
    correspondsTo: 'ATB',
    order: 2,
    stage: 'Berthing',
  },
  ETC: {
    isActual: false,
    isEstimation: true,
    description: 'Estimated Time of Completion',
    correspondsTo: 'ATC',
    order: 3,
    stage: 'Completion',
  },
  ETD: {
    isActual: false,
    isEstimation: true,
    description: 'Estimated Time of Departure',
    correspondsTo: 'ATD',
    order: 4,
    stage: 'Departure',
  },
  ATA: {
    isActual: true,
    isEstimation: false,
    description: 'Actual Time of Arrival',
    correspondsTo: 'ETA',
    order: 1,
    stage: 'Arrival',
  },
  ATB: {
    isActual: true,
    isEstimation: false,
    description: 'Actual Time of Berthing',
    correspondsTo: 'ETB',
    order: 2,
    stage: 'Berthing',
  },
  ATC: {
    isActual: true,
    isEstimation: false,
    description: 'Actual Time of Completion',
    correspondsTo: 'ETC',
    order: 3,
    stage: 'Completion',
  },
  ATD: {
    isActual: true,
    isEstimation: false,
    description: 'Actual Time of Departure',
    correspondsTo: 'ETD',
    order: 4,
    stage: 'Departure',
  },
});

exports.timeFields = fn.keys (exports.TimeField.meta.map);

exports.timeFieldDescription = timeField => (
  exports.TimeField.meta.map[exports.TimeField (timeField)].description
);

exports.timeFieldStage = timeField => (
  exports.TimeField.meta.map[exports.TimeField (timeField)].stage
);

exports.isEstimation = timeField => (
  exports.TimeField.meta.map[exports.TimeField (timeField)].isEstimation
);

exports.isActual = timeField => (
  exports.TimeField.meta.map[exports.TimeField (timeField)].isActual
);

exports.correspondingTimeField = timeField => (
  exports.TimeField.meta.map[exports.TimeField (timeField)].correspondsTo
);

//      timeField :: Pair PortStage TimeIndication -> TimeField
exports.timeField = timeUpdate => {
  const stage = fn.fst (timeUpdate);
  const {type} = fn.snd (timeUpdate);

  return `${type.charAt (0)}T${stage.charAt (0)}`;
};

exports.crementTimefield = by => timeField => {
  const meta = exports.TimeField.meta.map;
  const {order, isActual} = meta[exports.TimeField (timeField)];
  const prev = by (order);
  const pred = fn.both (fn.propEq ('order') (prev)) (fn.propEq ('isActual') (isActual));
  return fn.map (fn.fst) (fn.find (fn.pair (fn.K (pred))) (fn.pairs (meta)));
};

exports.decrementTimefield = exports.crementTimefield (fn.sub (1));

exports.incrementTimefield = exports.crementTimefield (fn.add (1));

exports.Vessel = model ('Vessel') ({
  name: exports.VesselName,
  operatorId: maybe (PositiveInteger),
  imo: exports.IMO,
  deadweight: exports.Deadweight,
  // A vessels is active when it hasn't been sold. A vessel is enabled when the
  // Mailer should send out updates for it. A vessel cannot be inactive whilst
  // also being enabled.
  isEnabled: tcomb.Boolean,
  isActive: tcomb.Boolean,
});

exports.Port = model ('Port') ({
  name: exports.PortName,
  code: exports.PortCode,
  timezone: Timezone,
});
