import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Radio} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {$gray3, $nav} from '../styles/variables';

const Row = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  user-select: none;
  color: ${props => props.disabled ? $gray3 : 'inherit'};
`;

const useStyles = makeStyles({
  root: {
    color: $nav,
  },
  checked: {
    color: $nav,
  },
});

export const StyledRadio = ({onClick, checked, label, disabled}) => {
  const classes = useStyles();
  return (
    <Row disabled={disabled} onClick={() => onClick(!disabled && !checked)}>
      <Radio
        disabled={disabled}
        className={classes.root}
        size="small"
        checked={checked}
        style={{margin: '0 4px 0 0', padding: 0}}
      />
      {label}
    </Row>
  );
};

StyledRadio.propTypes = {
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
