import styled from 'styled-components';

import {EditableText, EditableSelection} from '../../../components';
import {ElevatedContainerStyle} from '../../../styles/global';
import {$white, $black, $gray, $grayLight, $wine} from '../../../styles/variables';

export const FlexWrap = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
`;

export const Flex = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  flex: ${props => props.flex || 1};
`;

export const GridCell = styled.div`
  background-color: ${$white};
  padding: 25px;
`;

export const GridArea = styled(GridCell)`
  ${ElevatedContainerStyle};
  grid-column: ${props => props.half ? 'span 1' : 'span 2'};
`;

export const CellTitle = styled.div`
  font-weight: 600;
  color: ${$black};
  margin-bottom: 24px;
`;

export const Label = styled.span`
  font-size: 12px;
  color: ${$gray};
  padding-bottom: 5px;
`;

export const Value = styled.span`
  font-size: 14px;
  color: ${$black};
  margin-bottom:4px;
`;

export const InputText = styled(EditableText)`
  color: ${$black};
  font-size: 14px;
`;

export const InputSelect = styled(EditableSelection)`
  color: ${$black};
  font-size: 14px;
`;

export const Pre = styled.span`
  white-space: pre-line;
  font-size: 14px;
  color: ${$black};
  overflow-wrap: break-word;
`;

export const Status = styled(Value)`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: 16px;
    fill: ${props => props.color};
  }
`;

export const Link = styled.a`
  padding: 6px 0 7px;
  font-size: 14px;
  width: min-content;

  &:hover {
    text-decoration: underline;
  }

  &:visited,
  &:-webkit-any-link {
    text-decoration-color: purple;
    color: purple;
  }
`;

export const VoyageDetailsContainer = styled(GridArea)`
  background-color: transparent;
  padding-bottom: 8px;
  box-shadow: none;
`;

export const WaypointDetailsContainer = styled(GridArea)`
  grid-column: span 2;
  ${Flex}:not(:last-child) {
    padding-right: 25px;
  }
`;

export const UpdateDetailsContainer = styled(GridArea)`
  box-shadow: none;
  background-color: transparent;
`;

export const TimelineArrow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TimelineBullet = styled.div`
  max-width: 25%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 30px;
  padding-right: 10px;
  border-top: 3px solid ${$grayLight};
  position: relative;

  &:before {
    position: absolute;
    top: -8px;
    left: -2px;
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: ${$grayLight};
  }

  &:last-child {
    border-top-color: transparent;
  }

  /* TEMP: :after is temporary since we do not have the timeline infos comming from the API yet */
  &:after {
    position: absolute;
    top: -6px;
    left: 0;
    content: '';
    width: 8px;
    height: 8px;
    border: 2px solid ${$white};
    border-radius: 100%;
    background-color: ${$white};
  }

  ${props => props.current && `
    &:after,
    ~ ${TimelineBullet}:after {
      position: absolute;
      top: -6px;
      left: 0;
      content: '';
      width: 8px;
      height: 8px;
      border: 2px solid ${$white};
      border-radius: 100%;
      background-color: transparent;
    }
    ~ ${TimelineBullet}:after {
      background-color: ${$white};
    }
  `}

  ${props => props.rejected && `
    ${Value} {
      color: ${$wine};
      text-decoration: underline;
      text-underline-position: under;
    }
  `}
`;

export const ListHeader = styled(FlexWrap)``;

export const ListContainer = styled(GridArea)`
  ${FlexWrap} {
    ${Flex} {
      border-bottom: 1px solid ${$grayLight};
      margin-bottom: 10px;
      padding-bottom: 5px;
      padding-right: 20px;
      box-sizing: border-box;
    }
    &:last-child {
      ${Flex} {
        border-bottom: none;
      }
    }
  }

  ${ListHeader} {
    ${Flex} {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`;

export const Overflow = styled.div`
  overflow: auto;
`;

export const UpdateTitleCell = styled(GridCell)`
  background-color: transparent;
  padding: 16px 25px;
`;

export const UpdateTitle = styled(CellTitle)`
  margin-bottom: 0;
  font-size: 18px;
  text-transform: uppercase;
`;
