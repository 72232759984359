/* eslint-disable max-len */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {prop, view} from 'ramda';
import {createStructuredSelector, createSelector} from 'reselect';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TablePagination,
} from '@material-ui/core';

import {Table, PageContainer, Loader, ConfirmDialog} from '../../components';
import {
  listState, actions, isPendingState, activeTabFilterState,
  columnFilterState, pageNumberState, rowsPerPageState, sortingState,
  selectedItemsState, isArchivingState, archivalErrorsState,
  isConfirmingArchivalState,
} from '../../reducers/updates';
import {formatDateTime, formatTimestamp} from '../../utils/time';
import {
  actions as vesselsActions,
  isPendingState as isVesselsPendingState,
  listState as vesselsListState,
} from '../../reducers/vessels';
import TableFilter from '../../components/Table/TableFilter';
import {UpdatesListField, updatesColumnDefintions, StatusIconMap, TabFilterType} from './constants';
import {actions as documentActions} from '../../reducers/documentTitle';
import {ElevatedContainerStyle} from '../../styles/global';
import {getPortUpdateLabel} from './utils';

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${ElevatedContainerStyle};
`;

const Selection = styled.div`
  display: flex;
  padding: 16px 32px;
  background-color: white;
  border-bottom: 1px solid #eee;
  & > * {
    margin-right: 8px;
  }
`;

const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: 16px;

    fill: ${props => props.color};
  }
`;

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`;

const PaginationContainer = styled.div`
  display:flex;
  justify-content: flex-end;
`;

const WhenEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  opacity: 0.5;
`;

const ArchivalConfirmationDialog = ({amount, onCancel, onConfirm, archiving}) => (
  <ConfirmDialog
    open
    title={`Mark ${amount} update${amount > 1 ? 's' : ''} as ${archiving ? 'processed' : "'To be processed'"}?`}
    description={
      `Are you sure you want to mark the ${amount} selected ` +
      `update${amount > 1 ? 's' : ''} as ${archiving ? 'processed' : "'To be processed'"}?`
    }
    cancelButtonLabel="Cancel"
    confirmButtonLabel={`Mark ${amount} as ${archiving ? 'processed' : "'To be processed'"}`}
    closeDialogHandler={onCancel}
    confirmActionHandler={onConfirm}
  />
);

ArchivalConfirmationDialog.propTypes = {
  amount: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  archiving: PropTypes.bool.isRequired,
};

const ArchivingUpdatesDialog = ({remaining, errors}) => (
  <Dialog
    open
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Marking Updates</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <Loader />
        {`${remaining} updates remaining; ${errors.length} errors occurred`}
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

ArchivingUpdatesDialog.propTypes = {
  remaining: PropTypes.number.isRequired,
  errors: PropTypes.array.isRequired,
};

const viewTableData = createSelector([
  view(listState),
  view(selectedItemsState),
], (list, selectedItems) => (
  list.updates.map(item => {
    const ta = item.portUpdate && item.portUpdate.xtx.Arrival;
    const Icon = StatusIconMap[item.status].icon;
    return Object.assign({}, item, {
      [UpdatesListField.SELECTED]: selectedItems.includes(item.messageId),
      [UpdatesListField.HIGHLIGHT]: !item.read,
      _status: item.status,
      [UpdatesListField.STATUS]: (
        <Status color={StatusIconMap[item.status].color}>
          <Icon /> {StatusIconMap[item.status].label}
        </Status>
      ),
      [UpdatesListField.TIME_OF_ARRIVAL]: ta
        ? `${ta.type.charAt(0).toUpperCase()}TA: ${formatDateTime(ta.time)}`
        : null,
      [UpdatesListField.PORT_UPDATE_TYPE]: (
        item.portUpdate && getPortUpdateLabel(item.portUpdate.type)
      ),
      [UpdatesListField.CREATED_AT]: formatTimestamp(item.createdAt),
      [UpdatesListField.PORT]: item.portUpdate && item.portUpdate.port.name,
      [UpdatesListField.FILE_NAME]: item.fileUpdate ? item.fileUpdate.name : '-',
    });
  })
));

const viewTotalPages = createSelector([view(listState)], list => list.total);

// eslint-disable-next-line complexity
const UpdatesList = ({
  addSelection,
  archivalErrors,
  archiveSelected,
  unarchiveSelected,
  finishArchival,
  clearColumnFilter,
  columnFilters,
  confirmArchival,
  getUpdatesList,
  initColumnFilters,
  isArchiving,
  isConfirmingArchival,
  isVesselsPending,
  isPending,
  getVesselsList,
  vessels,
  pageNumber,
  push,
  removeSelection,
  rowsPerPage,
  selectAll,
  selectNone,
  setColumnFilter,
  setDocumentTitle,
  setPageNumber,
  setRowsPerPage,
  setSorting,
  setTabFilter,
  sorting,
  tabFilter,
  tableData,
  totalPages,
}) => {

  useEffect(() => {
    initColumnFilters();
    getVesselsList();
    getUpdatesList();
    setDocumentTitle('Updates');
    /*
    After getting the fitlered list of updates there is a small chance that the current
    page number is invalid (too high). We could automatically adapt it and
    refetch the updates, but this could lead to an infinite loop when the API returns
    a wrong value for the total amount of items. It is possible too guard against
    this, but for now let's leave it up the user to fix the state through the UI
    (prev page or browser refresh).
    */
  }, []);

  const onHandledTabChange = (_, value) => setTabFilter(value);

  const onRowClickHandler = row => {
    push(`/mailer/${row.messageId}`);
  };

  const onSort = newSortBy => {
    const revert = newSortBy === sorting.sortBy;
    setSorting({
      sortBy: newSortBy,
      isSortAscending: revert
        ? !sorting.isSortAscending
        : sorting.isSortAscending,
    });
  };

  const onFilterChange = (newValue, column) => setColumnFilter({
    columnDef: column,
    value: newValue,
  });

  const definitions = updatesColumnDefintions.filter(c => c.belongsTo.includes(tabFilter));

  const filters = definitions.reduce((acc, column) => ({...acc, [column.prop]: (
    <TableFilter
      {...column.filter}
      type={column.filter.type}
      value={columnFilters[column.prop]}
      onChange={newValue => onFilterChange(newValue, column)}
      onClear={() => clearColumnFilter(column)}
      selectProps={column.filter.selectProps
        ? {
          ...column.filter.selectProps,
          options: column.prop === UpdatesListField.VESSEL
            ? vessels.map(prop('name'))
            : column.filter.selectProps.options}
        : undefined}
    />
  )}), {});

  if (isVesselsPending) {
    return <Loader />;
  }

  const numSelected = tableData.reduce((x, row) => x + Number(row[UpdatesListField.SELECTED]), 0);
  const anySelected = numSelected > 0;
  const amountSelectable = tabFilter === TabFilterType.HANDLED
    ? tableData.filter(row => row._status === 'rejected').length
    : tableData.length;

  const allSelected = anySelected && numSelected === amountSelectable;

  return (
    <PageContainer>
      {isConfirmingArchival && (
        <ArchivalConfirmationDialog
          amount={numSelected}
          archiving={tabFilter === TabFilterType.UNHANDLED}
          onCancel={() => finishArchival()}
          onConfirm={tabFilter === TabFilterType.UNHANDLED
            ? archiveSelected
            : unarchiveSelected}
        />
      )}
      {isArchiving && (
        <ArchivingUpdatesDialog remaining={numSelected} errors={archivalErrors} />
      )}
      <Title>Updates</Title>
      <Tabs value={tabFilter} onChange={onHandledTabChange}>
        <Tab label="To be processed" value={TabFilterType.UNHANDLED} />
        <Tab label="Processed updates" value={TabFilterType.HANDLED} />
        <Tab label="Documents" value={TabFilterType.FILE} />
      </Tabs>
      <Content>
        {tabFilter !== TabFilterType.FILE && (
          <Selection>
            <Checkbox
              checked={allSelected}
              indeterminate={anySelected && !allSelected}
              onClick={() => allSelected ? selectNone() : selectAll(tabFilter === TabFilterType.HANDLED)}
              disabled={amountSelectable === 0}
            />
            <Button
              variant="outlined"
              onClick={() => confirmArchival()}
              disabled={numSelected === 0 || isArchiving}
            >
              Mark {anySelected ? numSelected : ''} as {tabFilter === TabFilterType.HANDLED ? "'To be processed'" : 'processed'}
            </Button>
          </Selection>
        )}
        <Table
          data={tableData}
          columns={definitions}
          onRowClick={onRowClickHandler}
          filters={filters}
          isBodyDataPending={isPending}
          whenEmpty={<WhenEmpty>No updates found. Try expanding your date range.</WhenEmpty>}
          serverSideSorting={{
            sortBy: sorting.sortBy,
            isSortAscending: sorting.isSortAscending,
            onSort: onSort,
          }}
          selectable={tabFilter !== TabFilterType.FILE ? {
            isDisabled: row => tabFilter === TabFilterType.HANDLED && row._status !== 'rejected',
            prop: UpdatesListField.SELECTED,
            onSelect: row => addSelection(row.messageId),
            onDeselect: row => removeSelection(row.messageId),
          } : undefined}
        />
      </Content>
      <PaginationContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          count={totalPages}
          // eslint-disable-next-line react/prop-types
          component={props => <div {...props}>{props.children}</div>}
          rowsPerPage={rowsPerPage}
          page={pageNumber}
          onChangeRowsPerPage={event => setRowsPerPage(event.target.value)}
          onChangePage={(_, newPage) => setPageNumber(newPage)}
        />
      </PaginationContainer>
    </PageContainer>
  );
};

UpdatesList.propTypes = {
  isPending: PropTypes.bool.isRequired,
  isArchiving: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  tableData: PropTypes.array.isRequired,
  getUpdatesList: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  vessels: PropTypes.array.isRequired,
  isVesselsPending: PropTypes.bool.isRequired,
  getVesselsList: PropTypes.func.isRequired,
  tabFilter: PropTypes.string.isRequired,
  setTabFilter: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  columnFilters: PropTypes.object.isRequired,
  setColumnFilter: PropTypes.func.isRequired,
  clearColumnFilter: PropTypes.func.isRequired,
  initColumnFilters: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  sorting: PropTypes.object.isRequired,
  setSorting: PropTypes.func.isRequired,
  addSelection: PropTypes.func.isRequired,
  removeSelection: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  selectNone: PropTypes.func.isRequired,
  archiveSelected: PropTypes.func.isRequired,
  unarchiveSelected: PropTypes.func.isRequired,
  archivalErrors: PropTypes.array.isRequired,
  confirmArchival: PropTypes.func.isRequired,
  finishArchival: PropTypes.func.isRequired,
  isConfirmingArchival: PropTypes.bool.isRequired,
};

export default connect(
  createStructuredSelector({
    totalPages: viewTotalPages,
    tableData: viewTableData,
    isPending: view(isPendingState),
    vessels: view(vesselsListState),
    isVesselsPending: view(isVesselsPendingState),
    tabFilter: view(activeTabFilterState),
    columnFilters: view(columnFilterState),
    pageNumber: view(pageNumberState),
    rowsPerPage: view(rowsPerPageState),
    sorting: view(sortingState),
    isArchiving: view(isArchivingState),
    archivalErrors: view(archivalErrorsState),
    isConfirmingArchival: view(isConfirmingArchivalState),
  }),
  {...actions, ...documentActions, push, ...vesselsActions},
)(UpdatesList);
