import {lensProp, compose, set, always} from 'ramda';
import {createReducer} from 'warped-reducers';

import {defaultTo} from '../utils/state';

const baseLens = lensProp('navigation');

export const userMenuLens = compose(
  baseLens,
  lensProp('userMenuActive'),
  defaultTo(false),
);

export const settingMenuLens = compose(
  baseLens,
  lensProp('settingsMenuActive'),
  defaultTo(false),
);

export const fleetMenuLens = compose(
  baseLens,
  lensProp('fleetMenu'),
  defaultTo({}),
);

export const fleetMenuActiveLens = compose(
  fleetMenuLens,
  lensProp('isActive'),
  defaultTo(false),
);

export const fleetMenuFilterLens = compose(
  fleetMenuLens,
  lensProp('filter'),
  defaultTo(''),
);

export const localTimezoneLens = compose(
  baseLens,
  lensProp('localTimezone'),
  defaultTo(false),
);

export const {reducer, types, actions} = createReducer('navigation')({
  openUserMenu: always(set(userMenuLens, true)),
  closeUserMenu: always(set(userMenuLens, false)),
  openSettingsMenu: always(set(settingMenuLens, true)),
  closeSettingsMenu: always(set(settingMenuLens, false)),
  setFleetFilter: payload => set(fleetMenuFilterLens, payload),
  openFleetMenu: always(set(fleetMenuActiveLens, true)),
  closeFleetMenu: always(set(fleetMenuActiveLens, false)),
  setLocalTimezone: payload => set(localTimezoneLens, payload),
});
