export const compareVoyageNumbers = (a, b, yearDigits) => {
  const voyageA = String(a);
  const voyageB = String(b);
  const [yearA, vA] = [Number(voyageA.slice(0, yearDigits)), Number(voyageA.slice(yearDigits))];
  const [yearB, vB] = [Number(voyageB.slice(0, yearDigits)), Number(voyageB.slice(yearDigits))];
  return yearA === yearB ? vA >= vB : yearA >= yearB;
};

export const filter = [
  {
    name: 'Coral Acropora',
    voyageStart: 202006,
    yearDigits: 4,
  },
  {
    name: 'Coral Actinia',
    voyageStart: 20200006,
    yearDigits: 4,
  },
  {
    name: 'Coral Alameda',
    voyageStart: 202002,
    yearDigits: 4,
  },
  {
    name: 'Coral Alicia',
    voyageStart: 2006,
    yearDigits: 2,
  },
  {
    name: 'Coral Anthelia',
    voyageStart: 202002,
    yearDigits: 4,
  },
  {
    name: 'Coral Encanto',
    voyageStart: 0,
    yearDigits: 4,
  },
  {
    name: 'Coral Energice',
    voyageStart: 202008,
    yearDigits: 4,
  },
  {
    name: 'Coral Energy',
    voyageStart: 202004,
    yearDigits: 4,
  },
  {
    name: 'Coral Favia',
    voyageStart: 2020009,
    yearDigits: 4,
  },
  {
    name: 'Coral Fraseri',
    voyageStart: 202002,
    yearDigits: 4,
  },
  {
    name: 'Coral Fungia',
    voyageStart: 2020006,
    yearDigits: 4,
  },
  {
    name: 'Coral Furcata',
    voyageStart: 202001,
    yearDigits: 4,
  },
  {
    name: 'Coral Ivory',
    voyageStart: 2009,
    yearDigits: 2,
  },
  {
    name: 'Coral Lacera',
    voyageStart: 202004,
    yearDigits: 4,
  },
  {
    name: 'Coral Leaf',
    voyageStart: 20200002,
    yearDigits: 4,
  },
  {
    name: 'Coral Lophelia',
    voyageStart: 202008,
    yearDigits: 4,
  },
  {
    name: 'Coral Meandra',
    voyageStart: 202004,
    yearDigits: 4,
  },
  {
    name: 'Coral Medusa',
    voyageStart: 202002,
    yearDigits: 4,
  },
  {
    name: 'Coral Methane',
    voyageStart: 202003,
    yearDigits: 4,
  },
  {
    name: 'Coral Millepora',
    voyageStart: 202003,
    yearDigits: 4,
  },
  {
    name: 'Coral Monactis',
    voyageStart: 202004,
    yearDigits: 4,
  },
  {
    name: 'Coral Obelia',
    voyageStart: 202003,
    yearDigits: 4,
  },
  {
    name: 'Coral Orinda',
    voyageStart: 202001,
    yearDigits: 4,
  },
  {
    name: 'Coral Parensis',
    voyageStart: 202005,
    yearDigits: 4,
  },
  {
    name: 'Coral Patula',
    voyageStart: 202006,
    yearDigits: 4,
  },
  {
    name: 'Coral Pearl',
    voyageStart: 202006,
    yearDigits: 4,
  },
  {
    name: 'Coral Rigida',
    voyageStart: 20200003,
    yearDigits: 4,
  },
  {
    name: 'Coral Rubrum',
    voyageStart: 19034,
    yearDigits: 2,
  },
  {
    name: 'Coral Shasta',
    voyageStart: 202001,
    yearDigits: 4,
  },
  {
    name: 'Coral Star',
    voyageStart: 202009,
    yearDigits: 4,
  },
  {
    name: 'Coral Sticho',
    voyageStart: 202009,
    yearDigits: 4,
  },
  {
    name: 'JS Ineos Independence',
    voyageStart: 202003,
    yearDigits: 4,
  },
  {
    name: 'Prins Alexander',
    voyageStart: 202001,
    yearDigits: 4,
  },
];
