import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {PageContainer, Loader} from '../../components';
import {createStructuredSelector, createSelector} from 'reselect';
import {view, pipe, groupBy, prop, toPairs, map} from 'ramda';

import {actions, activeVoyagesState, loaderState} from '../../reducers/voyages';
import Header from '../../components/Header';
import Vessel from '../../components/positionsList/Vessel';
import LabelContainer from '../../components/positionsList/LabelContainer';
import {actions as documentActions} from '../../reducers/documentTitle';
import {$orange} from '../../styles/variables';
import RefreshLoader from '../../components/RefreshLoader';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const VesselDiv = styled.div`
  padding-bottom: 16px;
`;

const VesselName = styled(Link)`
  display: inline-flex;
  padding: 13px 0;
  font-weight: bold;
  font-size: 18px;
  color: ${$orange};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const H2 = styled.div`
  margin-right: 8px;
`;

const WithLoader = ({children, isLoading}) => isLoading ? <Loader /> : children;
WithLoader.propTypes = {
  children: PropTypes.element.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export const PositionList = ({vessels, loadActiveVoyages, vesselsLoading, setDocumentTitle}) => {

  useEffect(() => {
    setDocumentTitle('Position list');
    loadActiveVoyages();
  }, []);

  return (
    <div>
      <Header title={(
        <Title>
          <H2>Position list</H2>
          <RefreshLoader isLoading={vesselsLoading} onClick={loadActiveVoyages} />
        </Title>
      )}
      >
        <LabelContainer style={{margin: '20px 0 -10px'}} />
      </Header>
      <PageContainer>
        <Content>
          {
            vessels.map(vessel =>
              <VesselDiv key={vessel.name}>
                <VesselName to={`/vessel/${vessel.name}`}>{vessel.name}</VesselName>
                <Vessel vessel={vessel} />
              </VesselDiv>,
            )
          }
        </Content>
      </PageContainer>
    </div>
  );
};

PositionList.propTypes = {
  vessels: PropTypes.array.isRequired,
  vesselsLoading: PropTypes.bool.isRequired,
  loadActiveVoyages: PropTypes.func.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
};

const selectVessels = createSelector([view(activeVoyagesState)], pipe(
  groupBy(prop('vessel')),
  toPairs,
  map(([name, voyages]) => ({name, voyages})),
));

export default connect(
  createStructuredSelector({
    vessels: selectVessels,
    vesselsLoading: pipe(view(loaderState), Boolean),
  }),
  {
    ...documentActions,
    ...actions,
  },
)(PositionList);
