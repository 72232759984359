import dayjs from 'dayjs';

// values are stored as a function because we want
// to compute them at the moment when we filter / make an API call.
export const DateRange = Object.freeze({
  LEGACY_LAST_5_DAYS: {
    label: 'Last 5 days',
    value: () => ({
      start: dayjs().subtract(5, 'days').toISOString(),
    }),
  },
  LEGACY_UPCOMING_5_DAYS: {
    label: 'Upcoming 5 days',
    value: () => ({
      start: dayjs().add(5, 'days').toISOString(),
    }),
  },
  LAST_24_HOURS: {
    label: 'Last 24 hours',
    value: () => ({
      start: dayjs().subtract(24, 'hour').toISOString(),
    }),
  },
  UPCOMING_24_HOURS: {
    label: 'Upcoming 24 hours',
    value: () => ({
      start: dayjs().add(24, 'hour').toISOString(),
    }),
  },
  LAST_7_DAYS: {
    label: 'Last 7 days',
    value: () => ({
      start: dayjs().subtract(7, 'days').toISOString(),
    }),
  },
  UPCOMING_7_DAYS: {
    label: 'Upcoming 7 days',
    value: () => ({
      start: dayjs().add(7, 'days').toISOString(),
    }),
  },
  LAST_14_DAYS: {
    label: 'Last 14 days',
    value: () => ({
      start: dayjs().subtract(14, 'days').toISOString(),
    }),
  },
  UPCOMING_14_DAYS: {
    label: 'Upcoming 14 days',
    value: () => ({
      start: dayjs().add(14, 'days').toISOString(),
    }),
  },
  ANY_TIME: {
    label: 'Any time',
    value: () => ({
      start: undefined,
      end: undefined,
    }),
  },
});

export const getDateRangeValueForLabel = label =>
  Object.values(DateRange).find(v => v.label === label).value();
