'use strict';

const tcomb = require ('tcomb');
const leven_ = require ('leven');
const {alias, enumeration} = require ('../utils/domain');
const fn = require ('../functions');

const leven = fn.curry2 (leven_);
const leveni = fn.on (leven) (fn.toLower);

const file = module.exports;

file.FileName = alias ('FileName') (tcomb.String);

file.FileType = enumeration ('FileType') ({
  COOLING_LOG: {
    conventionalName: 'Borealis Cooling Log',
  },
  CARGO_DOC: {
    conventionalName: 'Cargo Docs',
  },
  CHARTERERS_EXPENSES: {
    conventionalName: 'Charterer\'s Expenses',
  },
  DEMURRAGE_INVOICE: {
    conventionalName: 'Demurrage Invoice',
  },
  FAIRWAY_DUES: {
    conventionalName: 'Fairway Dues',
  },
  FREIGHT_INVOICE: {
    conventionalName: 'Freight Invoice',
  },
  LAYTIME_CALCULATION: {
    conventionalName: 'Laytime Calculation',
  },
});

file.typesByConventionalName = fn.pump (file.FileType.meta.map) ([
  fn.map (fn.prop ('conventionalName')),
  fn.pairs,
  fn.map (fn.swap),
  fn.fromPairs,
]);

file.getTypeByConventionalName = fn.flip (fn.value) (file.typesByConventionalName);

file.conventionalNames = fn.keys (file.typesByConventionalName);

file.conventionalName = type => file.FileType.meta.map[file.FileType (type)].conventionalName;

// Returns the FileType that most closely matches the input string based on the
// Levenshtein distance to the closest conventional file name.
file.extractFileTypeFromName = fn.pipe ([
  tcomb.String,
  fn.flip (fn.map (a => b => fn.Pair (a) (leveni (a) (b))) (file.conventionalNames)),
  fn.reduce (fn.minBy (fn.snd)) (fn.Pair ('') (Infinity)),
  fn.fst,
  file.getTypeByConventionalName,
  fn.justOrBust,
  file.FileType,
]);
