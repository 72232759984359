import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {view} from 'ramda';
import {createStructuredSelector, createSelector} from 'reselect';
import RefreshIcon from '@material-ui/icons/Refresh';

import UpdatesStats from './components/UpdatesStats';
import NoUpdatesList from './components/NoUpdatesList';
import SystemStatusBox from './components/SystemStatusBox';
import {SystemStatusTypes, StatusShape} from '../../shapes';
import {PageContainer, PageTitleSearchBar, Loader} from '../../components';
import {
  actions as overviewActions,
  isPendingState,
  statusState,
  dataState,
  lastFetchDateState,
  idleVesselsState,
} from '../../reducers/overview';
import {$blue2} from '../../styles/variables';
import {formatTimestamp} from '../../utils/time';
import {actions as documentActions} from '../../reducers/documentTitle';
import {ElevatedContainerStyle} from '../../styles/global';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-areas:
    "updates vessels"
    "noUpdates vessels"
    "systemStatus vessels";
`;

const Updates = styled(UpdatesStats)`
  grid-area: updates;
  ${ElevatedContainerStyle};
`;

const NoUpdates = styled(NoUpdatesList)`
  grid-area: noUpdates;
  ${ElevatedContainerStyle};
`;

const SystemStatus = styled(SystemStatusBox)`
  grid-area: systemStatus;
`;

const Vessels = styled.div`
  grid-area: vessels;
  background-color: white;
  ${ElevatedContainerStyle};
`;

const Refresh = styled.span`
  display: inline-flex;
  font-style: normal;
  color: ${$blue2};
  margin-left: 20px;
  position: absolute;
  margin-top: 6px;

  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
`;

const RefreshIconStyled = styled(RefreshIcon)`
  height: 16px;
`;

const Header = styled.div`
  display: flex;
`;

const Title = styled(PageTitleSearchBar)`
  flex: 1;
`;

class Overview extends React.Component {
  static propTypes = {
    getCurrentStatusData: PropTypes.func.isRequired,
    getIdleVessels: PropTypes.func.isRequired,
    setLastFetchDate: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired,
    status: PropTypes.oneOf(Object.values(SystemStatusTypes)).isRequired,
    data: StatusShape,
    isPending: PropTypes.bool,
    lastFetchDate: PropTypes.string,
    idleVessels: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      lastUpdateAt: PropTypes.string,
    })),
  }

  componentDidMount() {
    this.fetchData();
    this.interval = setInterval(this.fetchData, 5000 * 60);
    this.props.setDocumentTitle('Overview');
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchData = () => {
    this.props.getCurrentStatusData();
    this.props.getIdleVessels();
    this.props.setLastFetchDate(dayjs().format('HH:mm on DD/MM/YYYY'));
  }

  refresh = () => {
    clearInterval(this.interval);
    this.fetchData();
    this.interval = setInterval(this.fetchData, 5000 * 60);
  }

  render() {
    const {status, data, lastFetchDate, isPending, idleVessels} = this.props;
    const lastFetch = lastFetchDate ? `Last update at ${lastFetchDate}` : '';

    const idleColumns = [{
      key: 'name',
      label: 'Vessel name',
    }, {
      key: 'lastUpdateAt',
      label: 'No update since',
      render: val => val ? formatTimestamp(val) : '-',
    }];

    const updatesData = ([
      {
        id: 'rejected',
        label: 'Rejected',
        value: data.rejected,
        color: '#004a88',
      },
      {
        id: 'warnings',
        label: 'Warnings',
        value: data.warnings,
        color: '#478bbf',
      },
      {
        id: 'accepted',
        label: 'Accepted',
        value: data.accepted,
        color: '#72acdb',
      },
    ]);

    return (
      <PageContainer>
        <Header>
          <Title
            title="Overview"
            subtitle={(
              <>
                {lastFetch}
                {!isPending && (
                  <Refresh onClick={this.refresh}>
                    <RefreshIconStyled /> Refresh
                  </Refresh>
                )}
              </>
            )}
          />
        </Header>
        {isPending && <Loader />}
        <Grid>
          <Updates data={updatesData} />
          <NoUpdates data={idleVessels} columns={idleColumns} />
          <SystemStatus status={status} lastSent={data.lastSent} />
          <Vessels data={[]} />
        </Grid>
      </PageContainer>
    );
  }
}

const isPendingSelector = createSelector([
  view(isPendingState),
], (a, b) => a || b);

export default connect(
  createStructuredSelector({
    isPending: isPendingSelector,
    status: view(statusState),
    data: view(dataState),
    idleVessels: view(idleVesselsState),
    lastFetchDate: view(lastFetchDateState),
  }),
  {
    ...overviewActions,
    ...documentActions,
  },
)(Overview);
