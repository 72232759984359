'use strict';

const tcomb = require ('tcomb');
const type = require ('sanctuary-type-identifiers');
const Z = require ('sanctuary-type-classes');

const builtins = [
  'Any',
  'Boolean',
  'Date',
  'Error',
  'Function',
  'Integer',
  'Number',
  'Object',
  'RegExp',
  'String',
  'Type',
];

const alwaysTrue = _ => true;
const isLaden = x => !Z.equals (x, Z.empty (x.constructor));

const joiners = [
  ', and ',
  ', also, ',
  ', furthermore, ',
  ', moreover, ',
  ', in addition, ',
  ', additionally, ',
  ', not to mention, ',
  ', likewise, ',
];

const randomJoiner = () => joiners[Math.round (Math.random () * joiners.length)];

Object.assign (exports, Object.fromEntries (builtins.map (k => [k, tcomb[k]])));

exports.constant = value => (
  tcomb.irreducible (`Constant (${value})`, x => x === value)
);

exports.alias = name => Super => (
  tcomb.refinement (Super, alwaysTrue, name)
);

exports.dictionary = Key => Value => (
  tcomb.dict (Key, Value, `Dictionary (${Key.meta.name}) (${Value.meta.name})`)
);

exports.enumeration = name => strmap => (
  tcomb.enums (strmap, name)
);

exports.union = name => models => (
  tcomb.union (models, name)
);

exports.list = Inner => (
  tcomb.list (Inner, `List (${Inner.meta.name})`)
);

exports.laden = Super => (
  tcomb.refinement (Super, isLaden, `Laden (${Super.meta.name})`)
);

exports.instance = name => ctr => (
  tcomb.irreducible (name, x => x instanceof ctr)
);

exports.struct = name => properties => (
  tcomb.interface (properties, {name, strict: false})
);

exports.model = name => properties => (
  tcomb.interface (properties, {name, strict: true})
);

exports.submodel = name => Super => properties => (
  Super.extend (properties, {name, strict: true})
);

exports.subtype = name => Supertype => validations => {
  const predicates = Object.values (validations);
  const messages = Object.keys (validations);
  const validate = value => predicates.every (predicate => predicate (value));
  const Type = tcomb.refinement (Supertype, validate, name);

  Type.getValidationErrorMessage = value => {
    const errors = messages.filter (message => {
      try {
        return !validations[message] (value);
      } catch (e) {
        return true;
      }
    });
    if (errors.length === 0) {
      return undefined;
    }
    if (errors.length === 1) {
      return errors[0];
    }
    const head = errors.slice (0, -1).map (message => `${message}${randomJoiner ()}`).join ('');
    const tail = errors.slice (-1)[0];
    return head + tail;
  };

  return Type;
};

exports.Maybe = tcomb.irreducible ('Maybe', x => type (x) === 'sanctuary-maybe/Maybe@1');

exports.maybe = Inner => tcomb.refinement (
  tcomb.interface ({value: tcomb.maybe (Inner)}),
  exports.Maybe.is,
  `Maybe (${Inner.meta.name})`,
);

exports.Pair = tcomb.irreducible ('Pair', x => type (x) === 'sanctuary-pair/Pair@1');

exports.pair = First => Second => tcomb.refinement (
  tcomb.interface ({fst: First, snd: Second}),
  exports.Pair.is,
  `Pair (${First.meta.name}) (${Second.meta.name})`,
);

exports.Null = exports.constant (null);

exports.nullable = Inner => {
  const Type = exports.union ('Nullable') ([Inner, exports.Null]);
  Type.dispatch = x => x === null ? exports.Null : Inner;
  return Type;
};

exports.Undefined = exports.constant (undefined);

exports.optional = Inner => {
  const Type = exports.union ('Optional') ([Inner, exports.Undefined]);
  Type.dispatch = x => x === undefined ? exports.Undefined : Inner;
  return Type;
};
