import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import {ago} from 'time-ago';
import PropTypes from 'prop-types';
import {Icon} from '@material-ui/core/';
import {clamp} from 'ramda';
import {ZonedDateTime, Duration} from '@js-joda/core';
import {$nav, $avOrange} from '../styles/variables';
import DateText from '../components/DateText';
import ClickPopover from '../components/ClickPopover';
import WaypointFunction from './WaypointFunction';

const tGray = '#d8d8d8';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  padding: 24px 80px;
  overflow-x: auto;
`;

const statusWidth = 26;
const Segment = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 80px;
  min-width: 100px;

  &:after {
    content: '';
    height: 3px;
    background: ${tGray};
    left: 0;
    top: 50%;
    position: absolute;
    z-index: 1;
    width: 100%;
  }

  &:last-of-type {
    width: ${statusWidth}px;
    min-width: ${statusWidth}px;
  }

  &:last-of-type:after {
    display: none;
  }
`;

const VoyageProgressContainer = styled.div`
  position: absolute;
  width: calc(100% - 26px);
  height: 3px;
  z-index: 2;
  left: 26px;
  top: 50%;
`;

const VoyageProgressBar = styled.div`
  height: 100%;
  background: ${tGray};
  background: ${props => props.voyageProgressUnknown || !props.showProgress
    ? 'transparent'
    : $nav};
  border: 1.5px dashed ${props => props.voyageProgressUnknown ? $nav : 'transparent'};
  opacity: ${props => props.voyageProgressUnknown ? 0.8 : 1};
  width: ${props => (props.voyageCompleted || props.voyageProgressUnknown)
    ? '100%'
    : `${props.voyageProgress}%`};
`;

const VoyageProgress = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 1;
  background: ${$nav};
  height: 9px;
  width: 9px;
  border-radius: 50%;
  transform: translateX(-4px);
  left: ${props => props.voyageProgress}%;
`;

const Port = styled.div`
  position: absolute;
  font-size: 14px;
  top: ${props => props.isEven ? '0' : '75%'};
  transform: translateX(calc(-50% + ${statusWidth / 2}px));
  width: max-content;
`;

const VoyageNumber = styled.div`
  color: ${$nav};
  position: absolute;
  left: 0;
  top: -24px;
  font-size: 14px;
  transform: translateX(calc(-50% + ${statusWidth / 2}px));
  z-index: 9;
`;

const OrangeClickable = styled.a`
  color: ${$avOrange};
`;

const Status = styled.div`
  width: ${statusWidth}px;
  height: ${statusWidth}px;
  background: ${props => props.isActive ? $nav : tGray};
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 10;

  > * {
    pointer-events: none;
  }
`;

const Updated = styled.div`
  margin-top: 8px;
  color: ${$nav};
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 8px;
  &:not(:first-child){
    margin-top: 16px;
  }

  font-weight: 500;
  font-size: 16px;
`;

const Row = styled.div`
  display: flex;
`;

const statusMap = Object.assign(Object.create(null), {
  L: 'arrow_downward',
  D: 'arrow_upward',
});

const Column = styled.div`
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
`;

const Label = styled.div`
  color: rgb(76, 85, 99);
  font-weight: 600;
  text-transform: uppercase;
`;

const Value = styled.div``;

const UpdatedAt = ({source, updatedAt}) => (
  <Updated>
    {updatedAt ? `Last updated in ${source}, ${ago(updatedAt)}` : 'No updates received'}
  </Updated>
);

UpdatedAt.propTypes = {
  updatedAt: PropTypes.string,
  source: PropTypes.string,
};

const scheduleShape = {
  eta: PropTypes.string,
  etb: PropTypes.string,
  etc: PropTypes.string,
  etd: PropTypes.string,
  ata: PropTypes.string,
  atb: PropTypes.string,
  atc: PropTypes.string,
  atd: PropTypes.string,
};

const itineraryShape = {
  portName: PropTypes.string.isRequired,
  waypointFunction: PropTypes.string.isRequired,
  waypointFunctionName: PropTypes.string,
  itineraryIdx: PropTypes.number.isRequired,
  agent: PropTypes.object,
  updatedAt: PropTypes.string,
  latestSchedule: PropTypes.shape(scheduleShape).isRequired,
  confirmedSchedule: PropTypes.shape(scheduleShape).isRequired,
};

//eslint-disable-next-line complexity
const ItineraryPopover = ({itinerary, setPopover, anchorEl, isOpen}) => {
  const arrivalTime = itinerary.confirmedSchedule.ata || itinerary.confirmedSchedule.eta;
  const berthingTime = itinerary.confirmedSchedule.atb || itinerary.confirmedSchedule.etb;
  const departureTime = itinerary.confirmedSchedule.atd || itinerary.confirmedSchedule.etd;

  return (
    <ClickPopover
      isOpen={isOpen}
      setPopover={setPopover}
      anchorEl={anchorEl}
    >
      <Header>Position details</Header>
      <Row>
        <Column>
          <Label>Type</Label>
          {Boolean(arrivalTime) &&
            <Label>{itinerary.confirmedSchedule.ata ? 'ATA' : 'ETA'}</Label>}
          {Boolean(berthingTime) &&
            <Label>{itinerary.confirmedSchedule.atb ? 'ATB' : 'ETB'}</Label>}
          {Boolean(departureTime) &&
            <Label>{itinerary.confirmedSchedule.atd ? 'ATD' : 'ETD'}</Label>}
        </Column>
        <Column>
          <Value>
            <WaypointFunction
              waypointFunction={itinerary.waypointFunction}
              waypointFunctionName={itinerary.waypointFunctionName}
            />
          </Value>
          {Boolean(arrivalTime) &&
            <Value><DateText date={ZonedDateTime.parse(arrivalTime)} /></Value>}
          {Boolean(berthingTime) &&
            <Value><DateText date={ZonedDateTime.parse(berthingTime)} /></Value>}
          {Boolean(departureTime) &&
            <Value><DateText date={ZonedDateTime.parse(departureTime)} /></Value>}
        </Column>
      </Row>
      <UpdatedAt source="IMOS" updatedAt={itinerary.updatedAt} />
      <Header>Agent details</Header>
      {itinerary.agent ? (
        <>
          <Row>
            <Column>
              <Label>country</Label>
              <Label>city</Label>
              <Label>zipcode</Label>
              <Label>email</Label>
              <Label>fax</Label>
              <Label>phone</Label>
              <Label>mobile</Label>
              <Label>name</Label>
            </Column>
            <Column>
              <Value>{itinerary.agent.country || 'Unknown'}</Value>
              <Value>{itinerary.agent.city || 'Unknown'}</Value>
              <Value>{itinerary.agent.postcode || 'Unknown'}</Value>
              <Value>{itinerary.agent.email || 'Unknown'}</Value>
              <Value>{itinerary.agent.fax || 'Unknown'}</Value>
              <Value>{itinerary.agent.phone || 'Unknown'}</Value>
              <Value>{itinerary.agent.mobile || 'Unknown'}</Value>
              <Value>{itinerary.agent.name || 'Unknown'}</Value>
            </Column>
          </Row>
          <UpdatedAt source="S5" updatedAt={itinerary.agent.updatedAt} />
        </>
      ) : (
        <Row>
          <OrangeClickable
            href="https://anthonyveder.app.box.com/s/tu7b93hd8cgymoygbptz00637v2o85bg"
            target="blank"
          >
            Open Spreadsheet in Box
          </OrangeClickable>
        </Row>
      )}
    </ClickPopover>
  );
};

ItineraryPopover.propTypes = {
  itinerary: PropTypes.shape(itineraryShape),
  setPopover: PropTypes.func,
  anchorEl: PropTypes.any,
  isOpen: PropTypes.bool,
};

const SegmentCmp = ({
  itinerary,
  voyage,
  nextSegmentHasArrival,
  nextSegmentEta,
  voyageProgress,
  isLast,
  isEven,
  isFirst,
  isFirstOfItinerary,
}) => {
  const anchorEl = useRef(null);
  const [isPopover, setPopover] = useState(false);

  const showVoyageProgress = Boolean(nextSegmentEta) &&
    !nextSegmentHasArrival &&
    Boolean(itinerary.confirmedSchedule.atd) &&
    voyageProgress > 0;

  return (
    <Segment>
      <Port isEven={isEven} first={isFirst} last={isLast}>{itinerary.portName}</Port>
      {isFirstOfItinerary && <VoyageNumber first={isFirst}>{voyage}</VoyageNumber>}
      <VoyageProgressContainer>
        <VoyageProgressBar
          voyageCompleted={nextSegmentHasArrival}
          voyageProgress={voyageProgress}
          showProgress={itinerary.confirmedSchedule.atd && nextSegmentEta}
          voyageProgressUnknown={itinerary.confirmedSchedule.atd && !nextSegmentEta && !isLast}
        />
        {showVoyageProgress && <VoyageProgress voyageProgress={voyageProgress} />}
      </VoyageProgressContainer>
      <Status
        style={{cursor: 'pointer'}}
        onClick={() => setPopover(!isPopover)}
        ref={anchorEl}
        isActive={itinerary.confirmedSchedule.ata}
        first={isFirst}
        last={isLast}
      >
        <Icon style={{fontSize: 18}}>
          {itinerary.confirmedSchedule.atd
            ? 'checkmark'
            : (statusMap[itinerary.waypointFunction] || 'arrow_forward')}
        </Icon>
      </Status>

      <ItineraryPopover
        itinerary={itinerary}
        isOpen={isPopover}
        setPopover={setPopover}
        anchorEl={anchorEl}
      />
    </Segment>
  );
};

SegmentCmp.propTypes = {
  itinerary: PropTypes.shape(itineraryShape),
  voyageProgress: PropTypes.number.isRequired,
  voyage: PropTypes.number.isRequired,
  nextSegmentEta: PropTypes.string,
  nextSegmentHasArrival: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  isEven: PropTypes.bool.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isFirstOfItinerary: PropTypes.bool.isRequired,
};

const getVoyageProgress = (atd, eta) => {
  if(!atd || !eta) {
    return 0;
  }

  const currentAtd = ZonedDateTime.parse(atd);
  const nextEta = ZonedDateTime.parse(eta);
  const now = ZonedDateTime.now();

  const voyageDuration = Duration.between(currentAtd, nextEta).toMillis();
  const voyageUntilNow = Duration.between(currentAtd, now).toMillis();
  return Math.round(voyageUntilNow / voyageDuration * 100);
};

const VoyageTimeline = ({voyages}) => {
  const voyageSegments = voyages.map(({voyage, itinerary}) =>
    itinerary.map((it, itineraryIdx) => ({...it, voyage, itineraryIdx}))).flat();

  return (
    <Container>
      {voyageSegments.map((segment, i) => {
        const isFirst = i === 0;
        const isLast = i === voyageSegments.length - 1;
        const isEven = i % 2 === 0;
        const isFirstOfItinerary = !voyageSegments[i - 1] ||
        (segment.voyage !== voyageSegments[i - 1].voyage);
        const nextSegmentHasArrival = voyageSegments[i + 1]
          ? Boolean(voyageSegments[i + 1].confirmedSchedule.ata)
          : false;
        const nextSegmentEta = voyageSegments[i + 1]
          ? voyageSegments[i + 1].confirmedSchedule.eta
          : null;
        const voyageProgress = (
          clamp(5, 95, getVoyageProgress(segment.confirmedSchedule.atd, nextSegmentEta))
        );

        return (
          <SegmentCmp
            key={i}
            itinerary={segment}
            voyage={segment.voyage}
            nextSegmentHasArrival={nextSegmentHasArrival}
            voyageProgress={voyageProgress}
            nextSegmentEta={nextSegmentEta}
            isLast={isLast}
            isEven={isEven}
            isFirst={isFirst}
            isFirstOfItinerary={isFirstOfItinerary}
          />
        );
      })}
    </Container>
  );
};

VoyageTimeline.propTypes = {
  voyages: PropTypes.array.isRequired,
};

export default VoyageTimeline;
