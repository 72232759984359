import dayjs from 'dayjs';

const dateTimeFormat = 'DD/MM/YYYY HH:mm';

export const formatDateTime = ISO8601Date =>
  dayjs(ISO8601Date).format(dateTimeFormat);

export const formatTimestamp = epochMilli =>
  dayjs.unix(epochMilli / 1000).format(dateTimeFormat);

export const daysOfWeek = Object.freeze([
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]);

export const months = Object.freeze([
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]);
