import {put, takeEvery} from 'redux-saga/effects';
import axios from 'axios';
import {types} from '../reducers/overview';

const getCurrentStatusData = function* () {
  try {
    const response = yield axios.get('/status');
    yield put({type: types.setCurrentStatusData, payload: response.data});
  } catch (e) {
    yield put({type: types.setCurrentStatusDataError, message: e.message});
  }
};

const getIdleVessels = function* () {
  try {
    const response = yield axios.get('/vessels/idle');
    yield put({type: types.setIdleVessels, payload: response.data});
  } catch (e) {
    yield put({type: types.setIdleVesselsError, message: e.message});
  }
};

const currentStatusDetector = function* ({type, message}) {
  if ((type && type.match(/_REJECTED/)) || (message && message.match(/Network Error/))) {
    yield put({type: types.setDown});
  }

  if (type && type.match(/_FULFILLED/)) {
    yield put({type: types.setRunning});
  }
};

export default function* () {
  yield takeEvery(types.getCurrentStatusData, getCurrentStatusData);
  yield takeEvery(types.getIdleVessels, getIdleVessels);
  yield takeEvery('*', currentStatusDetector);
}
