import {all, put} from 'redux-saga/effects';
import {actions as errorActions} from '../reducers/error';

import updatesSaga from './updates.saga';
import overviewSaga from './overview.saga';
import voyagesSaga from './voyages.saga';
import vesselSaga from './vessel.saga';
import checklistsSaga from './checklists.saga';
import vesselsSaga from './vessels.saga';
import operatorsSaga from './operators.saga';

export default function* rootSaga() {
  try {
    yield all([
      updatesSaga(),
      overviewSaga(),
      voyagesSaga(),
      vesselSaga(),
      checklistsSaga(),
      vesselsSaga(),
      operatorsSaga(),
    ]);
  } catch(e) {
    yield put(errorActions.setError(new Error(e.message || String(e))));
  }
}
