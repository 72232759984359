import {set, lens, identity, filter, reject, concat} from 'ramda';

// Creates a lens that provides a default value in case focus is undefined.
// defaultTo :: a -> Lens a? a
export const defaultTo = value => lens(a => a === undefined ? value : a, identity);

// Sets the focus of the given lens to undefined.
// unset :: Lens a b -> a -> a?
export const unset = lens => set(lens, undefined);

// normalize :: (a -> a) -> Lens a a
export const normalize = f => lens(identity, a => a === undefined ? a : f(a));

// filter :: (a -> Boolean) -> Lens (Array a) (Array a)
export const filterLens = f => lens(
  x => x === undefined ? x : filter(f, x),
  (xs, ys) => (
    xs === undefined && ys === undefined ? (
      undefined
    ) : xs === undefined ? (
      reject(f, ys)
    ) : ys === undefined ? (
      xs
    ) : (
      concat(xs, reject(f, ys))
    )
  ),
);
