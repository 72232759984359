import {lensProp, compose, set, over} from 'ramda';
import {createReducer, noopAction} from 'warped-reducers';

import {defaultTo} from '../utils/state';

export const baseLens = lensProp('checklists');

export const checklistsState = compose(
  baseLens,
  lensProp('checklists'),
  defaultTo([]),
);

export const checklistsLoadingState = compose(
  baseLens,
  lensProp('loading'),
  defaultTo(false),
);

export const checklistSavingState = compose(
  baseLens,
  lensProp('isSaving'),
  defaultTo(false),
);

export const checklistVesselsState = compose(
  baseLens,
  lensProp('meta'),
  defaultTo([]),
);

export const selectedVesselState = compose(
  baseLens,
  lensProp('selectedVessel'),
  defaultTo(null),
);

export const selectedVoyageState = compose(
  baseLens,
  lensProp('selectedVoyage'),
  defaultTo(null),
);

export const selectedChecklistState = compose(
  baseLens,
  lensProp('selectedChecklist'),
  defaultTo([]),
);

export const checklistFormState = compose(
  baseLens,
  lensProp('checklistForm'),
  defaultTo({}),
);

export const checklistFormLoadingState = compose(
  checklistFormState,
  lensProp('isLoading'),
  defaultTo(false),
);

export const checklistFormErrorState = compose(
  checklistFormState,
  lensProp('error'),
);

export const checklistFormDataState = compose(
  checklistFormState,
  lensProp('data'),
  defaultTo({}),
);

export const remarksState = compose(
  checklistFormDataState,
  lensProp('remarks'),
  defaultTo(''),
);

const machingChecklists = (left, right) =>
  left.id === right.id &&
  left.voyage === right.voyage &&
  left.vessel === right.vessel;

const upsertChecklist = (checklist, payload) =>
  checklist.some(c => machingChecklists(payload, c))
    ? checklist.map(c =>
      c.id === payload.id
        ? payload
        : c,
    )
    : [...checklist, payload];

export const {reducer, types, actions} = createReducer('checklists')({
  loadChecklists: noopAction,
  setLoading: set(checklistsLoadingState),
  setChecklists: set(checklistsState),
  setChecklistsVessels: set(checklistVesselsState),
  setSelectedVessel: set(selectedVesselState),
  setSelectedVoyage: set(selectedVoyageState),
  setSelectedChecklist: set(selectedChecklistState),
  setSaving: set(checklistSavingState),
  updateAnswer: payload => over(selectedChecklistState, (checklist =>
    upsertChecklist(checklist, payload)
  )),
  saveUpdate: payload => over(checklistsState, allChecklists => {
    const newItems = payload.filter(p => allChecklists.some(c => !machingChecklists(c, p)));
    return [
      ...allChecklists.map(c => {
        const match = payload.some(p => machingChecklists(c, p));
        return match
          ? match
          : c;
      }),
      ...newItems,
    ];
  }),
  loadChecklistForm: noopAction,
  setChecklistFormLoading: set(checklistFormLoadingState),
  setChecklistFormError: set(checklistFormErrorState),
  setChecklistFormData: set(checklistFormDataState),
  setRemarks: set(remarksState),
});
