import {createMuiTheme} from '@material-ui/core/styles';
import {$white, $nav, $navHighlight, $black, $avOrange} from './variables';

export default createMuiTheme({
  pallete: {
    primary: {
      main: $nav,
    },
    secondary: {
      main: $avOrange,
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif',
    ],
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: $nav,
      },
      root: {
        flexDirection: 'row',
        paddingLeft: 24,
        paddingRight: 24,
      },
    },
    MuiButton: {
      text: {
        borderRadius: 2,
        appearance: 'button',
        fontSize: 13,
        fontWeight: 600,
        boxShadow: 'none',
        minWdth: 90,
        paddingLeft: 30,
        paddingRight: 30,
      },
      contained: {
        'borderRadius': 2,
        'fontWeight': 600,
        'boxShadow': 'none',
        'background-color': $nav,
        'color': $white,
        'fontSize': 13,
        'minWdth': 90,
        'paddingLeft': 30,
        'paddingRight': 30,
        '&:hover': {
          color: $white,
          backgroundColor: $navHighlight,
        },
      },
      outlined: {
        borderRadius: 2,
        fontWeight: 600,
        fontSize: 13,
        boxShadow: 'none',
        minWdth: 90,
        paddingLeft: 30,
        paddingRight: 30,
      },
    },
    MuiCheckbox: {
      root: {
        'color': $nav,
        '&$checked': {
          color: $nav,
        },
      },
      colorSecondary: {
        'color': $nav,
        '&$checked': {
          color: $nav,
        },
      },
    },
    MuiRadio: {
      root: {
        'color': $nav,
        '&$checked': {
          color: $nav,
        },
      },
      colorSecondary: {
        'color': $nav,
        '&$checked': {
          color: $nav,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: 'rgba(0, 0, 0, 0.26)',
      },
    },
    MuiInputBase: {
      root: {
        marginTop: '0 !important',
      },
    },
    MuiTextField: {
      root: {
        paddingTop: 20,
        paddingBottom: 20,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: $black,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
  },
});
