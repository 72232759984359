'use strict';

const tcomb = require ('tcomb');
const {enumeration, alias, list} = require ('../utils/domain');
const {checkPermission} = require ('permissionary');
const {uniq, trim} = require ('ramda');
const permissions = module.exports;

const allowTesters = process.env.ALLOW_TESTERS === 'true';

const fullAccessForEveryone = process.env.FULL_ACCESS_FOR_EVERYONE === 'true';

const OPGString = process.env.OVERRIDE_PERMISSION_GROUPS;

permissions.overridePermissionGroups = OPGString === undefined
  ? undefined
  : OPGString.split (',').map (trim).filter (g => g.length);

const grants = {
  'Everyone': fullAccessForEveryone ? ['**'] : ['general/read'],
  'Anthony Veder Okta - Chartering Department': ['operations/read'],
  'Anthony Veder Okta - Operations Department': ['operations/read', 'operations/write'],
  'Bridge Testers': allowTesters ? ['general/*', 'operations/*'] : [],
  'Anthony Veder Okta - Digital Services / Application Managers': ['**'],
  'Bridge Developers': ['**'],
};

permissions.Group = alias ('Group') (tcomb.String);

permissions.Groups = list (permissions.Group);

permissions.Role = enumeration ('Role') (grants);

permissions.hasPermission = checkPermission (grants);

permissions.defaultRoles = ['Everyone'];

permissions.groupsToRoles = groups => uniq (
  permissions.Groups (groups)
  .filter (permissions.Role.is)
  .concat (permissions.defaultRoles),
);
