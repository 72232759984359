import {put, takeEvery, call, all, select} from 'redux-saga/effects';
import axios from 'axios';

import {
  types, actions, checklistFormDataState, checklistFormErrorState
} from '../reducers/checklists';
import {actions as errorActions} from '../reducers/error';
import {sideEffects} from '../components/Checklist/questionaire';
import {delayedGet} from './utils';
import {view} from 'ramda';

const getChecklists = function* () {
  yield put(actions.setLoading(true));
  try {
    const [{data: checklists}, {data: vessels}] = yield all([
      call(delayedGet(500), '/checklists'),
      call(delayedGet(750), '/vessels'),
    ]);
    yield put(actions.setChecklists(checklists));
    yield put(actions.setChecklistsVessels(vessels));
  } catch (e) {
    yield put(errorActions.setError(e.response.data));
  } finally {
    yield put(actions.setLoading(false));
  }
};

// It would be better to combine the checklist (rows) with the
// checklistForm (remarks field) for a specfic vessel + voyage
// but this requires a significant refactor. Currently ALL
// the checklists are loaded in advance. So for now we fetch the
// checklistForm separately when the voyage is selected.
const getChecklistForm = function* ({payload: {vessel, voyage}}) {
  yield put(actions.setChecklistFormError(null));
  yield put(actions.setChecklistFormLoading(true));
  try {
    const response = yield axios.get(
      `checklist-forms/vessels/${vessel.name}/voyages/${voyage.voyage}`
    );
    yield put(actions.setChecklistFormData(response.data ?? {
      vessel: vessel.name,
      voyage: voyage.voyage,
      remarks: '',
    }));
  } catch (e) {
    yield put(actions.setChecklistFormError(e?.response?.data ?? 'error'));
  } finally {
    yield put(actions.setChecklistFormLoading(false));
  }
};

const saveChecklists = function* ({payload}) {
  // Decided not to make checklistFormData part of the saveUpdate payload
  // for now, because this payload is used directly in the reducer as well.
  const checklistFormData = yield select(view(checklistFormDataState));
  const checklistFormError = yield select(view(checklistFormErrorState));

  yield put(actions.setSaving(true));
  try {
    yield axios.post('/checklists', payload);
    const {data: checklists} = yield call(axios.get, '/checklists');
    yield put(actions.setChecklists(checklists));

    if (!checklistFormError) {
      // This check is needed because checklist and checklistform
      // share a single save action but they do not share loading
      // and error state. Requires significant refactor.
      yield axios.post('/checklist-forms', checklistFormData);
    }
  } catch(e) {
    yield put(errorActions.setError(e.response.data));
  } finally {
    yield put(actions.setSaving(false));
  }
};

const runRules = function* ({payload}) {
  const rule = sideEffects[payload.id];
  const updates = rule ? rule(payload) : [];
  yield all(updates.map(update => put(actions.updateAnswer(update))));
};

export default function* () {
  yield takeEvery(types.loadChecklists, getChecklists);
  yield takeEvery(types.loadChecklistForm, getChecklistForm);
  yield takeEvery(types.saveUpdate, saveChecklists);
  yield takeEvery(types.updateAnswer, runRules);
}
