import axios from 'axios';
import {view} from 'ramda';
import {put, select, takeEvery} from 'redux-saga/effects';
import {isPendingState, types} from '../reducers/vessels';
// import {default as mock} from './_mock/vessels';
import {objEq} from './utils';

export const equal = objEq(['name']);

const getVesselsList = function* () {
  const pendingState = yield select(view(isPendingState));
  if (pendingState) {
    return;
  }
  yield put({type: types.setIsPendingState, payload: true});
  const response = yield axios.get('/vessels');
  yield put({type: types.setVesselsList, payload: response.data});
};

const updateVessel = function* ({payload}) {
  yield axios.patch('/vessels', payload);
  yield put({type: types.setVessel, payload});
};

export default function* () {
  yield takeEvery(types.getVesselsList, getVesselsList);
  yield takeEvery(types.updateVessel, updateVessel);
}
