import {combineReducers} from 'redux';
import reduceReducers from 'reduce-reducers';
import {connectRouter} from 'connected-react-router';

import {reducer as sessionReducer} from './session';
import {default as clients} from './clients';
import {default as brokers} from './brokers';
import {reducer as operatorsReducer} from './operators';
import {reducer as vesselsReducer} from './vessels';
import {reducer as updatesReducer} from './updates';
import {reducer as overviewReducer} from './overview';
import {reducer as navigationReducer} from './navigation';
import {reducer as voyagesReducer} from './voyages';
import {reducer as vesselReducer} from './vessel';
import {reducer as errorReducer} from './error';
import {reducer as checklistsReducer} from './checklists';
import {reducer as documentTitleReducer} from './documentTitle';

export default history => reduceReducers(
  updatesReducer,
  overviewReducer,
  sessionReducer,
  navigationReducer,
  voyagesReducer,
  vesselReducer,
  errorReducer,
  checklistsReducer,
  documentTitleReducer,
  vesselsReducer,
  operatorsReducer,
  combineReducers({
    router: connectRouter(history),
    clients,
    brokers,
    operators: (state = {}, _) => state,
    vessels: (state = {}, _) => state,
    session: (state = {}, _) => state,
    updates: (state = {}, _) => state,
    overview: (state = {}, _) => state,
    navigation: (state = {}, _) => state,
    voyages: (state = {}, _) => state,
    vessel: (state = {}, _) => state,
    error: (state = {}, _) => state,
    checklists: (state = {}, _) => state,
    documentTitle: (state = {}, _) => state,
  }),
);
