import React from 'react';
import Routes from '../../router';
let App = Routes;

// run hot module reloading
if(module.hot && process.env.NODE_ENV !== 'production') {
  const RawApp = App;
  App = class App extends React.Component {
    constructor(props) {
      super(props);
      this.state = {App: RawApp};
      module.hot.accept('../../router', () => {
        import('../../router').then(({default: App}) => {
          this.setState({App});
        });
      });
    }

    //eslint-disable-next-line class-methods-use-this
    render() {
      return <this.state.App />;
    }
  };
}

export default App;
