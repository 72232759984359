import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {view} from 'ramda';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import ListIcon from '@material-ui/icons/List';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {IconButton} from '@material-ui/core';

import {
  listState, pageNumberState, actions, rowsPerPageState
} from '../../../reducers/updates';

// developer flags (requirements were not clear at time of implementation)
const includeNextPrevPageButtons = false;
const includeNavListView = false;

const ITEM_HEIGHT = 48;

const getIsCurrentUpdate = id => update => update.messageId === id;

const NavList = ({updates, updateId, navigateTo}) => {

  const inList = Boolean(updates.find(getIsCurrentUpdate(updateId)));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        disabled={!inList || updates.length < 2}
        onClick={handleClick}
      >
        <ListIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 10.5,
          },
        }}
      >
        {updates.map(update => {
          const isCurrent = getIsCurrentUpdate(updateId)(update);
          return (
            <MenuItem
              key={update.messageId}
              selected={isCurrent}
              onClick={() => {
                navigateTo(update);
                handleClose();
              }}>
              {update.vessel}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

NavList.propTypes = {
  updateId: PropTypes.string.isRequired,
  updates: PropTypes.array.isRequired,
  navigateTo: PropTypes.func.isRequired,
};

/*
In order to offer navigate to next/prev update functionality directly from within the update
details screen, there are three options:

A) Integrate the updates list and detail screens (e.g. side by side or overlay/flyout).
B) Re-use the updates list data in the details screen if it is available in order to determine
   the next/prev update ID
C) Implement next/prev functionality in the backend so that we are not dependent on the list
   data for determining next/prev update ID in the frontend. In this case it is however unclear
   what to do when the current update does not match the filter settings. Should we then for
   example fallback to the next update in the unfiltered list or ignore the request? We cannot
   disable the next/prev buttons in the frontend if it is only calculated in the backend.

Let's go for B because A means more UI work and C means more backend work and has some unknowns.
*/

const Root = styled.div`
  display: flex;
  margin-right: auto;
  flex-shrink: 0;
  align-items: center;
`;

// eslint-disable-next-line complexity
const EditScreenNav = ({
  updateId, list, rowsPerPage, pageNumber, goToLastOfPrevPage, goToFirstOfNextPage, push, isPending
}) => {

  /*
  Normally the user navigates directly from the list screen to details screen, so the list data
  (and the filter settings) are available. Only when the user goes directly to the details screen
  at application start (e.g. by means of link / bookmark) the list data is not available.

  When the component loads we ideally fetch the list data if it is not available, so that we can
  determine the next/prev update. But we ideally do not re-fetch the list data every time the user
  navigates from list > detail screen or worse on every next/prev button press in the detail screen.

  However in the current codebase we cannot easily differentiate between empty list an no list and
  we have no timestamps / caching, so let's just ignore this edge case for now and therefore not
  fetch the list data on load. We simply disable the next / prev buttons if the current update is
  not part of the current (empty) list.
  */

  const updates = list.updates;

  const index = updates.findIndex(u => u.messageId === updateId);
  const inList = index !== -1;

  const maxPageNumber = Math.floor(list.total / rowsPerPage);

  const navigateTo = update => push(`/mailer/${update.messageId}`);

  const navigateOffset = offset => navigateTo(updates[index + offset]);

  const maxIndex = updates.length - 1;
  const canNext = inList && index < maxIndex;
  const canNextPage = inList && index === maxIndex && pageNumber < maxPageNumber;
  const canNextButton = !isPending && (canNext || canNextPage);
  const canNextPageButton = !isPending && inList && pageNumber < maxPageNumber;

  const next = () => {
    if (canNext) {
      navigateOffset(1);
    } else if (canNextPage) {
      goToFirstOfNextPage();
    }
  };

  const canPrev = index > 0;
  const canPrevPage = index === 0 && pageNumber > 0;
  const canPrevButton = !isPending && (canPrev || canPrevPage);
  const canPrevPageButton = !isPending && inList && pageNumber > 0;

  const prev = () => {
    if (canPrev) {
      navigateOffset(-1);
    } else if (canPrevPage) {
      goToLastOfPrevPage();
    }
  };

  return (
    <Root>
      {includeNextPrevPageButtons && (
        <IconButton disabled={!canPrevPageButton} onClick={goToLastOfPrevPage}>
          <SkipPreviousIcon/>
        </IconButton>
      )}
      <IconButton disabled={!canPrevButton} onClick={prev}>
        <NavigateBeforeIcon/>
      </IconButton>
      {includeNavListView && (
        <NavList updateId={updateId} updates={updates} navigateTo={navigateTo} />
      )}
      <IconButton disabled={!canNextButton} onClick={next}>
        <NavigateNextIcon/>
      </IconButton>
      {includeNextPrevPageButtons && (
        <IconButton disabled={!canNextPageButton} onClick={goToFirstOfNextPage}>
          <SkipNextIcon/>
        </IconButton>
      )}
    </Root>
  );
};

EditScreenNav.propTypes = {
  updateId: PropTypes.string.isRequired,
  list: PropTypes.object.isRequired,
  pageNumber: PropTypes.number.isRequired,
  push: PropTypes.func.isRequired,
  goToLastOfPrevPage: PropTypes.func.isRequired,
  goToFirstOfNextPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  isPending: PropTypes.bool.isRequired,
};

const {setPageNumber, goToFirstOfNextPage, goToLastOfPrevPage} = actions;

export default connect(
  createStructuredSelector({
    list: view(listState),
    pageNumber: view(pageNumberState),
    rowsPerPage: view(rowsPerPageState),
  }),
  {push, setPageNumber, goToFirstOfNextPage, goToLastOfPrevPage}
)(EditScreenNav);
