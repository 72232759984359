import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import {$black} from '../styles/variables';

const FieldContainer = styled.div`
  width: 100%;
  position: relative;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  * {
    color: ${$black};
  }
  .MuiSelect-icon.Mui-disabled {
    display: none;
  }
`;

const EditableSelection = ({children, fieldName, fieldValue, isEditable, onChange}) => (
  <FieldContainer>
    <StyledSelect
      disabled={!isEditable}
      name={fieldName}
      value={fieldValue}
      onChange={e => { onChange(e.target.value); }}
      disableUnderline
    >
      {children}
    </StyledSelect>
  </FieldContainer>
);

EditableSelection.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  onChange: PropTypes.func,
};

export default EditableSelection;
