import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {$text} from '../styles/variables';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${$text};
`;

const Item = styled.div`
  display: flex;
  line-height: 1.5;
  strong, span {
    flex: 0 0 50%;
  }
`;

const FallbackText = styled.p`
  text-align: center;
  padding: 40px;
`;

const EntriesList = ({entries, fallbackText}) => {
  const list = entries
    ? Object.entries(entries)
    : [];

  return (
    <Container>
      {list.length < 1 && <FallbackText>{fallbackText}</FallbackText>}
      {list.map(([key, value]) =>
        <Item key={key}>
          <span>{key}:</span>
          <strong>{value}</strong>
        </Item>,
      )}
    </Container>
  );
};

EntriesList.propTypes = {
  fallbackText: PropTypes.string,
  entries: PropTypes.object,
};

export default EntriesList;
