import React from 'react';
import PropTypes from 'prop-types';
import {createSelector} from 'reselect';
import {connect} from 'react-redux';
import {view} from 'ramda';

import {
  actions,
  checklistsState,
  checklistsLoadingState,
  checklistVesselsState,
} from '../../reducers/checklists';
import {
  actions as voyagesActions,
  loaderState,
  completeState,
  voyagesState,
} from '../../reducers/voyages';
import VoyageChecklist from '../../components/Checklist';
import Loader from '../../components/Loader';
import {actions as documentActions} from '../../reducers/documentTitle';

class Checklists extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    checklists: PropTypes.array.isRequired,
    vessels: PropTypes.array.isRequired,
    voyages: PropTypes.array.isRequired,
    loadChecklists: PropTypes.func.isRequired,
    loadVoyages: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {loadChecklists, loadVoyages, setDocumentTitle} = this.props;
    loadChecklists();
    loadVoyages();
    setDocumentTitle('Checklists');
  }

  render() {
    const {isLoading, checklists, vessels, voyages} = this.props;
    return isLoading
      ? <Loader full />
      : <VoyageChecklist vessels={vessels} voyages={voyages} checklists={checklists} />;
  }
}

const loadingSelector = createSelector([
  view(checklistsLoadingState),
  view(loaderState),
  view(completeState),
], (checklistsLoading, voyagesLoading, voyagesComplete) => (
  checklistsLoading || (!voyagesComplete && voyagesLoading > 0)
));

export default connect(
  state => ({
    isLoading: loadingSelector(state),
    checklists: view(checklistsState, state),
    vessels: view(checklistVesselsState, state),
    voyages: view(voyagesState, state),
  }),
  {
    ...voyagesActions,
    ...actions,
    ...documentActions,
  },
)(Checklists);
