import {equal} from '../services/brokers';

const defState = {
  list: [],
  isPending: false,
};

export default function (state = defState, action) {
  switch (action.type) {

    case 'BROKER_LIST_PENDING':
      return Object.assign({}, state, {list: [], isPending: true});
    case 'BROKER_LIST_FULFILLED':
      return Object.assign({}, state, {list: action.payload, isPending: false});

    case 'BROKER_UPDATE_FULFILLED':
      return Object.assign({}, state, {list: state.list.map(item => (
        equal(item, action.payload)
          ? Object.assign({}, item, action.payload)
          : item
      ))});

    default:
      return state;
  }
}
