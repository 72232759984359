'use strict';

const fn = require ('../functions');
const {model, maybe, list, enumeration, submodel, laden, alias} = require ('../utils/domain');
const common = require ('./common');
const broker = require ('./broker');
const shipping = require ('./shipping');
const agent = require ('./agent');
const tcomb = require ('tcomb');
const voyage = module.exports;

// TODO: Remove legacy model.
voyage.VoyagePortIdentifier = model ('VoyagePortIdentifier') ({
  vessel: shipping.VesselName,
  voyage: shipping.VoyageNumber,
  port: shipping.PortCode,
  portOrder: shipping.PortOrder,
  portType: shipping.PortType,
});

voyage.getVoyagePortIdentifier = voyagePort => {
  voyage.VoyagePort (voyagePort);

  return voyage.VoyagePortIdentifier ({
    vessel: voyagePort.vessel,
    voyage: voyagePort.voyage,
    port: voyagePort.port,
    portOrder: voyagePort.portOrder,
    portType: voyagePort.portType,
  });
};

// TODO: Remove legacy model.
voyage.VoyagePort = submodel ('VoyagePort') (voyage.VoyagePortIdentifier) ({
  portName: shipping.PortName,
  timezone: common.Timezone,
  ata: maybe (common.ZonedDateTime),
  ataUat: maybe (common.Instant),
  ataSrc: maybe (common.UpdateSource),
  atb: maybe (common.ZonedDateTime),
  atbUat: maybe (common.Instant),
  atbSrc: maybe (common.UpdateSource),
  atc: maybe (common.ZonedDateTime),
  atcUat: maybe (common.Instant),
  atcSrc: maybe (common.UpdateSource),
  atd: maybe (common.ZonedDateTime),
  atdUat: maybe (common.Instant),
  atdSrc: maybe (common.UpdateSource),
  eta: maybe (common.ZonedDateTime),
  etaUat: maybe (common.Instant),
  etaSrc: maybe (common.UpdateSource),
  etb: maybe (common.ZonedDateTime),
  etbUat: maybe (common.Instant),
  etbSrc: maybe (common.UpdateSource),
  etc: maybe (common.ZonedDateTime),
  etcUat: maybe (common.Instant),
  etcSrc: maybe (common.UpdateSource),
  etd: maybe (common.ZonedDateTime),
  etdUat: maybe (common.Instant),
  etdSrc: maybe (common.UpdateSource),
  pta: maybe (common.ZonedDateTime),
  agent: maybe (agent.Agent),
});

voyage.WaypointFunctionName = laden (tcomb.String);

voyage.WaypointFunction = enumeration ('WaypointFunction') ({
  L: {
    name: voyage.WaypointFunctionName ('Loading'),
    portType: shipping.PortType ('load'),
  },
  D: {
    name: voyage.WaypointFunctionName ('Discharging'),
    portType: shipping.PortType ('discharge'),
  },
  C: {
    name: voyage.WaypointFunctionName ('Commencing'),
    portType: shipping.PortType ('transit'),
  },
  T: {
    name: voyage.WaypointFunctionName ('Terminating'),
    portType: shipping.PortType ('transit'),
  },
  F: {
    name: voyage.WaypointFunctionName ('Fueling'),
    portType: shipping.PortType ('transit'),
  },
  R: {
    name: voyage.WaypointFunctionName ('Repair'),
    portType: shipping.PortType ('transit'),
  },
  P: {
    name: voyage.WaypointFunctionName ('Passing'),
    portType: shipping.PortType ('transit'),
  },
  I: {
    name: voyage.WaypointFunctionName ('Canal Transit'),
    portType: shipping.PortType ('transit'),
  },
  Y: {
    name: voyage.WaypointFunctionName ('Delivery'),
    portType: shipping.PortType ('transit'),
  },
  Z: {
    name: voyage.WaypointFunctionName ('Redelivery'),
    portType: shipping.PortType ('transit'),
  },
  W: {
    name: voyage.WaypointFunctionName ('Waiting'),
    portType: shipping.PortType ('transit'),
  },
  O: {
    name: voyage.WaypointFunctionName ('Other'),
    portType: shipping.PortType ('transit'),
  },
  N: {
    name: voyage.WaypointFunctionName ('Nitrogen'),
    portType: shipping.PortType ('transit'),
  },
});

voyage.waypointFunctionToPortType = x => (
  voyage.WaypointFunction.meta.map[voyage.WaypointFunction (x)].portType
);

// A PortOrder number has historically been trivially derived from the
// underlying WaypointSequence number by means of division by hundred.
// Now that we receive the underlying data as well, we need this
// transformation in order to associate the older data format with the
// newer one.
// TEMP: This function can be removed along with everything else VoyagePort-related.
voyage.waypointSequenceToPortOrder = x => Math.round (x / 100);

voyage.WaypointSchedule = model ('WaypointSchedule') ({
  ata: maybe (common.ZonedDateTime),
  atb: maybe (common.ZonedDateTime),
  atc: maybe (common.ZonedDateTime),
  atd: maybe (common.ZonedDateTime),
  eta: maybe (common.ZonedDateTime),
  etb: maybe (common.ZonedDateTime),
  etc: maybe (common.ZonedDateTime),
  etd: maybe (common.ZonedDateTime),
});

voyage.emptySchedule = voyage.WaypointSchedule ({
  ata: fn.Nothing,
  atb: fn.Nothing,
  atc: fn.Nothing,
  atd: fn.Nothing,
  eta: fn.Nothing,
  etb: fn.Nothing,
  etc: fn.Nothing,
  etd: fn.Nothing,
});

voyage.WaypointIdentifier = model ('WaypointIdentifier') ({
  vessel: shipping.VesselName,
  voyage: shipping.VoyageNumber,
  port: shipping.PortCode,
  sequence: shipping.PortOrder,
  waypointFunction: voyage.WaypointFunction,
});

voyage.waypointIdentifierToVoyagePortIdentifier = id => {
  voyage.WaypointIdentifier (id);

  return voyage.VoyagePortIdentifier ({
    vessel: id.vessel,
    voyage: id.voyage,
    port: id.port,
    portOrder: voyage.waypointSequenceToPortOrder (id.sequence),
    portType: voyage.waypointFunctionToPortType (id.waypointFunction),
  });
};

voyage.Waypoint = submodel ('Waypoint') (voyage.WaypointIdentifier) ({
  portName: maybe (shipping.PortName),
  waypointFunctionName: maybe (voyage.WaypointFunctionName),
  updatedAt: maybe (common.Instant),
  timezone: maybe (common.ZoneId),
  latestSchedule: voyage.WaypointSchedule,
  confirmedSchedule: voyage.WaypointSchedule,
  agent: maybe (agent.Agent),
});

voyage.getWaypointIdentifier = waypoint => {
  voyage.Waypoint (waypoint);

  return voyage.WaypointIdentifier ({
    vessel: waypoint.vessel,
    voyage: waypoint.voyage,
    port: waypoint.port,
    sequence: waypoint.sequence,
    waypointFunction: waypoint.waypointFunction,
  });
};

voyage.Itinerary = list (voyage.Waypoint);

voyage.CoaNumber = alias ('CoaNumber') (tcomb.Number);

voyage.VoyageType = enumeration ('VoyageType') ({
  OVOV: null,
  OVTO: null,
});

voyage.VoyageStatus = enumeration ('VoyageStatus') ({
  Closed: null,
  Completed: null,
  Commenced: null,
  Scheduled: null,
  Other: null,
});

voyage.VoyageIdentifier = model ('VoyageIdentifier') ({
  vessel: shipping.VesselName,
  voyage: shipping.VoyageNumber,
});

voyage.Voyage = submodel ('Voyage') (voyage.VoyageIdentifier) ({
  cargo: maybe (shipping.Cargo),
  company: maybe (common.CompanyName),
  broker: maybe (broker.BrokerName),
  laycanFrom: maybe (common.ZonedDateTime),
  laycanTo: maybe (common.ZonedDateTime),
  status: maybe (voyage.VoyageStatus),
  voyageType: maybe (voyage.VoyageType),
  updatedAt: maybe (common.Instant),
  coaNumber: maybe (voyage.CoaNumber),
  itinerary: voyage.Itinerary,
});

voyage.getVoyageIdentifier = it => {
  voyage.Voyage (it);
  return voyage.VoyageIdentifier ({vessel: it.vessel, voyage: it.voyage});
};

voyage.getVoyageIdentifierFromVoyagePortIdentifier = it => {
  voyage.VoyagePortIdentifier (it);
  return voyage.VoyageIdentifier ({vessel: it.vessel, voyage: it.voyage});
};
