import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const FontIcon = ({ alt, children, className, theme, value, ...other}) => ( // eslint-disable-line
  <span
    aria-label={alt}
    className={classnames('icon-font', `icon-${value}`, className)}
    {...other}
  />
);

FontIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};

FontIcon.defaultProps = {
  alt: '',
  className: '',
};

export default FontIcon;
