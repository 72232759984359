import React from 'react';
import PropTypes from 'prop-types';

import {
  VoyageDetailsContainer,
  CellTitle,
  FlexWrap,
  Flex,
  Label,
  InputText
} from './styles';
import {UpdateBundleShape} from '../../../shapes';
import DateText from '../../../components/DateText';
import {ZonedDateTime} from '@js-joda/core';
import {Loader} from '../../../components';
import {$avOrange} from '../../../styles/variables';

const VoyageDetails = ({
  update,
  isEditable,
  onChangeHandler,
  voyage,
  voyageLoading,
  voyageLoadingError
}) => (
  <VoyageDetailsContainer>
    <CellTitle>Voyage details</CellTitle>
    <FlexWrap direction="column">
      <Flex>
        <Label>Vessel name</Label>
        <InputText
          fieldName="vessel"
          fieldValue={update.vessel}
          onChange={onChangeHandler('vessel')}
          isEditable={isEditable}
        />
      </Flex>
      <Flex>
        <Label>Voyage no.</Label>
        <InputText
          fieldName="voyage"
          fieldValue={update.voyage}
          type="number"
          onChange={onChangeHandler('voyage')}
          isEditable={isEditable}
        />
      </Flex>
      {
        voyageLoadingError ? (
          <Flex>
            <Label style={{color: $avOrange}}>Could not retrieve voyage details</Label>
          </Flex>
        ) : (
          <>
            <Flex>
              <Label>Laycan From</Label>
              {voyageLoading
                ? <Loader size={16} noPadding minHeight={18}/>
                : voyage && voyage.laycanFrom
                  ? <DateText date={ZonedDateTime.parse(voyage.laycanFrom)} />
                  : 'Unknown'
              }
            </Flex>
            <Flex>
              <Label>Laycan To</Label>
              {voyageLoading
                ? <Loader size={16} noPadding minHeight={18}/>
                : voyage && voyage.laycanTo
                  ? <DateText date={ZonedDateTime.parse(voyage.laycanTo)} />
                  : 'Unknown'
              }
            </Flex>
          </>
        )
      }
    </FlexWrap>
  </VoyageDetailsContainer>
);

VoyageDetails.propTypes = {
  update: UpdateBundleShape,
  voyage: PropTypes.object.isRequired,
  voyageLoading: PropTypes.bool.isRequired,
  voyageLoadingError: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool,
  onChangeHandler: PropTypes.func.isRequired,
};

export default VoyageDetails;
