import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {ZonedDateTime} from '@js-joda/core';
import DateText from '../DateText';
import {$text} from '../../styles/variables';
import WaypointFunction from '../WaypointFunction';

const BlankText = styled.div`
  color: ${$text};
  font-size: 14px;
  width: 100%;
`;

const TimeText = styled.div`
  color: ${$text};
  font-size: 14px;
  min-height: 34px;
  background: ${props => props.isActual ? 'rgba(0, 75, 134, 0.1)' : 'none'};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
`;

const PortName = ({portName}) => (
  <BlankText>
    {portName || 'Unknown'}
  </BlankText>
);

PortName.propTypes = {
  portName: PropTypes.string.isRquired,
};

const Type = ({waypointFunction, waypointFunctionName}) => (
  <BlankText>
    <WaypointFunction
      waypointFunction={waypointFunction}
      waypointFunctionName={waypointFunctionName}
    />
  </BlankText>
);

Type.propTypes = WaypointFunction.propTypes;

const TimeOfArrival = props => {
  const isActual = Boolean(props.confirmedSchedule.ata);
  const time = props.confirmedSchedule.ata || props.confirmedSchedule.eta;

  return (
    <TimeText isActual={isActual}>
      {time && <DateText date={ZonedDateTime.parse(time)} />}
    </TimeText>
  );
};

TimeOfArrival.propTypes = {
  confirmedSchedule: PropTypes.objectOf(PropTypes.shape({
    ata: PropTypes.string,
    eta: PropTypes.string,
  })).isRequired,
};

const TimeOfBerthing = ({confirmedSchedule}) => {
  const isActual = Boolean(confirmedSchedule.atb);
  const time = confirmedSchedule.atb || confirmedSchedule.etb;

  return (
    <TimeText isActual={isActual}>
      {time && <DateText date={ZonedDateTime.parse(time)} />}
    </TimeText>
  );
};

TimeOfBerthing.propTypes = {
  confirmedSchedule: PropTypes.objectOf(PropTypes.shape({
    atb: PropTypes.string,
    etb: PropTypes.string,
  })).isRequired,
};

const TimeOfDeparture = ({confirmedSchedule}) => {
  const isActual = Boolean(confirmedSchedule.atd);
  const time = confirmedSchedule.atd || confirmedSchedule.etd;

  return (
    <TimeText isActual={isActual}>
      {time && <DateText date={ZonedDateTime.parse(time)} />}
    </TimeText>
  );
};

TimeOfDeparture.propTypes = {
  confirmedSchedule: PropTypes.objectOf(PropTypes.shape({
    atd: PropTypes.string,
    etd: PropTypes.string,
  })).isRequired,
};

export const columnProperties = [
  {
    label: 'PORT NAME',
    center: false,
    width: 25,
    render: PortName,
  },
  {
    label: 'TYPE',
    center: false,
    width: 15,
    render: Type,
  },
  {
    label: 'TIME OF ARRIVAL',
    center: true,
    width: 20,
    flex: 'center',
    render: TimeOfArrival,
  },
  {
    label: 'TIME OF BERTHING',
    center: true,
    width: 20,
    flex: 'center',
    render: TimeOfBerthing,
  },
  {
    label: 'TIME OF DEPARTURE',
    center: true,
    width: 20,
    flex: 'center',
    render: TimeOfDeparture,
  },
];
