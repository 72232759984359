import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

import {Table, PageContainer, FontIcon, Loader, PageTitleSearchBar} from '../../components';
import {
  list as listBrokers,
  update as updateBroker,
  equal as brokerEqual,
} from '../../services/brokers';
import {hasWritePermission} from '../../reducers/session';
import {actions as documentActions} from '../../reducers/documentTitle';

const Content = styled.div`
  display: flex;
  justify-content: center;
`;

const Input = styled(TextField)`
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
`;

const EmailList = styled.div`
  width: 100%;
`;

const EmailItem = styled.div`
  min-height: 40px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 100%;
  line-height: 40px;
  &:first-child {
    margin-top: -8px;
  }
  &:last-child {
    margin-bottom: -8px;
  }
`;

class Brokers extends Component {

  static propTypes = {
    listBrokers: PropTypes.func.isRequired,
    updateBroker: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired,
    brokers: PropTypes.shape({
      list: PropTypes.array,
      isPending: PropTypes.bool,
    }),
    hasWritePermission: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.listBrokers();
    this.props.setDocumentTitle('Brokers');
  }

  constructor(props) {
    super(props);
    this.state = {
      activeEdit: null,
      query: '',
      columns: [
        {prop: 'name', label: 'Broker', width: 45, sortable: true},
        {prop: 'client', label: 'Customer', width: 45, sortable: false},
        {prop: 'eori', label: 'EORI', width: 45, sortable: false},
        {prop: 'emails', label: 'Emails', width: 45, sortable: false},
        {prop: 'icon', label: '', width: 10, align: 'center'},
      ],
    };
  }
  search = query => this.setState({query});
  onEdit = broker => () => {
    const emails = (broker.emails || '').replace(/,/g, '\n');
    const activeEdit = Object.assign({}, broker, {emails});
    this.setState({activeEdit});
  };

  updateEori = ({target}) => {
    const activeEdit = Object.assign({}, this.state.activeEdit, {eori: target.value});
    this.setState({activeEdit});
  };

  updateEmails = ({target}) => {
    const activeEdit = Object.assign({}, this.state.activeEdit, {emails: target.value});
    this.setState({activeEdit});
  };

  eoriEdit = eori => (
    <Input
      multiline
      onChange={this.updateEori}
      value={eori}
    />
  );

  emailEdit = emails => (
    <Input
      multiline
      onChange={this.updateEmails}
      value={emails}
    />
  );

  emailList = list => (
    <EmailList>
      {list.split(',').map(d => (<EmailItem key={d} title={d}>{d}</EmailItem>))}
    </EmailList>
  );

  saveEdit = () => {
    const {activeEdit} = this.state;
    const emails = (activeEdit.emails || '').trim().replace(/[\s,]+/g, ',') || null;
    const eori = (activeEdit.eori || '').trim();
    const update = Object.assign({}, activeEdit, {eori, emails});
    this.props.updateBroker(update);
    this.setState({activeEdit: null});
  };

  cancelEdit = () => {
    this.setState({activeEdit: null});
  };

  saveIcons = () => (
    <div>
      <FontIcon
        onClick={this.saveEdit}
        value="check"
      />
      <FontIcon
        onClick={this.cancelEdit}
        value="close2"
      />
    </div>
  );

  isActiveEdit = broker => brokerEqual(broker, this.state.activeEdit);

  render() {
    const {hasWritePermission} = this.props;
    const tableData = this.props.brokers.list.map(broker => Object.assign({}, broker, {
      emails: this.isActiveEdit(broker)
        ? this.emailEdit(this.state.activeEdit.emails || '')
        : this.emailList(broker.emails || ''),

      eori: this.isActiveEdit(broker)
        ? this.eoriEdit(this.state.activeEdit.eori || '')
        : (broker.eori || ''),

      icon: this.isActiveEdit(broker)
        ? this.saveIcons()
        : hasWritePermission && <FontIcon onClick={this.onEdit(broker)} value="pencil" />,
    }));

    if (this.props.brokers.isPending) {
      return <Loader />;
    }

    return (
      <PageContainer>
        <PageTitleSearchBar title="Brokers" onSearchChange={this.search} />
        <Content>
          <Table
            data={tableData}
            columns={this.state.columns}
            query={this.state.query}
            onRowClick={this.onRowClickHandler}
          />
        </Content>
      </PageContainer>
    );
  }
}

export default connect(
  storeState => ({
    brokers: storeState.brokers,
    hasWritePermission: hasWritePermission(storeState),
  }),
  dispatch => ({
    listBrokers: () => dispatch(listBrokers()),
    updateBroker: update => dispatch(updateBroker(update)),
    setDocumentTitle: title => dispatch(documentActions.setDocumentTitle(title)),
  }),
)(Brokers);
