import {equal} from '../services/clients';
import {customerColumnDefinitions} from '../containers/Customers/constants';

const defState = {
  list: [],
  isPending: false,
  clientColumnFilters: customerColumnDefinitions.reduce(
    (acc, column) => ({...acc, [column.prop]: column.filter.defaultValue}), {}
  ),
};

export default function (state = defState, action) {
  switch (action.type) {

    case 'CLIENT_LIST_PENDING':
      return Object.assign({}, state, {list: [], isPending: true});
    case 'CLIENT_LIST_FULFILLED':
      return Object.assign({}, state, {list: action.payload, isPending: false});

    case 'CLIENT_UPDATE_FULFILLED':
      return Object.assign({}, state, {list: state.list.map(item => (
        equal(item, action.payload)
          ? Object.assign({}, item, action.payload)
          : item
      ))});

    case 'SET_CLIENT_COLUMN_FILTER':
      return Object.assign({}, state, {clientColumnFilters: Object.assign(
        {}, state.clientColumnFilters, {[action.payload.field]: action.payload.value})
      });

    case 'CLEAR_CLIENT_COLUMN_FILTER':
      return Object.assign({}, state, {clientColumnFilters: Object.assign(
        {}, state.clientColumnFilters, {[action.payload]: ''})
      });

    default:
      return state;
  }
}
