import {withStyles} from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {$gray} from '../styles/variables';
import styled from 'styled-components';

const StyledMenuSeparator = styled.div`
  height: 1px;
  background: #c2c2c2;
  width: 100%;
`;

const StyledMenu = withStyles(() => ({
  paper: {
    'minWidth': '120px',
    'marginTop': '12px',
    'overflow': 'visible',
    '&:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      right: 12,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 5px 5px 5px',
      borderColor: 'transparent transparent #fff transparent',
      transform: 'translateY(-100%)',
    },

    '&.skip-arrow': {
      'marginTop': 0,
      '&:before': {
        display: 'none',
      },
    },
  },
  list: {
    padding: '0',
  },
}))(Menu);

const StyledMenuItem = withStyles(() => ({
  root: {
    'fontSize': 14,
    'color': $gray,
    'minHeight': '0',
    'padding': '8px 12px',
    'transition': 'color 0.2s ease, background 0.2s ease',
    '&:hover': {
      background: '#eee',
      color: '#222',
    },
    '&:first-of-type': {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    },
    '&:last-of-type': {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    },
  },
}))(MenuItem);

export {
  StyledMenu,
  StyledMenuItem,
  StyledMenuSeparator,
};
