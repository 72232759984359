import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {OutlinedInput} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const SearchField = ({onClear, ...rest}) => (
  <Root>
    <OutlinedInput {...rest} />
    <IconContainer>
      {
        rest.value && onClear
          ? <ClearIcon fontSize="small" style={{cursor: 'pointer'}} onClick={onClear} />
          : <SearchIcon fontSize="small" />
      }
    </IconContainer>
  </Root>
);

SearchField.propTypes = {
  ...OutlinedInput.propTypes,
  onClear: PropTypes.func,
};

export default SearchField;

const Root = styled.div`
  display: flex;
  .MuiInputBase-input {
    padding-right: 30px;
  }
  input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const IconContainer = styled.div`
  width: 30px;
  display: flex;
  justify-content: center;
  margin-left: -30px;
  padding-top: 10px;
  z-index:1;
`;
