/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {view, assocPath, pick, equals} from 'ramda';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Button from '@material-ui/core/Button';
import {push} from 'connected-react-router';

import {UpdateStatusTypes, UpdateBundleShape} from '../../shapes';
import {PageContainer, ConfirmDialog, Loader} from '../../components';
import {$gray} from '../../styles/variables';
import {
  listState,
  actions,
  isPendingState,
  openResendDialogState,
  updateState,
  updateInitialState,
} from '../../reducers/updates';
import {hasWritePermission} from '../../reducers/session';
import VoyageDetails from './components/VoyageDetails';
import UpdateDetails from './components/UpdateDetails';
import PortUpdateContainer from './components/PortUpdateContainer';
import FileUpdateContainer from './components/FileUpdateContainer';
import {actions as documentActions} from '../../reducers/documentTitle';
import {
  actions as voyageActions,
  loaderState as voyagesLoaderState,
  updateVesselVoyageState,
  updateVesselVoyageErrorState,
} from '../../reducers/voyages';
import {
  actions as vesselsActions,
  listState as vesselsState,
  isPendingState as isVesselsPendingState,
} from '../../reducers/vessels';
import {
  actions as operatorsActions,
  listState as operatorsState,
  isPendingState as isOperatorsPendingState,
} from '../../reducers/operators';
import EditScreenNav from './components/EditScreenNav';

const RIGHT_COLUMN_LEFT_MARGIN = 24;
const UPDATE_DETAILS_FLEX_GROW = 3;
const UPDATE_INFO_FLEX_GROW = 1;
const UPDATE_DETAILS_PERCENTAGE =
  UPDATE_DETAILS_FLEX_GROW / (UPDATE_DETAILS_FLEX_GROW + UPDATE_INFO_FLEX_GROW);

const VesselNameTitle = styled.h2`
  padding: 0;
  margin: 0;
  margin-bottom: 25px;
  font-weight: normal;
  align-items: center;
  display: flex;
`;

const BackButton = styled(KeyboardArrowLeftIcon)`
  font-size: 30px;
  margin-left: -40px;
  margin-right: 10px;
  fill: ${$gray};
  cursor: pointer;
`;

const Actions = styled.div`
  position: sticky;
  bottom: 0;
  background: #FAFAFA;
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  padding: 15px 0;
  margin-top: 8px;
  flex-direction: row-reverse;
  width: calc(${UPDATE_DETAILS_PERCENTAGE * 100}% -
    ${UPDATE_DETAILS_PERCENTAGE * RIGHT_COLUMN_LEFT_MARGIN}px
  );
  > button {
    margin-left: 15px;
  }
`;

const Grid = styled.div`
  display: flex;
`;

const UpdateInfoContainer = styled.div`
  flex: ${UPDATE_INFO_FLEX_GROW};
  margin-left: ${RIGHT_COLUMN_LEFT_MARGIN}px;
  margin-top: 64px;
`;

const UpdateDetailsContainer = styled.div`
  flex: ${UPDATE_DETAILS_FLEX_GROW};
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
`;

const operatorSignature = operator => `${operator.name}

Operations Department
Anthony Veder Chartering B.V.${
  operator.phone ? `\nT ${operator.phone}` : ''
}${
  operator.mobile ? `\nM ${operator.mobile}` : ''
}
E operations@anthonyveder.com
W www.anthonyveder.com`;

const fallbackSignature = `Operations Department

Anthony Veder Chartering B.V.
E operations@anthonyveder.com
W www.anthonyveder.com`;

const createSignature = (update, vessels, operators) => {
  const vessel = vessels.find(v => v.name === update.vessel);
  const operator = vessel && operators.find(o => o.id === vessel.operatorId);
  return operator ? operatorSignature(operator) : fallbackSignature;
};

const createMailtoLink = (update, vessels, operators) => encodeURI(`mailto:${
  update.recipients.filter(({type}) => type === 'email').map(({value}) => value).join(';')
}?subject=${
  update.vessel
} ${
  update.voyage
}&body=Good day,
Please find the below details of the ${update.portUpdate
    ? update.portUpdate.type
    : 'file'} report.

${update.portUpdate ? update.portUpdate.type.toUpperCase() : 'UPDATE'} REPORT
------------------------------------------
VESSEL          : ${update.vessel}
VOYAGE NO       : ${update.voyage}
${update.portUpdate
    ? `PORT OF SERVICE : ${update.portUpdate.port.name} (${update.portUpdate.port.code})\n`
    : ''}
${update.portUpdate && update.portUpdate.generalRemarks
    ? `REMARKS
------------------------------------------
${update.portUpdate.generalRemarks}\n`
    : ''}
Kind regards,
${createSignature(update, vessels, operators)}
`);

const getId = props => props.match.params.id;

class UpdatesItem extends React.Component {
  static propTypes = {
    isResendDialogOpen: PropTypes.bool.isRequired,
    updateInitial: UpdateBundleShape,
    update: UpdateBundleShape,
    isPending: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.object,
    push: PropTypes.func.isRequired,
    openResendDialog: PropTypes.func.isRequired,
    closeResendDialog: PropTypes.func.isRequired,
    setUpdate: PropTypes.func.isRequired,
    getUpdateById: PropTypes.func.isRequired,
    pushUpdate: PropTypes.func.isRequired,
    archiveUpdate: PropTypes.func.isRequired,
    unarchiveUpdate: PropTypes.func.isRequired,
    setUpdateTimeline: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired,
    hasWritePermission: PropTypes.bool.isRequired,
    getVesselsList: PropTypes.func.isRequired,
    isVesselsPending: PropTypes.bool.isRequired,
    vessels: PropTypes.array.isRequired,
    getOperatorsList: PropTypes.func.isRequired,
    isOperatorsPending: PropTypes.bool.isRequired,
    operators: PropTypes.array.isRequired,
    updateVesselVoyage: PropTypes.object.isRequired,
    updateVesselVoyageLoading: PropTypes.bool.isRequired,
    loadVesselVoyage: PropTypes.func.isRequired,
    loadUpdateVesselVoyageError: PropTypes.bool.isRequired
  }

  getUpdate = () => {
    this.props.getUpdateById({
      id: getId(this.props),
      markAsRead: this.props.hasWritePermission,
    });
  }

  componentDidMount() {
    this.getUpdate();
    this.props.getVesselsList();
    this.props.getOperatorsList();
    this.props.setDocumentTitle('Updates');
  }

  componentDidUpdate(prevProps) {
    if(getId(this.props) !== getId(prevProps)) {
      this.getUpdate();
    }
    if(this.props.update !== prevProps.update) {
      const {vessel, voyage} = this.props.update;
      this.props.loadVesselVoyage({vessel, voyage});
    }
  }

  onClickSendUpdate = () => {
    const {updateInitial, update, openResendDialog} = this.props;
    if(equals(updateInitial, update)) {
      // If the user made NO changes we assume he wants to force-send the update,
      // and this requires a confirmation.
      openResendDialog();
    } else {
      this.sendUpdate(false);
    }
  }

  onConfirmForceSendUpdate = () => {
    this.props.closeResendDialog();
    this.sendUpdate(true);
  }

  /**
   * @param {boolean} force - Signals the backend to disable some
   * rules when processing the new update.
   */
  sendUpdate = force => {
    //TODO: error handling
    //TODO: import the data model from server/domain
    const newUpdateFields = [
      'vessel',
      'voyage',
      'portUpdate',
      'fileUpdate',
    ];
    const newPortUpdateFields = [
      'port',
      'portType',
      'type',
      'previousPort',
      'protest',
      'berthName',
      'callNumber',
      'xtx',
      'portlog',
      'tug',
      'generalRemarks',
      'draftOnSailing',
      'draftOnArrival',
      'delayRemarks',
      'cargo',
      'bunkersTaken',
      'bunkersOnSailing',
      'bunkersOnArrival',
      'signature',
    ];
    const payload = {
      newUpdate: {
        ...pick(newUpdateFields, this.props.update),
        force,
        portUpdate: this.props.update.portUpdate ?
          pick(newPortUpdateFields, this.props.update.portUpdate) :
          null,
      },
      messageId: this.props.update.messageId,
    };
    this.props.pushUpdate(payload);
  };

  archiveUpdate = () => {
    this.props.archiveUpdate(this.props.update.messageId);
  }

  unarchiveUpdate = () => {
    this.props.unarchiveUpdate(this.props.update.messageId);
  }

  onChangeHandler = name => value => {
    const path = name.split('.').map(x => isNaN(parseInt(x, 10)) ? x : parseInt(x, 10));
    this.props.setUpdate(assocPath([...path], value, this.props.update));
  }

  isPending = () => {
    const {isPending, update, isVesselsPending, isOperatorsPending} = this.props;
    return isPending || !update || isVesselsPending || isOperatorsPending;
  };

  isVesselVoyagePending = () => {
    const {updateVesselVoyage, updateVesselVoyageLoading} = this.props;
    return updateVesselVoyageLoading || !updateVesselVoyage;
  }
  render() {
    const {
      update,
      hasWritePermission,
      setUpdateTimeline,
      vessels,
      operators,
      updateVesselVoyage,
      loadUpdateVesselVoyageError
    } = this.props;
    if (this.isPending()) {
      return <Loader />;
    }
    const isEditable = hasWritePermission && update.status === UpdateStatusTypes.rejected
      && !update.handled;

    const hasLoadVesselVoyageError = Boolean(loadUpdateVesselVoyageError);
    return (
      <PageContainer>
        <ConfirmDialog
          title="Send unchanged update"
          // eslint-disable-next-line max-len
          description="You are about to resend an update without changing anything. Are you sure you want to send this update?"
          confirmButtonLabel="Send update"
          cancelButtonLabel="Discard"
          open={this.props.isResendDialogOpen}
          confirmActionHandler={() => this.onConfirmForceSendUpdate()}
          closeDialogHandler={() => this.props.closeResendDialog()}
        />
        <VesselNameTitle>
          <BackButton onClick={() => this.props.push('/mailer')} />
          {update.vessel}
        </VesselNameTitle>
        <Grid isFileUpdate={update.fileUpdate} isPortUpdate={update.portUpdate}>
          <UpdateDetailsContainer>
            {update.fileUpdate && <FileUpdateContainer update={update.fileUpdate} />}
            {update.portUpdate && (
              <PortUpdateContainer
                update={update.portUpdate}
                isEditable={isEditable}
                setUpdateTimeline={setUpdateTimeline}
                onChangeHandler={this.onChangeHandler}
              />
            )}
          </UpdateDetailsContainer>
          <UpdateInfoContainer>
            <VoyageDetails
              update={update}
              voyage={updateVesselVoyage}
              voyageLoading={this.isVesselVoyagePending()}
              voyageLoadingError={hasLoadVesselVoyageError}
              isEditable={isEditable}
              onChangeHandler={this.onChangeHandler}
            />

            <UpdateDetails
              update={update}
              isEditable={isEditable}
              onChangeHandler={this.onChangeHandler}
            />
          </UpdateInfoContainer>
        </Grid>
        <Actions>
          {hasWritePermission && update && (
            update.handled ? (
              update.status === 'rejected' &&
              <Button variant="outlined" onClick={this.unarchiveUpdate}>Mark as &apos;To be processed&apos;</Button>
            ) : (
              <>
                {(update.status === UpdateStatusTypes.rejected || !update.recipients.length) && (
                // In the design the label is 'resend update' if the update is not changed,
                // and 'send update' if it is changed. But I doubt if this is clear for the user.
                // TODO: Make the action of send/resend update clear to the user
                  <Button variant="contained" onClick={this.onClickSendUpdate}>Send update</Button>
                )}
                <Button variant="outlined" onClick={this.archiveUpdate}>Mark as processed</Button>
              </>
            )
          )}

          <Button
            variant="outlined"
            target="_blank"
            rel="noopener noreferrer"
            href={createMailtoLink(update, vessels, operators)}
          >
            Send email
          </Button>
          {/*
            There's no design for (the placement of) the prev/next nav controls. Perhaps it is not quite
            right semantically to put it here in the 'actions' area, but there's space here and it is
            usefull to have it here because this area has a fixed position. Alternatively if there's
            no requirement to have a fixed position for the nav controls, we could make them part of
            the scrollable page header (which already has the 'back to list' nav button).
          */}
          <EditScreenNav updateId={update.messageId} isPending={this.isPending()} />
        </Actions>
      </PageContainer>
    );
  }
}

export default connect(
  createStructuredSelector({
    updateVesselVoyage: view(updateVesselVoyageState),
    loadUpdateVesselVoyageError: view(updateVesselVoyageErrorState),
    updateVesselVoyageLoading: view(voyagesLoaderState),
    list: view(listState),
    isPending: view(isPendingState),
    isResendDialogOpen: view(openResendDialogState),
    updateInitial: view(updateInitialState),
    update: view(updateState),
    hasWritePermission,
    isVesselsPending: view(isVesselsPendingState),
    vessels: view(vesselsState),
    isOperatorsPending: view(isOperatorsPendingState),
    operators: view(operatorsState),
  }),
  {
    ...actions,
    ...documentActions,
    ...vesselsActions,
    ...operatorsActions,
    ...voyageActions,
    push,
  },
)(UpdatesItem);
