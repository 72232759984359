import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const MapComponent = ({imo, vesselIsLoading}) => (
  <Container>
    <Button
      href={`https://www.marinetraffic.com/en/ais/details/ships/imo:${imo}/`}
      target="_blank"
      variant="contained"
      disabled={vesselIsLoading}
    >
      Marine Traffic details page
    </Button>
  </Container>
);

MapComponent.propTypes = {
  imo: PropTypes.string.isRequired,
  vesselIsLoading: PropTypes.bool.isRequired,
};

export default MapComponent;
