'use strict';

const tcomb = require ('tcomb');

const {model, submodel, maybe, alias} = require ('../utils/domain');
const common = require ('./common');
const shipping = require ('./shipping');
const agent = module.exports;

agent.Address = alias ('Address') (tcomb.String);
agent.City = alias ('City') (tcomb.String);
agent.Country = alias ('Country') (tcomb.String);
agent.Email = alias ('Email') (tcomb.String);
agent.Fax = alias ('Fax') (tcomb.String);
agent.Mobile = alias ('Mobile') (tcomb.String);
agent.Phone = alias ('Phone') (tcomb.String);
agent.Postcode = alias ('Postcode') (tcomb.String);
agent.UpdatedAt = alias ('UpdatedAt') (common.Instant);
agent.LastCheckedAt = alias ('LastCheckedAt') (common.Instant);

agent.AgentIdentifier = model ('AgentIdentifier') ({
  vesselImo: shipping.IMO,
  voyage: shipping.VoyageNumber,
  portCode: shipping.PortCode,
});

agent.Agent = submodel ('Agent') (agent.AgentIdentifier) ({
  address: maybe (agent.Address),
  city: maybe (agent.City),
  country: maybe (agent.Country),
  email: maybe (agent.Email),
  fax: maybe (agent.Fax),
  mobile: maybe (agent.Mobile),
  name: maybe (common.HumanName),
  phone: maybe (agent.Phone),
  postcode: maybe (agent.Postcode),
  updatedAt: agent.UpdatedAt,
  lastCheckedAt: agent.LastCheckedAt,
});

agent.getIdentifier = o => {
  agent.Agent (o);
  return agent.AgentIdentifier ({
    vesselImo: o.vesselImo,
    voyage: o.voyage,
    portCode: o.portCode,
  });
};
