import {put, takeEvery, select, debounce, call, all} from 'redux-saga/effects';
import {push, LOCATION_CHANGE} from 'connected-react-router';
import axios from 'axios';
import {
  view,
  head,
  last,
  when,
  cond,
  equals,
  always,
  has,
  evolve,
  T,
  identity
} from 'ramda';
import {
  types, activeTabFilterState, columnFilterState,
  pageNumberState, rowsPerPageState, sortingState, selectedItemsState,
} from '../reducers/updates';
import {DateRange, getDateRangeValueForLabel} from '../shapes/date-range';
import {UpdatesListField, TabFilterType} from '../containers/Updates/constants';
import {FilterType} from '../shapes';
import {pump} from '../../common/functions';

const fetchUpdates = function* () {
  const tabFilter = yield select(view(activeTabFilterState));
  const columnFilters = yield select(view(columnFilterState));
  const pageNumber = yield select(view(pageNumberState));
  const rowsPerPage = yield select(view(rowsPerPageState));
  const sorting = yield select(view(sortingState));
  const filesOnly = tabFilter === TabFilterType.FILE;
  const isHandled = tabFilter === TabFilterType.HANDLED;

  try {
    const queryBase = '/updates';
    const params = new URLSearchParams();
    params.append('start', pageNumber * rowsPerPage);
    params.append('limit', rowsPerPage);
    params.append('sort', sorting.isSortAscending ? 'asc' : 'desc');
    params.append('orderBy', sorting.sortBy);
    params.append('type', filesOnly ? 'file' : 'port');
    if (!filesOnly) {
      params.append('handled', isHandled);
    }

    const createdAtDateRange = getDateRangeValueForLabel(
      columnFilters[UpdatesListField.CREATED_AT],
    );
    if(createdAtDateRange.start !== undefined) {
      params.append('createdAtStart', createdAtDateRange.start);
    }
    if(createdAtDateRange.end !== undefined) {
      params.append('createdAtEnd', createdAtDateRange.end);
    }
    const portSearch = columnFilters[UpdatesListField.PORT];
    if(!filesOnly && portSearch) {
      params.append('port', portSearch);
    }
    const voyageNumberSearch = columnFilters[UpdatesListField.VOYAGE];
    if(voyageNumberSearch) {
      params.append('voyage', voyageNumberSearch);
    }
    const fileNameSearch = columnFilters[UpdatesListField.FILE_NAME];
    if(filesOnly && fileNameSearch) {
      params.append('fileName', fileNameSearch);
    }
    columnFilters[UpdatesListField.VESSEL].forEach(name => params.append('vessels[]', name));
    columnFilters[UpdatesListField.STATUS].forEach(status => params.append('status[]', status));
    const response = yield axios.get(`${queryBase}?${params.toString()}`);
    return response.data;
  } catch (e) {
    yield put({type: types.setUpdatesListError, message: e.message});
    return null;
  }
};

const getUpdatesList = function* () {
  const list = yield call(fetchUpdates);
  if (list) {
    yield put({type: types.setUpdatesList, payload: list});
  }
};

const getUpdateById = function* ({payload}) {
  try {
    const response = yield axios.get(`/updates/${payload.id}`);

    if (payload.markAsRead && response.data && !response.data.read) {
      yield axios.post(`/updates/read/${payload.id}`);
    }

    yield put({type: types.setUpdateInitial, payload: response.data});
  } catch (e) {
    yield put({type: types.setUpdateError, message: e.message});
    yield put({type: types.setHasRecipients, payload: false});
  }
};

const pushUpdate = function* ({payload}) {
  try {
    const {messageId, newUpdate} = payload;
    yield axios.post('/updates', newUpdate);
    yield axios.post(`/updates/handle/${messageId}`);
    yield put({type: types.pushUpdateSuccess});
  } catch (e) {
    yield put({type: types.pushUpdateError, message: e.message});
  }
};

const archiveUpdate = function* ({payload}) {
  try {
    yield axios.post(`/updates/handle/${payload}`);
    yield put({type: types.archiveUpdateSuccess});
  } catch (e) {
    yield put({type: types.archiveUpdateError, message: e.message});
  }
};

const unarchiveUpdate = function* ({payload}) {
  try {
    yield axios.post(`/updates/unarchive/${payload}`);
    yield put({type: types.unarchiveUpdateSuccess});
  } catch (e) {
    yield put({type: types.unarchiveUpdateError, message: e.message});
  }
};

const archiveOne = function* (id) {
  try {
    yield call(axios.post, `/updates/handle/${id}`);
    yield put({type: types.removeUpdate, payload: id});
  } catch (e) {
    yield put({type: types.pushArchivalError, message: e.message});
  }
};

const unarchiveOne = function* (id) {
  try {
    yield call(axios.post, `/updates/unarchive/${id}`);
    yield put({type: types.removeUpdate, payload: id});
  } catch (e) {
    yield put({type: types.pushArchivalError, message: e.message});
  }
};

const archiveSelected = function* () {
  const selected = yield select(view(selectedItemsState));
  yield all(selected.map(id => call(archiveOne, id)));
  yield put({type: types.finishArchival});
};

const unarchiveSelected = function* () {
  const selected = yield select(view(selectedItemsState));
  yield all(selected.map(id => call(unarchiveOne, id)));
  yield put({type: types.finishArchival});
};

const backToUpdateList = function* () {
  yield put(push('/mailer'));
};

const backToDashboardList = function* () {
  yield put(push('/'));
};

const onLocationChange = function* (action) {
  const currentRoute = action.payload.location.pathname;
  const prevRoute = action.payload.prevPathname;
  // When we leave the updates branch (list + detail), reset part of the list UI state.
  if(prevRoute.startsWith('/mailer') && !currentRoute.startsWith('/mailer')) {
    yield put({type: types.setPageNumber, payload: 0});
  }
};

const shouldDebounceGetUpdatesList = action => action.type === types.setColumnFilter &&
  action.payload.columnDef.filter.type === FilterType.SEARCH;

const localFiltersMigrations = [
  when(has('createdAt'), evolve({
    createdAt: cond([
      [equals(DateRange.LEGACY_LAST_5_DAYS.label), always(DateRange.LAST_7_DAYS.label)],
      [equals(DateRange.LEGACY_UPCOMING_5_DAYS.label), always(DateRange.UPCOMING_7_DAYS.label)],
      [T, identity]
    ])
  }))
];

const loadColumnFilters = function* () {
  const savedFilters = JSON.parse(localStorage.getItem('updatesFilters') || '{}');
  const updatedFilters = pump(savedFilters)(localFiltersMigrations);
  localStorage.setItem('updatesFilters', JSON.stringify(updatedFilters));
  yield put({type: types.restoreFilters, payload: updatedFilters});
};

const saveColumnFilters = function* () {
  const columnFilters = yield select(view(columnFilterState));
  localStorage.setItem('updatesFilters', JSON.stringify(columnFilters));
};

const navigateTo = update => put(push(`/mailer/${update.messageId}`));

const fetchUpdatesAndGoTo = headOrLast => function* () {
  const list = yield call(fetchUpdates);
  if (list) {
    yield put({type: types.setUpdatesList, payload: list});
    if(list.updates.length) {
      yield navigateTo(headOrLast(list.updates));
      // Alternatively we could call getUpdateById here directly, but just changing
      // the route and letting the component dispatch it seems to work fine.
    }
  }
};

export default function* () {
  yield takeEvery(action => [
    types.getUpdatesList,
    types.setTabFilter,
    types.setColumnFilter,
    types.clearColumnFilter,
    types.setPageNumber,
    types.setRowsPerPage,
    types.setSorting,
    types.finishArchival,
  ].includes(action.type) && !shouldDebounceGetUpdatesList(action), getUpdatesList);
  yield debounce(500, shouldDebounceGetUpdatesList, getUpdatesList);
  yield takeEvery(types.getUpdateById, getUpdateById);
  yield takeEvery(types.pushUpdate, pushUpdate);
  yield takeEvery(types.archiveUpdate, archiveUpdate);
  yield takeEvery(types.unarchiveUpdate, unarchiveUpdate);
  yield takeEvery(types.archiveSelected, archiveSelected);
  yield takeEvery(types.unarchiveSelected, unarchiveSelected);
  yield takeEvery(types.archiveUpdateSuccess, backToUpdateList);
  yield takeEvery(types.unarchiveUpdateSuccess, backToUpdateList);
  yield takeEvery(types.pushUpdateSuccess, backToUpdateList);
  yield takeEvery(types.setUpdateError, backToUpdateList);
  yield takeEvery(types.setUpdatesListError, backToDashboardList);
  yield takeEvery(LOCATION_CHANGE, onLocationChange);

  yield takeEvery(action => [
    types.setColumnFilter,
    types.clearColumnFilter,
  ].includes(action.type), saveColumnFilters);
  yield takeEvery(types.initColumnFilters, loadColumnFilters);
  yield takeEvery(types.goToFirstOfNextPage, fetchUpdatesAndGoTo(head));
  yield takeEvery(types.goToLastOfPrevPage, fetchUpdatesAndGoTo(last));
}
