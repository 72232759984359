import {lensProp, compose, set} from 'ramda';
import {createReducer, noopAction} from 'warped-reducers';

import {defaultTo} from '../utils/state';

export const baseLens = lensProp('vessel');

export const teamState = compose(
  baseLens,
  lensProp('team'),
  defaultTo(null),
);

export const teamLoaderState = compose(
  baseLens,
  lensProp('teamLoader'),
  defaultTo(false),
);

export const teamServiceUnavailable = compose(
  baseLens,
  lensProp('teamsServiceUnavailable'),
  defaultTo(false),
);

export const vesselState = compose(
  baseLens,
  lensProp('vessel'),
  defaultTo(null),
);

export const vesselLoaderState = compose(
  baseLens,
  lensProp('vesselLoader'),
  defaultTo(false),
);

export const vesselsLoaderState = compose(
  baseLens,
  lensProp('vesselsLoader'),
  defaultTo(false),
);

export const {reducer, types, actions} = createReducer('vessel')({
  setVesselsLoader: set(vesselsLoaderState),
  loadTeam: noopAction,
  setTeam: set(teamState),
  setTeamServiceUnavailable: set(teamServiceUnavailable),
  setTeamLoader: set(teamLoaderState),
  loadVessel: noopAction,
  setVessel: set(vesselState),
  setVesselLoader: set(vesselLoaderState),
});
