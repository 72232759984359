import React, {Fragment, useRef} from 'react';
import PropTypes from 'prop-types';
import {view} from 'ramda';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import {$navText} from '../styles/variables';
import {StyledMenu, StyledMenuItem} from './Menu';
import {actions, settingMenuLens} from '../reducers/navigation';

const Button = styled.button`
  margin: 0;
  padding: 4px;
  border: none;
  background: none;
  font-size: 28px;
  color: ${$navText};
  display: flex;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;
`;

//eslint-disable-next-line react/display-name
const Link = React.forwardRef((props, ref) => <NavLink {...props} innerRef={ref} />);

const SettingsMenu = ({routes, isOpen, openSettingsMenu, closeSettingsMenu}) => {
  const anchorEl = useRef(null);

  return (
    <>
      <Button ref={anchorEl} onClick={() => openSettingsMenu()}>
        <SettingsIcon fontSize="inherit" />
      </Button>
      <StyledMenu
        id="settings-menu"
        anchorEl={anchorEl.current}
        disableScrollLock
        elevation={2}
        keepMounted
        open={isOpen}
        onClose={() => closeSettingsMenu()}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {routes.map(route =>
          <StyledMenuItem
            onClick={() => closeSettingsMenu()}
            component={Link}
            to={route.url}
            key={route.name}
          >
            {route.name}
          </StyledMenuItem>,
        )}
      </StyledMenu>
    </>
  );
};

SettingsMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openSettingsMenu: PropTypes.func.isRequired,
  closeSettingsMenu: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
};

export default connect(
  state => ({
    isOpen: Boolean(view(settingMenuLens, state)),
  }),
  actions,
)(SettingsMenu);
