import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import {FilterType} from '../../shapes';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {Radio} from '@material-ui/core';
import SearchField from '../SearchField';
import MultiSelect from '../MultiSelect';

const TableFilter = ({
  type, onChange, value, onClear,
  selectProps, inactiveValue, inputType,
}) => {
  const isInactive = (type === FilterType.MULTI && value.length === 0)
    || (type === FilterType.SINGLE && value === inactiveValue)
    || (type === FilterType.SEARCH && value === '');

  return (
    <Root isInactive={isInactive}>
      {type === FilterType.SINGLE && (
        <Select
          value={value}
          onChange={e => onChange(e.target.value)}
          input={<OutlinedInput />}
          fullWidth
          placeholder="Select"
          displayEmpty
          renderValue={value => value}
        >
          {selectProps.options.map(option => (
            <MenuItem
              key={selectProps.labelSelector(option)}
              value={selectProps.valueSelector(option)}
            >
              <Radio checked={value === selectProps.valueSelector(option)} color="primary" />
              {selectProps.labelSelector(option)}
            </MenuItem>
          ))}
        </Select>
      )}
      {(type === FilterType.MULTI || type === FilterType.MULTI_ALL) && (
        <MultiSelect
          type={type}
          value={value}
          selectProps={selectProps}
          onChange={onChange}
        />
      )}
      {type === FilterType.SEARCH && (
        <SearchField
          value={value}
          onChange={e => onChange(e.target.value)}
          fullWidth
          placeholder="Search"
          onClear={onClear}
          type={inputType}
        />
      )}
    </Root>
  );
};

TableFilter.propTypes = {
  type: PropTypes.oneOf(Object.values(FilterType)),
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  selectProps: PropTypes.shape({
    options: PropTypes.array.isRequired,
    labelSelector: PropTypes.func.isRequired,
    valueSelector: PropTypes.func.isRequired,
  }),
  /**
   * inactiveValue is the value of a single-select filter (radio)
   * that means that no filtering will happen. For example 'any time' or 'no
   * This prop be used to for example gray out the UI.
   */
  inactiveValue: PropTypes.string,
  inputType: PropTypes.string,
};

const inactiveStyleFnc = ({isInactive}) => isInactive ? '#C2D1D9 !important' : 'black !important';

const Root = styled.div`
  position: relative;
  min-height: 38px;
  .MuiSelect-selectMenu, .MuiInputBase-input {
    font-size: 12px;
    line-height: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
  }
  .MuiSvgIcon-root {
    color: ${inactiveStyleFnc};
  }
  .MuiOutlinedInput-root {
    color: ${inactiveStyleFnc};
  }
  input::placeholder {
    color: ${inactiveStyleFnc};
    opacity: 1;
  }
`;

export default TableFilter;
