import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import {$green, $grayLight, $red} from '../styles/variables';

const StyledCloseIcon = styled(CloseIcon)`
  &:hover {
    fill: ${$red}
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  &:hover {
    fill: ${$green}
  }
`;

const RawInput = styled(InputBase)`
  width: 100%;

  ${props => props.type === 'number' && `
    padding-right: 22px;
  `}
`;

const MaterialInput = styled(TextField)`
  width: 100%;
  padding: 0;
  padding-right: 22px;

  ${props => props.type === 'number' && `
    padding-right: 22px;
  `}
`;

const Adornment = styled.div`
  position: absolute;
  right: 0;
  top: 3px;
  display: none;

  svg {
    font-size: 22px;
    padding-left: 5px;
    border-radius: 3px;
    cursor: pointer;
    fill: ${$grayLight};
    box-sizing: border-box;
  }
`;

const FieldContainer = styled.div`
  width: 100%;
  position: relative;

  &:hover ${Adornment} {
    display: flex;
  }

  ${RawInput} {
    font-size: 14px !important;
    line-height: 1.2em;
  }
`;

class EditableText extends React.Component {
  static propTypes = {
    fieldName: PropTypes.string.isRequired,
    fieldValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    multiline: PropTypes.bool,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    className: PropTypes.any,
    type: PropTypes.oneOf(['text', 'date', 'datetime-local', 'time', 'number']),
    isEditable: PropTypes.bool,
  }

  static defaultProps = {
    type: 'text',
    isEditable: true,
  }

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      isEditing: false,
      value: props.fieldValue,
    };
  }

  save = () => {
    const value = this.props.type === 'number' ? parseInt(this.state.value, 10) : this.state.value;
    this.props.onChange && this.props.onChange(value);
  }

  onBlurHandler = e => {
    if (e.currentTarget.name !== this.props.fieldName) {
      this.setState({isEditing: false});
      this.save();
    }
  }

  onSaveHandler = () => {
    this.setState({isEditing: false});
    this.save();
  }

  onChangeHandler = ({target}) => this.setState({value: target.value === '' ? null : target.value})

  onDeleteHandler = () => this.setState({isEditing: false})

  onEditClickHandler = () => this.setState({isEditing: true})

  render() {
    const {fieldName, className, multiline, onDelete, onChange, type, isEditable} = this.props;
    const {isEditing} = this.state;

    const Field = isEditing ? MaterialInput : RawInput;
    return (
      <FieldContainer onBlurCapture={this.onBlurHandler}>
        {this.state.value === null && isEditing === false ? (
          <RawInput style={{fontStyle: 'italic'}} value="None Given" />
        ) : (
          <Field
            ref={this.inputRef}
            multiline={multiline}
            readOnly={!this.state.isEditing || !this.onChangeHandler}
            autoFocus={isEditing}
            className={className}
            type={type}
            onChange={this.onChangeHandler}
            name={fieldName}
            value={this.state.value || ''}
          />
        )}
        {onChange && isEditable && (
          <Adornment>
            {!isEditing && <EditIcon onClick={this.onEditClickHandler} />}
            {isEditing && type === 'text' && <StyledCheckIcon onClick={this.onSaveHandler} />}
            {onDelete && <StyledCloseIcon onClick={this.onDeleteHandler} />}
          </Adornment>
        )}
      </FieldContainer>
    );
  }
}

export default EditableText;
