import React from 'react';
import PropTypes from 'prop-types';
import {CircularProgress} from '@material-ui/core';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: ${props => props.noPadding ? '0px' : '15px'}
  min-height: ${props => props.full ? 'calc(100vh - 80px)' : `${props.minHeight || 100}px`};
`;

const Loader = ({full, size, noPadding, minHeight}) => (
  <Container full={full} noPadding={noPadding} minHeight={minHeight}>
    <CircularProgress size={size}/>
  </Container>
);

Loader.propTypes = {
  full: PropTypes.bool,
  size: PropTypes.oneOf(PropTypes.string, PropTypes.number),
  noPadding: PropTypes.bool,
  minHeight: PropTypes.number
};

export default Loader;
