// colors
export const $navy = '#152049';
export const $navy2 = '#4f5d82';
export const $navyText = '#001F4C';
export const $gold = '#ffd800';
export const $blue = '#1ba5d8';
export const $blue2 = '#22437a';
export const $blueLight = '#f2faff';
export const $gray = '#6F6F6E';
export const $grayLight = '#ccc';
export const $gray3 = '#CBCBCB';
export const $gray4 = '#EBEBEB';
export const $white = '#fff';
export const $green = '#3eb54e';
export const $red = '#e53945';
export const $wine = '#a30f04';
export const $black = '#0b0b0b';
export const $highlight = '#FFFFF1';
export const $orange = '#EC9036';

//AV colors
export const $nav = '#004A86';
export const $navText = '#99B7CF';
export const $navHighlight = '#005396';
export const $bg = '#FAFAFA';
export const $enricher = '#FFFFFF';
export const $avOrange = '#ED9A43';
export const $alertGreen = '#20BF55';
export const $alertRed = '#E84855';
export const $alertYellow = '#FFE66D';
export const $alertGrey = '#536271';
export const $alertBlue = '#00A7E1';
export const $text = '#4C5563';

// sizes for constat paddings, margins, etc..
export const $sizeXs = '2px';
export const $sizeSm = '4px';
export const $sizeMd = '8px';
export const $sizeLg = '16px';
export const $mqMobilePortrait = '320px !default';
export const $mqMobileLandscape = '480px !default';
export const $mqTabletPortrait = '768px !default';
export const $mqTabletLandscape = '1024px !default';
export const $mqDesktop = '1382px !default';
