import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import {ZonedDateTime, Instant} from '@js-joda/core';
import PropTypes from 'prop-types';
import {ago} from 'time-ago';
import InfoIcon from '@material-ui/icons/Info';
import {columnProperties} from './columnProperties';
import DateText from '../DateText';
import {$text, $gray4, $gray3, $nav} from '../../styles/variables';
import ClickPopover, {PopoverColumn, PopoverLabel} from '../ClickPopover';

const VoyageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 16px 0;
  border-bottom: 1px solid #C2C2C2;
  &:last-of-type {
    border-bottom: 0px;
  }
`;

const PositionsContainer = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
`;

const PositionRow = styled.div`
  width:100%;
  min-height: 34px;
  display: flex;
  align-items: center;
`;

const Cell = styled.div`
  flex: ${props => `0 0 ${props.ratio}%`};
  display: flex;
`;

const VoyageColumn = styled.div`
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 0 0 10%;
  color: ${$text};
  font-weight: 600;
`;

const DetailsColumn = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  flex: 0 0 5%;
`;

const ColumnContent = styled.div`
  min-height: 36px;
  display: flex;
  align-items: center;
`;

const Details = styled.div`
  color: ${$text};
  font-size: 14px;
  display: flex;
  justify-content: center;
`;

const EmptyItinerary = styled.div`
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${$gray3};
`;

const UpdatedAt = styled.div`
  color: ${$nav};
  font-size: 12px;
  font-weight: 500;
  margin-top: 16px;
  text-transform: uppercase;
`;

const DetailsIcon = styled.div`
  cursor: pointer;
  color: #fff;
  position: relative;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;

  svg {
    position: relative;
    z-index: 2;
    font-size: 36px;
  }

  &:after {
    content: '';
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${$text};
    transform: scale(0.8);
    z-index: 1;
  }
  &:hover {
    color: ${$gray4};
  }
`;

const itineraryLastUpdated = itinerary => (
  itinerary.length === 0 ? null : (
    itinerary
    .map(({updatedAt}) => Instant.parse(updatedAt))
    .reduce((a, b) => a.isAfter(b) ? a : b)
    .toString()
  )
);

const DetailsComponent = ({voyage}) => {
  const [openPopover, setPopover] = useState(false);
  const anchorEl = useRef(null);

  return (
    <Details>
      <DetailsIcon ref={anchorEl} onClick={() => setPopover(!openPopover)}>
        <InfoIcon />
      </DetailsIcon>
      <ClickPopover
        isOpen={openPopover}
        setPopover={() => setPopover(false)}
        anchorEl={anchorEl}
      >
        <Details>
          <PopoverColumn>
            <PopoverLabel>Cargo Grade</PopoverLabel>
            <PopoverLabel>Charterer</PopoverLabel>
            <PopoverLabel>Laycan Start</PopoverLabel>
            <PopoverLabel>Laycan End</PopoverLabel>
            <PopoverLabel>Voyage type</PopoverLabel>
          </PopoverColumn>
          <PopoverColumn>
            <div>{voyage.cargo || 'Unknown'}</div>
            <div>{voyage.company || 'Unknown'}</div>
            <div>{voyage.laycanFrom ?
              <DateText date={ZonedDateTime.parse(voyage.laycanFrom)} /> :
              'Unknown'}
            </div>
            <div>{voyage.laycanTo ?
              <DateText date={ZonedDateTime.parse(voyage.laycanTo)} /> :
              'Unknown'}
            </div>
            <div>{voyage.voyageType || 'Unknown'}</div>
          </PopoverColumn>
        </Details>
        <UpdatedAt>
          {`Last updated in IMOS, ${
            ago(itineraryLastUpdated(voyage.itinerary) || voyage.updatedAt)
          }`}
        </UpdatedAt>
      </ClickPopover>
    </Details>
  );
};

const Voyage = ({voyage}) =>
  <VoyageContainer>
    <VoyageColumn>
      <ColumnContent>{voyage.voyage}</ColumnContent>
    </VoyageColumn>
    <PositionsContainer>
      {voyage.itinerary.length === 0 && (
        <EmptyItinerary>
          No known portcalls in itinerary
        </EmptyItinerary>
      )}
      {voyage.itinerary.map(position => (
        <PositionRow key={`${voyage.vessel}/${voyage.voyage}/${position.sequence}`}>
          {columnProperties.map(column => (
            <Cell ratio={column.width} key={column.label}>
              <column.render {...position} />
            </Cell>
          ))}
        </PositionRow>
      ))}
    </PositionsContainer>
    <DetailsColumn>
      <DetailsComponent voyage={voyage} />
    </DetailsColumn>
  </VoyageContainer>;

Voyage.propTypes = {
  voyage: PropTypes.object.isRequired,
};
DetailsComponent.propTypes = Voyage.propTypes;

export default Voyage;
