import axios from 'axios';
import {objEq} from './utils';

const equal = objEq(['name', 'client']);

const list = () => ({
  type: 'BROKER_LIST',
  payload: axios.get('/brokers').then(res => res.data),
});

const update = updateObject => ({
  type: 'BROKER_UPDATE',
  payload: axios.patch('/brokers', updateObject).then(() => updateObject),
});

export {
  list,
  update,
  equal,
};

