import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Voyage from './Voyage';
import {ElevatedContainerStyle} from '../../styles/global';

const VesselContainer = styled.div`
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  ${ElevatedContainerStyle};
`;

const Vessel = ({vessel: {voyages}}) =>
  <VesselContainer>
    {
      voyages.map(voyage =>
        <Voyage key={voyage.voyage} voyage={voyage} />,
      )
    }
  </VesselContainer>;

Vessel.propTypes = {
  vessel: PropTypes.shape({
    voyages: PropTypes.array.isRequired,
  }),
};

export default Vessel;
