'use strict';

const tcomb = require ('tcomb');
const {alias, model, enumeration, dictionary, maybe} = require ('../utils/domain');
const common = require ('./common');
const shipping = require ('./shipping');
const crew = module.exports;

crew.Role = enumeration ('Role') ({
  'Master': 1,
  'Chief Engineer': 2,
  'Chief Officer': 3,
  '2nd Engineer': 4,
});

crew.roles = Object.keys (crew.Role.meta.map);
crew.rank = role => crew.Role.meta.map[crew.Role (role)];

crew.Id = alias ('Id') (tcomb.String);

crew.Image = model ('Image') ({
  url: common.URI,
  hash: common.Md5,
});

crew.CrewMember = model ('CrewMember') ({
  id: crew.Id,
  vessel: shipping.VesselName,
  name: common.HumanName,
  role: crew.Role,
  image: maybe (crew.Image),
  onboardAt: common.Instant,
  updatedAt: common.Instant,
  lastCheckedAt: common.Instant,
});

crew.Crew = dictionary (crew.Role) (crew.CrewMember);
