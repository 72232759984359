import React, {Fragment, useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import {view} from 'ramda';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {NavLink} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';
import styled from 'styled-components';
import SearchField from './SearchField';
import Loader from './Loader';
import {
  actions as vesselsActions,
  isPendingState as isVesselsPendingState,
  listState as vesselsListState,
} from '../reducers/vessels';
import {StyledMenu} from './Menu';
import {fleetMenuActiveLens, fleetMenuFilterLens, actions} from '../reducers/navigation';

const findByName = (search, name) => name.toLowerCase().includes(search);

const FleetContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Filter = styled.div`
  display: flex;
  padding: 20px;
  padding-bottom: 10px;

  .MuiInputBase-input {
    font-size: 14px;
    line-height: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
    background: #fff;
  }
`;

const List = styled.div`
  padding: 20px;
  display: grid;
  grid-template-rows: repeat(${props => Math.ceil(props.count / 3)}, 1fr);
  grid-auto-flow: column;
  grid-gap: 10px 100px;
`;

const VesselContainer = styled.div`
  a {
    color: ${props => props.dim ? '#ccc' : '#222'};
  }
`;

const Vessel = styled(NavLink)`
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const FleetNavigation = ({
  children,
  getVesselsList,
  isVesselsPending,
  vessels,
  filter,
  isOpen,
  openFleetMenu,
  closeFleetMenu,
  setFleetFilter,
}) => {

  useEffect(() => {
    getVesselsList();
  }, []);

  const anchorEl = useRef(null);
  const inputEl = useRef(null);

  const Trigger = React.cloneElement(children, {
    ...children.props,
    isOpen,
    ref: anchorEl,
    onClick: () => {
      setFleetFilter('');
      openFleetMenu();
    },
  });

  const renderList = list => {
    const search = filter.toLowerCase();
    const matching = list.filter(({name}) => findByName(search, name));
    return (
      <List count={list.length}>
        {list.map(vessel =>
          <VesselContainer dim={!matching.includes(vessel)} key={vessel.name}>
            <Vessel onClick={() => closeFleetMenu()} to={`/vessel/${vessel.name}`}>
              {vessel.name}
            </Vessel>
          </VesselContainer>
          ,
        )}
      </List>
    );
  };

  return (
    <>
      {Trigger}
      <StyledMenu
        id="fleet-navigation"
        anchorEl={anchorEl.current}
        classes={{paper: 'skip-arrow'}}
        elevation={2}
        keepMounted
        onEntered={() => inputEl.current.focus()}
        open={isOpen}
        onClose={() => closeFleetMenu()}
        transitionDuration={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <FleetContainer>
          <Filter>
            <SearchField
              type="text"
              inputRef={inputEl}
              placeholder="Ship name..."
              name="filter"
              value={filter}
              onChange={e => setFleetFilter(e.target.value)}
            />
          </Filter>
          {isVesselsPending
            ? <Loader />
            : renderList(vessels)}
        </FleetContainer>
      </StyledMenu>
    </>
  );
};

FleetNavigation.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  filter: PropTypes.string.isRequired,
  getVesselsList: PropTypes.func.isRequired,
  isVesselsPending: PropTypes.bool.isRequired,
  vessels: PropTypes.array.isRequired,
  openFleetMenu: PropTypes.func.isRequired,
  closeFleetMenu: PropTypes.func.isRequired,
  setFleetFilter: PropTypes.func.isRequired,
};

export default withRouter(connect(
  createStructuredSelector({
    isVesselsPending: view(isVesselsPendingState),
    vessels: view(vesselsListState),
    isOpen: view(fleetMenuActiveLens),
    filter: view(fleetMenuFilterLens),
  }),
  {
    ...vesselsActions,
    ...actions,
  },
)(FleetNavigation));
