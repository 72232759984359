import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {NavLink, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import {Route, Switch} from 'react-router';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FleetNavigation from '../../components/FleetNavigation';
import UserMenu from '../../components/UserMenu';
import SettingsMenu from '../../components/SettingsMenu';
import ErrorHandler from '../../components/ErrorHandler';

import {
  Vessel,
  Error404,
  Customers,
  Brokers,
  Operators,
  Overview,
  Vessels,
  UpdatesList,
  UpdatesEdit,
  PositionList,
  Checklists,
} from '../../containers';
import {Loader} from '../../components';
import {withAuth} from '@okta/okta-react';
import {view, compose, lensProp} from 'ramda';
import {
  actions as sessionActions,
  tokenState,
  userState,
  hasReadPermission,
  hasWritePermission,
} from '../../reducers/session';

import {setupSession} from '../../services/session';
import {$navText, $white} from '../../styles/variables';
import {actions as documentActions} from '../../reducers/documentTitle';
import {overridePermissionGroups} from '../../../common/domain/permissions';

const StyledAppBar = styled(AppBar)`
  color: ${$white};
  min-height: 60px;
  box-shadow: none !important;
  position: sticky;
  top: 0;
`;

const AppContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`;

const DashboardView = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #fff;
  img {
    margin-right: 16px;
  }
`;

const Logo = styled.img`
  margin-right: 8px;
  max-width: 48px;
`;

const Navigation = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 0 48px;
`;

const linkStyle = `
  color: ${$navText};
  margin-right: 64px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.active, &:hover {
    color: #fff;
  }
`;

const RouteLink = styled(NavLink)`
  ${linkStyle};

  &:last-of-type {
    margin-right: 0;
  }
`;
const FleetDropdown = styled.div`
  ${linkStyle};
  margin-right: 64px;
  min-height: 60px;
  position: relative;
  &:before {
    display: ${props => props.isOpen ? 'block' : 'none'};
    content: '';
    position: absolute;
    bottom: 0;
    right: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #fff transparent;
  }
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const settingsRoutes = [
  {name: 'Customers', url: '/settings/customers'},
  {name: 'Brokers', url: '/settings/brokers'},
  {name: 'Operators', url: '/settings/operators'},
  {name: 'Vessels', url: '/settings/vessels'},
];

class App extends Component {

  static propTypes = {
    auth: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    showSettings: PropTypes.bool.isRequired,
    showMailer: PropTypes.bool.isRequired,
    showChecklists: PropTypes.bool.isRequired,
    showStats: PropTypes.bool.isRequired,
    setDocumentTitle: PropTypes.func.isRequired,
    setSession: PropTypes.func,
    username: PropTypes.string,
  };

  constructor(props) {
    super(props);
    Promise.all([props.auth.getIdToken(), props.auth.getUser()]).then(([token, user]) => {
      setupSession(token);
      const {groups} = JSON.parse(atob(token.split('.')[1]));
      props.setSession({token, user, groups: overridePermissionGroups || groups});
    });
  }

  componentDidMount() {
    this.props.setDocumentTitle();
  }

  //eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    if(!props.isLoggedIn) {
      this.props.setSession({});
      this.props.auth.logout('/');
    }
  }

  onlogout = () => {
    this.props.setSession({});
    this.props.auth.logout('/');
  }

  renderRoutes = () => (
    <Switch>
      <Route exact path="/" component={PositionList} />
      {this.props.showSettings
        ? <Route path="/settings/brokers" component={Brokers} />
        : null}
      {this.props.showSettings
        ? <Route path="/settings/operators" component={Operators} />
        : null}
      {this.props.showSettings
        ? <Route path="/settings/vessels" component={Vessels} />
        : null}
      {this.props.showSettings
        ? <Route path="/settings/customers" component={Customers} />
        : null}
      {this.props.showChecklists
        ? <Route path="/checklists" component={Checklists} />
        : null}
      {this.props.showStats
        ? <Route exact path="/stats" component={Overview} />
        : null}
      {this.props.showMailer
        ? <Route exact path="/mailer" component={UpdatesList} />
        : null}
      <Route exact path="/mailer/:id" component={UpdatesEdit} />
      <Route exact path="/vessel/:vesselName" component={Vessel} />
      <Route component={Error404} />
    </Switch>
  );

  render() {

    return !this.props.isLoggedIn
      ? <Loader />
      : (
        <div>
          <ErrorHandler />
          <StyledAppBar color="default">
            <Title to="/">
              <Logo alt="logo" src="img/av_logo_border.png" />
              Bridge
            </Title>
            <Navigation>
              <FleetNavigation>
                <FleetDropdown>
                  Fleet<ArrowDropDownIcon />
                </FleetDropdown>
              </FleetNavigation>
              <RouteLink exact to="/" activeClassName="active">Position List</RouteLink>
              {this.props.showChecklists
                ? <RouteLink exact to="/checklists" activeClassName="active">Checklists</RouteLink>
                : null}
              {this.props.showMailer
                ? <RouteLink exact to="/mailer" activeClassName="active">Mailer</RouteLink>
                : null}
              {this.props.showStats
                ? <RouteLink exact to="/stats" activeClassName="active">Stats</RouteLink>
                : null}
            </Navigation>
            <Actions>
              {this.props.showSettings ? <SettingsMenu routes={settingsRoutes} /> : null}
              <UserMenu username={this.props.username} onLogout={this.onlogout} />
            </Actions>
          </StyledAppBar>
          <AppContent>
            <DashboardView>
              {this.renderRoutes()}
            </DashboardView>
          </AppContent>
        </div>
      );
  }
}

export default withAuth(connect(
  state => ({
    isLoggedIn: Boolean(view(tokenState, state)),
    showSettings: hasReadPermission(state),
    showMailer: hasReadPermission(state),
    showStats: hasReadPermission(state),
    showChecklists: hasWritePermission(state),
    username: view(compose(userState, lensProp('name')), state),
  }),
  {
    ...sessionActions,
    ...documentActions,
  },
)(App));
