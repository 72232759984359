import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'ramda';
import {MenuItem} from '@material-ui/core';

import {
  ListHeader,
  ListContainer,
  Overflow,
  CellTitle,
  Flex,
  FlexWrap,
  Label,
  InputText,
  TimelineArrow,
  TimelineBullet,
  GridArea,
  WaypointDetailsContainer,
  InputSelect,
  Pre,
  UpdateTitleCell,
  UpdateTitle
} from './styles';
import {DateTimePicker} from '../../../components';
import {UpdateShape} from '../../../shapes';
import {getPortUpdateLabel} from '../utils';

const defaultToDash = str => str || '-';

const PortUpdateContainer = ({update, isEditable, onChangeHandler, setUpdateTimeline}) => {
  const renderTimelineField = field => {
    const times = update.xtx;
    const tx = times[field];
    const firstChar = tx ? tx.type.charAt(0).toUpperCase() : 'E';
    return (
      <>
        <Label>{`${firstChar}T${field.charAt(0)}`}</Label>
        <DateTimePicker
          fieldName={`${field}.time`}
          type="datetime-local"
          fieldValue={tx ? tx.time : null}
          onChange={value => {
            setUpdateTimeline({
              [field]: {time: value, type: tx && tx.type || 'Estimation'},
            });
          }}
          readOnly={!isEditable}
          disabled={!isEditable}
        />
      </>
    );
  };

  return (
    <>
      <UpdateTitleCell half>
        <UpdateTitle>
          {getPortUpdateLabel(update.type)}
        </UpdateTitle>
      </UpdateTitleCell>
      <WaypointDetailsContainer>
        <CellTitle>Port details</CellTitle>
        <FlexWrap>
          <Flex>
            <Label>Port code</Label>
            <InputText
              fieldName="code"
              fieldValue={update.port.code}
              onChange={onChangeHandler('portUpdate.port.code')}
              isEditable={isEditable}
            />
          </Flex>
          <Flex>
            <Label>Port name</Label>
            <InputText
              fieldName="name"
              fieldValue={update.port.name}
              onChange={onChangeHandler('portUpdate.port.name')}
              isEditable={isEditable}
            />
          </Flex>
        </FlexWrap>
        <FlexWrap>
          <Flex>
            <Label>Berth name</Label>
            <InputText
              fieldName="berthName"
              fieldValue={update.berthName}
              onChange={onChangeHandler('portUpdate.berthName')}
              isEditable={isEditable}
            />
          </Flex>
          <Flex>
            <Label>Function</Label>
            <InputSelect
              fieldName="portType"
              fieldValue={update.portType || 'NULL'}
              onChange={compose(
                onChangeHandler('portUpdate.portType'),
                v => v === 'NULL' ? null : v,
              )}
              isEditable={isEditable}
            >
              <MenuItem value="NULL"><em>None Given</em></MenuItem>
              <MenuItem value="load">Loading</MenuItem>
              <MenuItem value="discharge">Discharging</MenuItem>
              <MenuItem value="transit">Transit / Others</MenuItem>
            </InputSelect>
          </Flex>
        </FlexWrap>
        <FlexWrap>
          <Flex>
            <Label>Previous port</Label>
            <InputText
              fieldName="previousPort"
              fieldValue={update.previousPort}
              onChange={onChangeHandler('portUpdate.previousPort')}
              isEditable={isEditable}
            />
          </Flex>
        </FlexWrap>
      </WaypointDetailsContainer>

      <GridArea>
        <CellTitle>Timeline</CellTitle>
        <TimelineArrow>
          <TimelineBullet>
            {renderTimelineField('Arrival')}
          </TimelineBullet>
          <TimelineBullet>
            {renderTimelineField('Berthing')}
          </TimelineBullet>
          <TimelineBullet>
            {renderTimelineField('Completion')}
          </TimelineBullet>
          <TimelineBullet>
            {renderTimelineField('Departure')}
          </TimelineBullet>
        </TimelineArrow>
      </GridArea>

      <ListContainer>
        <CellTitle>Portlog</CellTitle>
        <ListHeader>
          <Flex flex={1}><Label>Date and time</Label></Flex>
          <Flex flex={3}><Label>Log</Label></Flex>
        </ListHeader>
        <Overflow>
          {update.portlog.map((portlog, i) => (
            <FlexWrap key={`${portlog.time}-${portlog.message}`}>
              <Flex flex={1}>
                <DateTimePicker
                  fieldName="portlog.time"
                  type="datetime-local"
                  fieldValue={portlog.time}
                  onChange={onChangeHandler(`portUpdate.portlog.${i}.time`)}
                  readOnly={!isEditable}
                  disabled={!isEditable}
                />
              </Flex>
              <Flex flex={3}>
                <InputText
                  fieldName="portlog.message"
                  fieldValue={portlog.message}
                  onChange={onChangeHandler(`portUpdate.portlog.${i}.message`)}
                  isEditable={isEditable}
                />
              </Flex>
            </FlexWrap>
          ))}
        </Overflow>
      </ListContainer>

      <GridArea>
        <CellTitle>Bunkers</CellTitle>
        <FlexWrap>
          <Flex flex={1}>
            <Label>On arrival</Label>
            <Pre>{defaultToDash(update.bunkersOnArrival)}</Pre>
          </Flex>
          <Flex flex={1}>
            <Label>On sailing</Label>
            <Pre>{defaultToDash(update.bunkersOnSailing)}</Pre>
          </Flex>
          <Flex flex={2}>
            <Label>Taken</Label>
            <Pre>{defaultToDash(update.bunkersTaken)}</Pre>
          </Flex>
        </FlexWrap>
      </GridArea>
      <GridArea>
        <CellTitle>Draft</CellTitle>
        <FlexWrap>
          <Flex flex={1}>
            <Label>On arrival</Label>
            <Pre>{defaultToDash(update.draftOnArrival)}</Pre>
          </Flex>
          <Flex flex={3}>
            <Label>On sailing</Label>
            <Pre>{defaultToDash(update.draftOnSailing)}</Pre>
          </Flex>
        </FlexWrap>
      </GridArea>
      <GridArea half>
        <CellTitle>Tug</CellTitle>
        <Pre>{defaultToDash(update.tug)}</Pre>
      </GridArea>
      <GridArea half>
        <CellTitle>Cargo</CellTitle>
        <Pre>{defaultToDash(update.cargo)}</Pre>
      </GridArea>
      <GridArea half>
        <CellTitle>Remarks</CellTitle>
        <Flex>
          <Label>General remarks</Label>
          <Pre>{defaultToDash(update.generalRemarks)}</Pre>
        </Flex>
        <Flex>
          <Label>Delay remarks</Label>
          <Pre>{defaultToDash(update.delayRemarks)}</Pre>
        </Flex>
      </GridArea>
      <GridArea half>
        <CellTitle>Protest</CellTitle>
        <Pre>{defaultToDash(update.protest)}</Pre>
      </GridArea>
      <GridArea>
        <CellTitle>Agent signature</CellTitle>
        <Pre>{defaultToDash(update.signature)}</Pre>
      </GridArea>
    </>
  );
};

PortUpdateContainer.propTypes = {
  update: UpdateShape,
  isEditable: PropTypes.bool.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  setUpdateTimeline: PropTypes.func.isRequired,
};

export default PortUpdateContainer;
