import React, {useEffect} from 'react';
import styled from 'styled-components';
import {actions} from '../../reducers/documentTitle';
import {useDispatch} from 'react-redux';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  p {
    text-align: center;
  }

  h1 {
    color: #aaa;
    font-size: 60px;
  }
`;

const FourZeroFour = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setDocumentTitle('Not found'), []);
  });
  return (
    <Container>
      <h1>404</h1>
      <p>Oops.... Page not found</p>
    </Container>
  );
};

export default FourZeroFour;
