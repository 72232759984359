import {lensProp, compose, set, view, applyTo} from 'ramda';
import {createReducer} from 'warped-reducers';
import {createSelector} from 'reselect';
import {hasPermission, groupsToRoles} from '../../common/domain/permissions';

import {defaultTo} from '../utils/state';

const baseLens = lensProp('session');

export const groupsState = compose(
  baseLens,
  lensProp('groups'),
  defaultTo([]),
);

export const tokenState = compose(
  baseLens,
  lensProp('token'),
);

export const userState = compose(
  baseLens,
  lensProp('user'),
);

export const {reducer, types, actions} = createReducer('session')({
  setSession: ({token, user, groups}) => compose(
    set(tokenState, token),
    set(userState, user),
    set(groupsState, groups),
  ),
});

export const viewRoles = createSelector(
  [view(groupsState)],
  groupsToRoles,
);

export const hasWritePermission = createSelector(
  [viewRoles],
  compose(applyTo('operations/write'), hasPermission),
);

export const hasReadPermission = createSelector(
  [viewRoles],
  compose(applyTo('operations/read'), hasPermission),
);
