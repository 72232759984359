import {PortUpdateType} from './constants';

export const getPortUpdateLabel = portUpdateType => {
  switch(portUpdateType) {
    case PortUpdateType.ARRIVAL:
      return 'Arrival Report';
    case PortUpdateType.BERTHING:
      return 'Berthing Report';
    case PortUpdateType.DAILY_UPDATE:
      return 'Daily Update';
    case PortUpdateType.SAILING:
      return 'Sailing Report';
    default: return '-';
  }
};
