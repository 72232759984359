import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {DateText} from '../DateText';
import {Instant, ZoneId} from '@js-joda/core';
import {$text, $grayLight} from '../../styles/variables';

import unknown from '../../assets/img/unknown.jpg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${$text};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  padding: 2px 0;
`;

const Strong = styled(Text)`
  font-weight: bold;
`;

const FadeText = styled(Text)`
  text-transform: uppercase;
  padding: 0 0 4px;
  font-size: 12px;
  font-weight: 500;
  color: ${$grayLight};
`;

const Img = styled.img`
  object-fit: cover;
  border-radius: 50%;
  height: 90px;
  width: 90px;
  margin-right: 24px;
`;

import {roles} from '../../../common/domain/crew';

const Crew = ({crew}) => (
  <Container>
    {
      roles.map(role =>
        crew[role] ? (
          <div key={crew[role].name + role}>
            <Row>
              <Img
                src={crew[role].image ? `data:image/jpeg;base64, ${crew[role].image}` : unknown}
                style={crew[role].image ? {} : {opacity: 0.1}}
              />
              <Column style={{flex: 2}}>
                <Text>{role}:</Text>
                <Strong>{crew[role].name}</Strong>
              </Column>
              <Column style={{flex: 3}}>
                <FadeText>Onboarding:</FadeText>
                <DateText
                  date={Instant.ofEpochMilli(crew[role].onboardAt).atZone(ZoneId.UTC)}
                  hideTime
                  style={{padding: '2px 0'}}
                />
              </Column>
            </Row>
          </div>
        ) : (
          <div key={role}>
            <Row>
              <Img src={unknown} style={{opacity: 0.1}} />
              <Column>
                <Text>{role}:</Text>
                <Strong>No data available</Strong>
              </Column>
            </Row>
          </div>
        ),
      )
    }
  </Container>
);

Crew.propTypes = {
  crew: PropTypes.object.isRequired,
};

export default Crew;
