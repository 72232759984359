const isPromise = value => {
  if (value !== null && typeof value === 'object') {
    return value && typeof value.then === 'function';
  }

  return false;
};

export const rejectionHandler = () => next => action => {
  // If not a promise, continue on
  if (!isPromise(action.payload)) {
    return next(action);
  }

  return next(action).catch(error => {
    // eslint-disable-next-line no-console
    console.error(error);
    if (action.type) {
      // eslint-disable-next-line no-console
      console.warn(`${action.type} rejected with error:`, error);
    }
    return {error};
  });
};
