import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {orderBy, get, filter, groupBy} from 'lodash';
import styled from 'styled-components';
import classnames from 'classnames';

import {Checkbox, FontIcon, Loader} from '..';
import {$grayLight, $alertYellow} from '../../styles/variables';

const TableContainer = styled.div`
  color: rgba(0,0,0,0.87);
  background-color: white;
  padding: 16px;
  box-sizing: border-box;
  font-size: 13px;
  width: 100%;
  align-self: flex-start;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const TableColumn = styled.div`
  padding: 8px 16px;
  box-sizing: border-box;
`;

const TableCellMainContent = styled.div`
  /* height: 100%; */
  min-height: 40px;
  display: flex;
  align-items: center;
  &.sort-active {
    color: rgba(0,0,0,0.87);
  }
  &.align-left {
    text-align: left;
    justify-content: flex-start;
  }
  &.align-center {
    text-align: center;
    justify-content: center;
  }
  &.align-spread {
    text-align: center;
    justify-content: space-between;
  }
  &.align-right {
    text-align: right;
    justify-content: flex-end;
  }
`;

const TableRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 40px;
  transition: background 0.1s ease;
  will-change: background;
  border-bottom: 1px solid #eee;
  position: relative;
  ${props => props.highlight && `
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 100%;
      background-color: ${$alertYellow}
    }
  `}

  &:not(.header):hover {
    background: #FFFFF1;
  }
  &:last-child {
    border-bottom: none;
  }

  .icon-font {
    display: none;
    font-size: 16px;
    cursor: pointer;
    opacity: 0.7;
    margin-right: 8px;
    &:hover {
      opacity: 1;
    }
    //variants
    &.icon-check {
      color: $green;
    }
    &.icon-warning {
      color: $av-orange;
    }
    &.icon-close2 {
      color: $red;
    }
  }
  &:hover .icon-font {
    display: inline-block;
  }
`;

const TableHeader = styled(TableRow)`
  border-bottom: 1px solid ${$grayLight};
  box-sizing: border-box;
  color: rgba(0,0,0,0.54);
  font-size: 12px;
  user-select: none;
  .sort-icon {
    font-size: 12px;
    margin-left: 4px;
  }
  .sortable {
    cursor: pointer;
  }

  .icon-font {
    display: inline-block !important;
  }
`;

class Table extends Component {

  static propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    query: PropTypes.string,
    onRowClick: PropTypes.func,
    selectable: PropTypes.shape({
      isDisabled: PropTypes.func.isRequired,
      onSelect: PropTypes.func.isRequired,
      onDeselect: PropTypes.func.isRequired,
      prop: PropTypes.string.isRequired,
    }),
    filters: PropTypes.objectOf(PropTypes.element),
    isBodyDataPending: PropTypes.bool,
    whenEmpty: PropTypes.element,
    /**
     * server side sorting overrides client side sorting
     */
    serverSideSorting: PropTypes.shape({
      sortBy: PropTypes.string.isRequired,
      isSortAscending: PropTypes.bool.isRequired,
      onSort: PropTypes.func.isRequired,
    }),
  };

  // To ensure sorting and filtering works as expected on cells containing things other than strings
  // or numbers, each row object can have a member [Table.rawValues] which should be an object with
  // string or number representations of those cells.
  // Example:
  // const myRow = {
  //   name: user.name,
  //   email: <input value={user.email}/>,
  //   [Table.rawValues]: {
  //     email: user.email,
  //   },
  // };
  static rawValues = Symbol('Table.rawValues');

  // If a given row should always be placed at the top or bottom of the table regardless of how it
  // is sorted, the row can have a property [Table.sortOverride] which should have a value of either
  // Table.sortStart or Table.sortEnd, respectively.
  // Example:
  // const myRow = {
  //   name: user.name,
  //   email: user.email,
  //   [Table.sortOverride]: Table.sortStart,
  // };
  static sortOverride = Symbol('Table.sortOverride');
  static sortStart = Symbol('Table.sortStart');
  static sortEnd = Symbol('Table.sortEnd');

  constructor(props) {
    super(props);

    const sorting = props.columns.find(c => c.sortable);
    this.state = {
      sorting: sorting ? sorting.prop : null,
      direction: true,
    };
  }

  getRawValue = prop => row => {
    const raw = get(row, [Table.rawValues, prop]);
    if (typeof raw !== 'undefined') {
      return raw;
    }
    return get(row, prop, '');
  };

  isServerSideSorting = () => this.props.serverSideSorting !== undefined

  sort = prop => () => {
    if(this.isServerSideSorting()) {
      this.props.serverSideSorting.onSort(prop);
    } else {
      const revert = prop === this.state.sorting;
      this.setState({sorting: prop, direction: revert ? !this.state.direction : true});
    }
  }

  isColumnSorted = column => this.isServerSideSorting()
    ? this.props.serverSideSorting.sortBy === column.prop
    : this.state.sorting === column.prop;

  isSortAscending = () => this.isServerSideSorting()
    ? this.props.serverSideSorting.isSortAscending
    : this.state.direction;

  orderRows = data => {
    if(this.isServerSideSorting()) {
      return data;
    }
    const groups = groupBy(data, row => {
      const override = row[Table.sortOverride];
      if (override === Table.sortStart || override === Table.sortEnd) {
        return override;
      }
      return 'rest';
    });

    return [
      ...(groups[Table.sortStart] || []),
      ...orderBy(
        groups.rest || [],
        this.getRawValue(this.state.sorting),
        this.state.direction ? 'asc' : 'desc',
      ),
      ...(groups[Table.sortEnd] || []),
    ];
  };

  filterRows = data => {
    if(!this.props.query) {
      return data;
    }
    const filterKeys = this.props.columns.filter(c => c.sortable).map(c => c.prop);
    return filter(data, row =>
      filterKeys.some(prop => {
        const rawValue = this.getRawValue(prop)(row);
        return rawValue.toString().toLowerCase().includes(this.props.query.toLowerCase());
      }),
    );
  };

  renderColumns(row, isHeader) {
    const totalWidth = this.props.columns.reduce((acc, b) => acc + b.width, 0);
    const targetTotalWidth = this.props.selectable ? 95 : 100;
    const widthRatio = totalWidth / targetTotalWidth;
    const getWidth = width => Math.round(width * widthRatio);

    const selectableColumn = this.props.selectable && (
      <TableColumn style={{width: '5%', cursor: 'default'}} onClick={e => e.stopPropagation()}>
        <TableCellMainContent>
          {isHeader || (
            <Checkbox
              disabled={this.props.selectable.isDisabled(row)}
              checked={row[this.props.selectable.prop]}
              onClick={(_, e) => {
                if(!this.props.selectable.isDisabled(row)) {
                  const fn = row[this.props.selectable.prop] ? 'onDeselect' : 'onSelect';
                  this.props.selectable[fn](row, e);
                }
              }}
            />
          )}
        </TableCellMainContent>
      </TableColumn>
    );

    // eslint-disable-next-line complexity
    const userColumns = this.props.columns.map(c => (
      <TableColumn key={c.prop} style={{width: `${getWidth(c.width)}%`}}>
        <TableCellMainContent
          onClick={isHeader && c.sortable ? this.sort(c.prop) : undefined}
          className={classnames(
            c.align ? `align-${c.align}` : 'left',
            c.sortable && 'sortable',
            this.isColumnSorted(c) && 'sort-active',
          )}
        >
          {isHeader ? c.label : row[c.prop]}
          {isHeader && this.isColumnSorted(c) && (
            <FontIcon
              className="sort-icon"
              value={`angle-${this.isSortAscending() ? 'up' : 'down'}`}
            />
          )}
        </TableCellMainContent>
        {
          isHeader && c.filter && this.props.filters[c.prop]
        }
      </TableColumn>
    ));

    return (
      <>
        {selectableColumn}
        {userColumns}
      </>
    );
  }

  renderRows(data) {
    return data.map((d, i) => (
      <TableRow
        key={`row-${i}`}
        highlight={d.highlight}
        style={{cursor: (this.props.onRowClick ? 'pointer' : 'default')}}
        onClick={() => this.props.onRowClick ? this.props.onRowClick(d) : null}
      >
        {this.renderColumns(d)}
      </TableRow>
    ));
  }

  renderEmpty() {
    return <div>{this.props.whenEmpty || 'No items found'}</div>;
  }

  render() {
    const {isBodyDataPending, data} = this.props;
    const rows = this.orderRows(this.filterRows(data));
    const isListEmpty = !rows.length && !isBodyDataPending;

    return (
      <TableContainer>
        <TableHeader className="header">
          {this.renderColumns({}, true)}
        </TableHeader>
        {this.props.isBodyDataPending
          ? <Loader />
          : (
            <div>
              {isListEmpty ? this.renderEmpty() : this.renderRows(rows)}
            </div>
          )}
      </TableContainer>
    );
  }
}

export default Table;
