import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {descend, filter, pipe, prop, propEq, sort, take, view} from 'ramda';
import {createSelector} from 'reselect';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import {withRouter} from 'react-router';
import {ago} from 'time-ago';

import PageContainer from '../../components/PageContainer';
import VoyageTimeline from '../../components/VoyageTimeline';
import EntriesList from '../../components/EntriesList';
import Header from '../../components/Header';
import RefreshLoader from '../../components/RefreshLoader';
import {
  teamServiceUnavailable,
  teamLoaderState,
  teamState,
  vesselLoaderState,
  vesselState,
  actions,
} from '../../reducers/vessel';
import {
  actions as voyagesActions,
  loaderState as voyagesLoaderState,
  voyagesState,
  vesselLens,
} from '../../reducers/voyages';
import Vessel from '../../components/positionsList/Vessel';
import Map from '../../components/Map';
import Loader from '../../components/Loader';
import Crew from '../../components/Crew';
import LabelContainer from '../../components/positionsList/LabelContainer';
import {$gray3, $gray4, $nav, $avOrange, $text} from '../../styles/variables';
import {actions as documentActions} from '../../reducers/documentTitle';
import {ElevatedContainerStyle} from '../../styles/global';
import {Divider} from '@material-ui/core';

import {VoyageStatus} from '../../../common/domain/voyage';

export const formatFloat = n => n.toLocaleString('en-GB', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const View = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled(View)`
  background: #fff;
`;

const PaddedContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  ${ElevatedContainerStyle};
`;

const EmptyItinerary = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 16px 0;
  color: ${$gray3};
`;

const Title = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const H2 = styled.h2`
  margin: 0;
  font-weight: bold;
  color: ${$nav};
`;

const Updated = styled.div`
  padding: 24px 0 8px;
  color: ${$nav};
  font-size: 12px;
  font-weight: 500;
  margin-top: auto;
  text-transform: uppercase;
`;

const OrangeClickable = styled.a`
  font-size: 12px;
  color: ${$avOrange};
  font-weight: 500;
  margin-top: auto;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled(Row)`
  flex-direction: column;
  flex: 1;
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 16px 16px;
  ${ElevatedContainerStyle};
`;

const MapGrid = styled.div`
  display: grid;
  grid-gap: 12px;
  flex: 1;
`;

const FallbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${$gray4};
  color: rgba(0,0,0, 0.5);
  font-size: 14px;
  line-height: 1.5;
  width: 100%;
  padding: 16px;
  min-height: 160px;
  text-align: center;
`;

const DetailContainer = styled.div`
  margin-top: 4px;
  color: ${$text};
  display: flex;
  align-items: center;
  &:first-child {
    margin-top: 0;
  }
`;

const DetailLabel = styled.div`
  min-width: 120px;
  margin-right: 8px;
  flex: 0 0 50%;
`;

const DetailValue = styled.div`
  font-weight: bold;
  flex: 0 0 50%;
`;

const identity = x => x;

const Detail = ({label, value, defaultValue, format = identity}) => (
  <DetailContainer>
    <DetailLabel>{label}</DetailLabel>
    <DetailValue>{value ? format(value) : defaultValue}</DetailValue>
  </DetailContainer>
);

Detail.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  format: PropTypes.func,
};

const WithLoader = ({children, isLoading}) => isLoading ? <Loader /> : children;
WithLoader.propTypes = {
  children: PropTypes.element,
  isLoading: PropTypes.bool.isRequired,
};

const TeamFallbackComponent = ({isLoading, retry}) =>
  <FallbackContainer>
    <WithLoader isLoading={isLoading}>
      <>
        <p>Could not reach Smartsheet.<br />Please try again later.</p>
        <Button
          variant="contained"
          onClick={() => retry()}
        >
          Retry
        </Button>
      </>
    </WithLoader>
  </FallbackContainer>;

TeamFallbackComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  retry: PropTypes.func.isRequired,
};

const VoyagesTimeline = ({
  voyages,
  voyagesLoading,
  loadVoyages,
}) => {
  const [showPosList, setShowPosList] = useState(false);

  return (
    <Content>
      <PaddedContainer>
        <Title>
          <H2 style={{marginRight: 8}}>Voyages Timeline</H2>
          <RefreshLoader isLoading={voyagesLoading} onClick={loadVoyages} />
        </Title>
        {!voyages.length ? (
          <EmptyItinerary>No known portcalls in itinerary</EmptyItinerary>
        ) : (
          <div>
            <VoyageTimeline voyages={voyages} />
            {showPosList ?
              <div>
                <OrangeClickable
                  onClick={() => setShowPosList(!showPosList)}
                >
                  SHOW LESS INFO
                </OrangeClickable>
                <LabelContainer
                  style={{
                    borderBottom: '1px solid #C2C2C2',
                    paddingTop: '32px',
                  }}
                />
                <Vessel vessel={{voyages}} />
              </div>
              :
              <div>
                <OrangeClickable onClick={() => setShowPosList(!showPosList)}>
                  SHOW MORE INFO
                </OrangeClickable>
              </div>}
          </div>
        )}
      </PaddedContainer>
    </Content>
  );
};
VoyagesTimeline.propTypes = {
  voyages: PropTypes.array.isRequired,
  voyagesLoading: PropTypes.bool.isRequired,
  loadVoyages: PropTypes.func.isRequired,
};

const VesselView = ({
  match,
  allVoyages,
  loadVesselVoyages,
  team,
  loadTeam,
  voyagesLoading,
  teamServiceUnavailable,
  teamLoading,
  vessel,
  loadVessel,
  vesselLoading,
  setDocumentTitle,
}) => {
  const loadVoyages = () => loadVesselVoyages(match.params.vesselName);
  useEffect(() => {
    loadVoyages();
    loadTeam(match.params.vesselName);
    loadVessel(match.params.vesselName);
    setDocumentTitle(match.params.vesselName);
  }, [match.params.vesselName]);

  const crewMembers = vessel
    ? Object.values(vessel.currentCrew).map(({updatedAt}) => updatedAt)
    : [];
  const vesselDetails = vessel ? vessel.vessel : {};

  const voyages = allVoyages.filter(voyage => voyage.status !== VoyageStatus('Closed')
  && voyage.status !== VoyageStatus('Completed'));

  const lastThreeVoyages = pipe(
    filter(propEq('status', VoyageStatus('Completed'))),
    sort(descend(prop('voyage'))),
    take(3)
  )(allVoyages);

  return (
    <View>
      <Header title={match.params.vesselName} />
      <PageContainer>
        <VoyagesTimeline
          voyages={voyages}
          voyagesLoading={voyagesLoading}
          loadVoyages={loadVoyages}
        />
        <Row style={{marginTop: 12}}>
          <Column style={{marginRight: 12}}>
            <Cell style={{flex: 4}}>
              <Title style={{justifyContent: 'space-between'}}>
                <H2>Crew</H2>
              </Title>
              <WithLoader isLoading={vesselLoading}>
                {vessel && (
                  <>
                    <Crew crew={vessel.currentCrew} />
                    <Updated>
                      {crewMembers.length
                        ? ''
                        : 'No updates received'}
                    </Updated>
                  </>
                )}
              </WithLoader>
            </Cell>
          </Column>
          <MapGrid>
            <Cell style={{flex: 1}}>
              <Title><H2>Vessel Responsibles</H2></Title>
              {teamServiceUnavailable ? (
                <TeamFallbackComponent
                  isLoading={teamLoading}
                  retry={() => loadTeam(match.params.vesselName)}
                />
              ) : (
                team &&
                  <EntriesList
                    fallbackText={`Responsibles for ${match.params.vesselName} not assigned.`}
                    entries={team.members}
                  />
              )}
              {team && <Updated>Last updated in smartsheet, {ago(team.updatedAt)}</Updated>}
            </Cell>
            <Cell>
              <Title><H2>Vessel details</H2></Title>
              <Detail label="Name" value={vesselDetails.name} defaultValue="-" />
              <Detail label="IMO" value={vesselDetails.imo} defaultValue="-" />
              <Detail
                label="Deadweight"
                format={formatFloat}
                value={vesselDetails.deadweight}
                defaultValue="-"
              />
            </Cell>
            {
              lastThreeVoyages.length !== 0 &&
            <Cell>
              <Title><H2>Last three voyages</H2></Title>

              {lastThreeVoyages.map((voyage, index) => (
                <div key={index}>
                  <Detail label="Voyage" value={voyage.voyage} />
                  <Detail label="Cargo" value={voyage.cargo} />
                  <Detail label="Charterer" value={voyage.company} />
                  {lastThreeVoyages.length - 1 !== index
                    && <Divider style={{margin: '16px 0'}} />}
                </div>
              ))}
            </Cell>
            }
            <Cell>
              <Title><H2>AIS</H2></Title>
              {vessel && <Map imo={vessel.vessel.imo} vesselIsLoading={vesselLoading} />}
            </Cell>
          </MapGrid>
        </Row>
      </PageContainer>
    </View>
  );
};

VesselView.propTypes = {
  match: PropTypes.object.isRequired,
  allVoyages: PropTypes.array.isRequired,
  voyagesLoading: PropTypes.bool.isRequired,
  setDocumentTitle: PropTypes.func.isRequired,
  loadVesselVoyages: PropTypes.func.isRequired,
  team: PropTypes.shape({
    updatedAt: PropTypes.number.isRequired,
    members: PropTypes.object,
  }),
  loadTeam: PropTypes.func.isRequired,
  teamServiceUnavailable: PropTypes.bool.isRequired,
  teamLoading: PropTypes.bool.isRequired,
  loadVessel: PropTypes.func.isRequired,
  vesselLoading: PropTypes.bool.isRequired,
  vessel: PropTypes.shape({
    vessel: PropTypes.object.isRequired,
    currentCrew: PropTypes.object.isRequired,
    nextCrew: PropTypes.object.isRequired,
  }),
};

const teamSelector = createSelector([
  view(teamState),
  (_, {match}) => match.params.vesselName,
], (team, vesselName) => team
  ? ({
    updatedAt: team.updatedAt,
    members: team.fleet[vesselName],
  })
  : null,
);

const allVoyagesSelector = createSelector([
  view(voyagesState),
  (_, {match}) => match.params.vesselName,
], (voyages, vesselName) => (
  view(vesselLens(vesselName), voyages)
));

const vesselSelector = view(vesselState);

export default withRouter(connect(
  (state, props) => ({
    allVoyages: allVoyagesSelector(state, props),
    voyagesLoading: Boolean(view(voyagesLoaderState, state)),
    team: teamSelector(state, props),
    teamServiceUnavailable: view(teamServiceUnavailable, state),
    teamLoading: view(teamLoaderState, state),
    vessel: vesselSelector(state, props),
    vesselLoading: view(vesselLoaderState, state),
  }),
  {...voyagesActions, ...documentActions, ...actions},
)(VesselView));
