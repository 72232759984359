import PropTypes from 'prop-types';

// TODO: Use the shared domain to define the propTypes
export const UpdateStatusTypes = {
  processed: 'processed',
  rejected: 'rejected',
  received: 'received',
  warning: 'warning',
  accepted: 'accepted',
};

export const TimeFieldType = {
  ETA: {
    label: 'ETA',
    description: 'Estimated Time of Arrival',
  },
  ETB: {
    label: 'ETB',
    description: 'Estimated Time of Berthing',
  },
  ETC: {
    label: 'ETC',
    description: 'Estimated Time of Charging',
  },
  ETD: {
    label: 'ETD',
    description: 'Estimated Time of Discharging',
  },
  ATA: {
    label: 'ATA',
    description: 'Estimated Time of Arrival',
  },
  ATB: {
    label: 'ATB',
    description: 'Estimated Time of Berthing',
  },
  ATC: {
    label: 'ATC',
    description: 'Estimated Time of Charging',
  },
  ATD: {
    label: 'ATD',
    description: 'Estimated Time of Discharging',
  },
};

export const RecipientShape = PropTypes.shape({
  value: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['api', 'email']).isRequired,
});

export const TimeUpdateShape = PropTypes.shape({
  port: PropTypes.string,
  field: PropTypes.oneOf(Object.keys(TimeFieldType)),
  time: PropTypes.string,
});

export const PortlogShape = PropTypes.shape({
  message: PropTypes.string,
  time: PropTypes.string,
});

export const FileUpdateShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
});

export const LaycanShape = PropTypes.shape({
  laycan: PropTypes.string,
});

export const UpdateShape = PropTypes.shape({
  berthName: PropTypes.string,
  callNumber: PropTypes.string,
  port: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  portlog: PropTypes.arrayOf(PortlogShape).isRequired,
  previousPort: PropTypes.string,
  protest: PropTypes.string,
  type: PropTypes.string,
  xtx: PropTypes.shape({
    Arrival: PropTypes.shape({
      type: PropTypes.oneOf(['Estimation', 'Actual']),
      time: PropTypes.string.isRequired,
    }),
    Berthing: PropTypes.shape({
      type: PropTypes.oneOf(['Estimation', 'Actual']),
      time: PropTypes.string.isRequired,
    }),
    Completion: PropTypes.shape({
      type: PropTypes.oneOf(['Estimation', 'Actual']),
      time: PropTypes.string.isRequired,
    }),
    Departure: PropTypes.shape({
      type: PropTypes.oneOf(['Estimation', 'Actual']),
      time: PropTypes.string.isRequired,
    }),
  }).isRequired,
  signature: PropTypes.string,
});

export const UpdateBundleShape = PropTypes.shape({
  force: PropTypes.bool.isRequired,
  createdAt: PropTypes.number.isRequired,
  fileUpdate: FileUpdateShape,
  handled: PropTypes.bool.isRequired,
  read: PropTypes.bool.isRequired,
  messageId: PropTypes.string.isRequired,
  vessel: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  voyage: PropTypes.number.isRequired,
  reason: PropTypes.string,
  status: PropTypes.oneOf(Object.values(UpdateStatusTypes)),
  recipients: PropTypes.arrayOf(RecipientShape),
});

export const FilterType = Object.freeze({
  SINGLE: 'single',
  MULTI: 'multi',
  MULTI_ALL: 'multi-all',
  NONE: 'none',
  SEARCH: 'search',
});

export const SystemStatusTypes = {
  RUNNING: 'running',
  DOWN: 'down',
  UNKNOWN: 'unknown',
};

export const StatusShape = PropTypes.shape({
  rejected: PropTypes.number.isRequired,
  warnings: PropTypes.number.isRequired,
  accepted: PropTypes.number.isRequired,
  lastSent: PropTypes.string,
});
