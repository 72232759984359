import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import RemoveIcon from '@material-ui/icons/Remove';
import WarningIcon from '@material-ui/icons/PriorityHigh';

import {$green, $gray, $red, $orange} from '../../styles/variables';
import {FilterType, UpdateStatusTypes} from '../../shapes';
import {DateRange} from '../../shapes/date-range';

export const TabFilterType = Object.freeze({
  UNHANDLED: 'unhandled',
  HANDLED: 'handled',
  FILE: 'file',
});

export const UpdatesListField = Object.freeze({
  SELECTED: 'selected',
  HIGHLIGHT: 'highlight',
  CREATED_AT: 'createdAt',
  VESSEL: 'vessel',
  STATUS: 'status',
  PORT: 'port',
  VOYAGE: 'voyage',
  TIME_OF_ARRIVAL: 'timeOfArrival',
  FILE_NAME: 'fileName',
  PORT_UPDATE_TYPE: 'portUpdateType'
});

export const PortUpdateType = Object.freeze({
  ARRIVAL: 'ARRIVAL',
  BERTHING: 'BERTHING',
  SAILING: 'SAILING',
  DAILY_UPDATE: 'DAILY UPDATE'
});

export const updatesColumnDefintions = [{
  prop: UpdatesListField.VESSEL,
  sortable: true,
  filter: {
    type: FilterType.MULTI_ALL,
    defaultValue: [],
    selectProps: {
      labelSelector: option => option,
      valueSelector: option => option,
      // filterOptions are dynamic
    },
  },
  label: 'Vessel name',
  type: 'string',
  width: 16,
  belongsTo: [TabFilterType.HANDLED, TabFilterType.UNHANDLED, TabFilterType.FILE],
}, {
  prop: UpdatesListField.VOYAGE,
  sortable: true,
  filter: {
    type: FilterType.SEARCH,
    inputType: 'number',
    defaultValue: '',
  },
  label: 'Voyage no.',
  type: 'number',
  width: 14,
  belongsTo: [TabFilterType.HANDLED, TabFilterType.UNHANDLED, TabFilterType.FILE],
}, {
  prop: UpdatesListField.PORT,
  sortable: true,
  filter: {
    type: FilterType.SEARCH,
    defaultValue: '',
  },
  label: 'Port of service',
  type: 'string',
  width: 20,
  belongsTo: [TabFilterType.HANDLED, TabFilterType.UNHANDLED],
}, {
  prop: UpdatesListField.PORT_UPDATE_TYPE,
  sortable: true,
  filter: {
    type: FilterType.NONE,
  },
  label: 'Update type',
  type: 'string',
  width: 15,
  belongsTo: [TabFilterType.HANDLED, TabFilterType.UNHANDLED],
}, {
  prop: UpdatesListField.TIME_OF_ARRIVAL,
  //TODO: ETA sorting and filtering not yet supported in backend
  sortable: false,
  filter: {
    type: FilterType.NONE,
  },
  label: 'Time of arrival',
  type: 'datetime',
  width: 16,
  belongsTo: [TabFilterType.HANDLED, TabFilterType.UNHANDLED],
}, {
  prop: UpdatesListField.CREATED_AT,
  sortable: true,
  filter: {
    type: FilterType.SINGLE,
    inactiveValue: DateRange.ANY_TIME.label,
    selectProps: {
      options: [
        DateRange.LAST_24_HOURS,
        DateRange.LAST_7_DAYS,
        DateRange.LAST_14_DAYS,
        DateRange.ANY_TIME,
      ],
      labelSelector: option => option.label,
      valueSelector: option => option.label,
      // Value is computed from the label on select
    },
    defaultValue: DateRange.LAST_7_DAYS.label,
  },
  label: 'Date and time',
  type: 'datetime',
  width: 16,
  belongsTo: [TabFilterType.HANDLED, TabFilterType.UNHANDLED, TabFilterType.FILE],
}, {
  prop: UpdatesListField.FILE_NAME,
  sortable: false,
  filter: {
    type: FilterType.SEARCH,
    inputType: 'string',
    defaultValue: '',
  },
  label: 'File name',
  type: 'number',
  width: 60,
  belongsTo: [TabFilterType.FILE],
}, {
  prop: UpdatesListField.STATUS,
  sortable: true,
  filter: {
    type: FilterType.MULTI,
    defaultValue: [],
    selectProps: {
      options: [
        UpdateStatusTypes.accepted, UpdateStatusTypes.warning, UpdateStatusTypes.rejected,
      ],
      labelSelector: option => option,
      valueSelector: option => option,
    },
  },
  label: 'Status',
  type: () => 'STATUS',
  width: 15,
  belongsTo: [TabFilterType.HANDLED, TabFilterType.UNHANDLED, TabFilterType.FILE],
}];

export const StatusIconMap = {
  processed: {
    label: 'Processed',
    color: $green,
    icon: CheckIcon,
  },
  rejected: {
    label: 'Rejected',
    color: $red,
    icon: ClearIcon,
  },
  received: {
    label: 'Received',
    color: $gray,
    icon: RemoveIcon,
  },
  warning: {
    label: 'Warning',
    color: $orange,
    icon: WarningIcon,
  },
  accepted: {
    label: 'Accepted',
    color: $green,
    icon: CheckIcon,
  },
};
