import React from 'react';
import styled from 'styled-components';
import {without} from 'ramda';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {Checkbox} from '@material-ui/core';
import {FilterType} from '../shapes';
import {$blue2} from '../styles/variables';

const ALL_OPTION_LABEL = 'all';

const SelectAllOption = styled(MenuItem)`
  background: rgba(0, 0, 0, 0.04);
  border-bottom: 1px solid ${$blue2};
`;

const MultiSelect = ({type, value, selectProps, onChange}) => {
  const optionsSelected = without(ALL_OPTION_LABEL, value);
  const noneSelected = optionsSelected.length === 0;
  const someSelected = (
    optionsSelected.length > 0 &&
    optionsSelected.length < selectProps.options.length
  );
  const allSelected = optionsSelected.length === selectProps.options.length;

  const handleMultiSelectChange = (value, child, options, onChange) => {
    const selectAllToggled = child.props.value === ALL_OPTION_LABEL;
    if (selectAllToggled) {
      if (allSelected) {
        onChange([]);
      } else {
        onChange(options);
      }
    } else {
      onChange(value);
    }
  };

  const getSelectAllLabel = () => noneSelected
    ? 'Select all'
    : someSelected
      ? `${optionsSelected.length} selected`
      : `${selectProps.options.length} selected`;

  return (
    <Select
      multiple
      value={value}
      /*
      This is introduced to stop option list from auto focusing
      which results in it scrolling to the bottom when all/none are selected.
      The drawback of this, is that selected options might not be
      immediately visible if they appear on the bottom of the list
      */
      MenuProps={{autoFocus: false}}
      onChange={(e, child) =>
        handleMultiSelectChange(e.target.value, child, selectProps.options, onChange)
      }
      input={<OutlinedInput />}
      fullWidth
      placeholder="Select"
      displayEmpty
      renderValue={value => value.length === 0
        ? 'Select'
        : `${optionsSelected.length} / ${selectProps.options.length} selected`
      }
    >
      {type === FilterType.MULTI_ALL && (
        <SelectAllOption
          key={ALL_OPTION_LABEL}
          value={ALL_OPTION_LABEL}
        >
          <Checkbox
            indeterminate={someSelected}
            checked={allSelected}
          />
          {getSelectAllLabel()}
        </SelectAllOption>
      )}
      {selectProps.options.map(option => (
        <MenuItem
          key={selectProps.labelSelector(option)}
          value={selectProps.valueSelector(option)}
        >
          <Checkbox checked={value.includes(selectProps.valueSelector(option))} />
          {selectProps.labelSelector(option)}
        </MenuItem>
      ))}
    </Select>
  );
};

MultiSelect.propTypes = {
  type: PropTypes.oneOf(Object.values(FilterType)),
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  selectProps: PropTypes.shape({
    options: PropTypes.array.isRequired,
    labelSelector: PropTypes.func.isRequired,
    valueSelector: PropTypes.func.isRequired,
  })
};

export default MultiSelect;
