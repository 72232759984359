import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ThemeProvider, StylesProvider} from '@material-ui/styles';
import {ConnectedRouter} from 'connected-react-router';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import {view} from 'ramda';

import Hmr from './components/HMR';
import theme from './styles/theme';
import GlobalStyles from './styles/global';
import {store} from './store';
import history from './history';
import {documentTitleState} from './reducers/documentTitle';

import 'normalize.css/normalize.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'typeface-roboto-mono';
import '@js-joda/timezone';

const rootTitle = 'Bridge';
const updateDocumentTitle = () => {
  const title = view(documentTitleState)(store.getState());
  document.title = title ? `${rootTitle} - ${title}` : rootTitle;
};

let currentRoute = '';
const maybeScrollToTop = () => {
  const {router} = store.getState();
  if(currentRoute !== router.location.pathname) {
    currentRoute = router.location.pathname;
    window.scrollTo(0, 0);
  }
};

store.subscribe(() => {
  updateDocumentTitle();
  maybeScrollToTop();
});

const App = () => (
  <StylesProvider injectFirst>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <GlobalStyles />
            <Hmr />
          </MuiPickersUtilsProvider>
        </ConnectedRouter>
      </ThemeProvider>
    </Provider>
  </StylesProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));
