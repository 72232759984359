import styled, {keyframes} from 'styled-components';

const fadeInFrames = keyframes`
  0% {opacity: 0}
  100% {opacity: 1}
`;

export default styled.div`
  width: 80%;
  height: 100%;
  max-width: 1280px;
  min-width: 720px;
  margin: 24px auto;
  opacity: 1;
  animation-name: ${fadeInFrames};
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.4s;
`;
