import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {$gray} from '../styles/variables';
import Search from './Search';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const Title = styled.div`
  font-size: 22px;
`;

const SubTitle = styled.small`
  font-size: 14px;
  color: ${$gray};
  font-style: italic;
`;

const PageTitleSearchBar = ({className, title, subtitle, onSearchChange}) => (
  <Container className={className}>
    <Title>{title}
      {subtitle && <SubTitle><br />{subtitle}</SubTitle>}
    </Title>
    {onSearchChange ? <div><Search onChange={onSearchChange} /></div> : null}
  </Container>
);

PageTitleSearchBar.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  onSearchChange: PropTypes.func,
};

export default PageTitleSearchBar;
