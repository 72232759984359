import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/TextField';
import styled from 'styled-components';

import FontIcon from './FontIcon';
import {$gray} from '../styles/variables';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  width: 250px;

  & > div {
    padding: 0;
    width: 100%;
  }
  input {
    padding: 0;
    padding-left: 24px;
    height: 32px;
    color: ${$gray};
    z-index: 1;
  }
  span:last-child {
    top: 0;
    bottom: 0;
    left: 24px;
    line-height: 16px;
    margin: auto;
    height: 16px;
  }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 16px;
    margin: auto;
    font-size: 16px;
    z-index: 9;
  }
`;

class Search extends Component {

  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {query: ''};
  }

  handleChange = () => ({target}) => {
    this.setState({query: target.value});
    this.props.onChange(target.value);
  };

  render() {
    return (
      <Container>
        <FontIcon value="search" className="icon" />
        <Input
          type="text"
          placeholder="Search"
          name="search"
          value={this.state.query} onChange={this.handleChange('name')}
        />
      </Container>
    );
  }
}

export default Search;
