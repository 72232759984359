import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {withStyles} from '@material-ui/core/styles';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Tooltip from '@material-ui/core/Tooltip';

import {$white, $gray, $blue} from '../../../styles/variables';
import SimpleTable from '../../../components/SimpleTable';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const Container = styled.div`
  padding: 25px;
  display: flex;
  background-color: ${$white};
  flex-wrap: wrap;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  padding-bottom: 25px;
  align-items: left;
  display: flex;
  align-items: center;

  small {
    margin-left: 10px;
    font-weight: normal;
    color: ${$gray};
  }
`;

const InfoIcon = styled(InfoRoundedIcon)`
  height: 16px;
  color: ${$blue};
  margin-left: 10px;
`;

const Text = 'No updates have been received from these vessels due to missing voyage orders.';

const NoUpdates = ({className, data, columns}) => (
  <Container className={className}>
    <Title>
      No updates received
      <LightTooltip title={Text} placement="top">
        <InfoIcon />
      </LightTooltip>
    </Title>
    <SimpleTable idKey={columns[0].key} data={data} columns={columns} />
  </Container>
);

NoUpdates.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    render: PropTypes.func,
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    lastUpdateAt: PropTypes.string,
  })).isRequired,
};

export default NoUpdates;
