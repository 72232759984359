import {get, isEqual, isObject, has} from 'lodash';
import axios from 'axios';

const filterByProps = properties => value => item => {
  if (value !== '') {
    const match = properties.find(prop =>
      item[prop].toLowerCase().includes(value.toLowerCase()),
    );
    return Boolean(match);
  }
  return true;
};

const mapToError = (payload, fallback) =>
  get(payload, 'response.data.result', fallback);

const objEq = keys => (objB, objA) => isObject(objA)
  && isObject(objB)
  && keys.every(key => has(objA, key)
    && has(objB, key)
    && isEqual(objA[key], objB[key]),
  );

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
const delayed = (fn, binding = null) => ms => (...args) =>
  delay(ms).then(() => fn.apply(binding, args));

const delayedGet = delayed(axios.get, axios);

export {
  filterByProps,
  mapToError,
  objEq,
  delay,
  delayedGet,
};
