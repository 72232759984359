import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker} from '@material-ui/pickers';
import EditIcon from '@material-ui/icons/Edit';
import {$grayLight, $white} from '../styles/variables';
import dayjs from 'dayjs';

const formats = {
  'date': {
    display: 'DD/MM/YYYY',
    save: 'YYYY-MM-DD',
  },
  'time': {
    display: 'HH:mm',
    save: 'HH:mm',
  },
  'datetime-local': {
    display: 'DD/MM/YYYY HH:mm',
    save: 'YYYY-MM-DDTHH:mm',
  },
};

const Adornment = styled.div`
  position: absolute;
  right: 0;
  top: 3px;
  display: none;

  svg {
    font-size: 22px;
    padding-left: 5px;
    border-radius: 3px;
    cursor: pointer;
    fill: ${$grayLight};
    background-color: ${$white};
    box-sizing: border-box;
  }
`;

const styles = css`
  width: 100%;
  padding-bottom: 0;
  padding-top: 0;

  input {
    font-size: 14px;
  }

  &:hover ${Adornment} {
    display: flex;
  }

  .MuiInput-underline:before {
    display: none;
  }

  .MuiButton-text{
    background: transparent;
  }

  input:disabled {
    color: ${({readOnly}) => readOnly ? 'black' : 'inherit'};
  }
`;

const StyledDatePicker = styled(KeyboardDatePicker)`${styles}`;
const StyledTimePicker = styled(KeyboardTimePicker)`${styles}`;
const StyledDateTimePicker = styled(KeyboardDateTimePicker)`${styles}`;

// Day.js's .isValid() method returns true for inputs like '56/34/2099 40:98', so this
// custom validation just checks if the display-formatted date is equal to the input string
const validateDate = (pickerType, inputString, date) => (
  date.format(formats[pickerType].display) === inputString
);

const Picker = ({type = 'datetime-local', fieldValue, className, disabled, readOnly, onChange}) => {

  // An intermediate variable between the input we receive and what we pass into the underlying
  // component. This is needed to pass invalid input to the underlying component (so it will show
  // error messages) without calling the 'onChange' prop, which we can't do with invalid dates
  // because the save format is different from the display format :/
  const [inputValue, setInputValue] = React.useState(fieldValue || null);
  const Field = (
    type === 'date' ? StyledDatePicker :
      type === 'time' ? StyledTimePicker :
        StyledDateTimePicker
  );
  const handleChange = newDate => {
    const formatted = newDate && newDate.format(formats[type].save);
    setInputValue(formatted);
    onChange && fieldValue !== formatted && onChange(formatted);
  };
  return (
    <Field
      variant="dialog"
      className={className}
      value={inputValue}
      ampm={false}
      autoOk
      clearable
      readOnly={readOnly}
      placeholder={inputValue ? null : '<unchanged>'}
      initialFocusedDate={inputValue}
      disabled={disabled}
      format={formats[type].display}
      onChange={() => {
        // DateTimePicker crashes if onChange is not passed, but we only want to call 'handleChange'
        // when using the visual picker (i.e. with the 'onAccept' prop), not while typing.
      }}
      onAccept={handleChange}
      onBlur={evt => {
        const inputString = evt.target.value;
        if (!inputString) {
          handleChange(null);
          return;
        }
        const date = dayjs(inputString, formats[type].display);
        if (validateDate(type, inputString, date)) {
          handleChange(date);
        } else {
          setInputValue(inputString);
        }
      }}
      InputProps={{
        endAdornment: <Adornment>{!readOnly && !disabled && <EditIcon />}</Adornment>,
      }}
    />
  );
};

Picker.propTypes = {
  type: PropTypes.oneOf(['date', 'time', 'datetime-local']).isRequired,
  fieldValue: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default Picker;
