import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {ZonedDateTime, ZoneId, DateTimeFormatter} from '@js-joda/core';
import {view} from 'ramda';
import {Locale} from '@js-joda/locale_en-us';
import {localTimezoneLens} from '../reducers/navigation';

const dateFormater = DateTimeFormatter.ofPattern('dd MMM yyyy').withLocale(Locale.ENGLISH);
const timeFormater = DateTimeFormatter.ofPattern('HH:mm');

const Time = styled.div`
  text-transform: uppercase;
  font-family: 'roboto mono';
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  span {
    margin-right: 6px;
  }
`;

export const DateText = ({date, hideTime = false, useLocalTimezone}) => {

  const d = useLocalTimezone
    ? date.withZoneSameInstant(ZoneId.SYSTEM)
    : date;

  return (
    <Time>
      <span>{d.format(dateFormater)}</span>
      {!hideTime && (
        <strong>
          {d.format(timeFormater)}
        </strong>
      )}
    </Time>
  )
  ;
};

DateText.propTypes = {
  useLocalTimezone: PropTypes.bool.isRequired,
  hideTime: PropTypes.bool,
  date: PropTypes.instanceOf(ZonedDateTime),
};

export default connect(
  state => ({
    useLocalTimezone: view(localTimezoneLens, state),
  }),
)(DateText);
