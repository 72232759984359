import React, {Fragment, useRef} from 'react';
import PropTypes from 'prop-types';
import {view} from 'ramda';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {$nav, $navText} from '../styles/variables';
import {StyledMenu, StyledMenuItem} from './Menu';
import {actions, userMenuLens} from '../reducers/navigation';

const UserOuter = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const User = styled.div`
  width: 24px;
  height: 24px;
  font-size: 16px;
  border-radius: 50%;
  display: flex;
  line-height: 1.2;
  align-items: center;
  justify-content: center;
  background: ${$navText};
  color: #222;
  text-transform: uppercase;
`;

const Logout = styled.div`
  font-weight: 600;
  color: ${$nav};
`;

const UserMenu = ({isOpen, username, onLogout, closeUserMenu, openUserMenu}) => {
  const anchorEl = useRef(null);

  return (
    <>
      <UserOuter ref={anchorEl} onClick={() => openUserMenu()}>
        <User>{username[0]}</User>
      </UserOuter>
      <StyledMenu
        id="user-menu"
        anchorEl={anchorEl.current}
        elevation={2}
        keepMounted
        open={isOpen}
        disableScrollLock
        onClose={() => closeUserMenu()}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuItem onClick={onLogout}>
          <Logout>Logout</Logout>
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

UserMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
  openUserMenu: PropTypes.func.isRequired,
  closeUserMenu: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    isOpen: Boolean(view(userMenuLens, state)),
  }),
  actions,
)(UserMenu);
