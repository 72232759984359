import axios from 'axios';
import {put, takeEvery} from 'redux-saga/effects';
import {types} from '../reducers/operators';
import {objEq} from './utils';

export const equal = objEq(['id']);

const getOperatorsList = function* () {
  const response = yield axios.get('/operators');
  yield put({type: types.setOperatorsList, payload: response.data});
};

const updateOperator = function* ({payload}) {
  yield axios.patch('/operators', payload);
  yield put({type: types.setOperator, payload});
};

const createOperator = function* ({payload}) {
  const response = yield axios.post('/operators', payload);
  yield put({type: types.addOperator, payload: response.data});
};

export default function* () {
  yield takeEvery(types.getOperatorsList, getOperatorsList);
  yield takeEvery(types.updateOperator, updateOperator);
  yield takeEvery(types.createOperator, createOperator);
}
