import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const ConfirmDialog = ({
  title,
  description,
  cancelButtonLabel,
  confirmButtonLabel,
  open,
  closeDialogHandler,
  confirmActionHandler,
}) => (
  <Dialog
    open={open}
    onClose={closeDialogHandler}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {description}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeDialogHandler} variant="contained">
        {cancelButtonLabel}
      </Button>
      <Button onClick={confirmActionHandler} autoFocus>
        {confirmButtonLabel}
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  open: PropTypes.bool,
  cancelButtonLabel: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
  closeDialogHandler: PropTypes.func.isRequired,
  confirmActionHandler: PropTypes.func.isRequired,
};

ConfirmDialog.defaultProps = {
  title: 'Confirm this action?',
  description: '',
  open: false,
  cancelButtonLabel: 'Cancel',
  confirmButtonLabel: 'Yes, proceed!',
};

export default ConfirmDialog;
