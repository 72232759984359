import React from 'react';
import PropTypes from 'prop-types';

const PortFunction = ({waypointFunction, waypointFunctionName}) =>
  <div>{waypointFunction
    ? `${waypointFunctionName || 'Unknown'} (${waypointFunction})`
    : 'Unknown'}
  </div>;

PortFunction.propTypes = {
  waypointFunction: PropTypes.string,
  waypointFunctionName: PropTypes.string,
};

export default PortFunction;
