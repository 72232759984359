import {LOCATION_CHANGE} from 'connected-react-router';

export default store => next => action => {
  if (action.type === LOCATION_CHANGE) {
    const newAction = {
      ...action,
      payload: {
        ...action.payload,
        prevPathname: store.getState().router.location.pathname,
      },
    };
    return next(newAction);
  }
  return next(action);
};
