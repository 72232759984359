import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {columnProperties} from './columnProperties';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0 20px;
`;

const Row = styled.div`
  display: flex;
  flex: 0 0 85%;
`;

const ColumnLabel = styled.div`
  font-family: Roboto;
  font-size: 12px;
  color: #ACACAC;
  display: flex;
  justify-content: ${props => props.center ? 'center' : 'flex-start'};
`;

const LabelContainer = ({style}) => (
  <Container style={style}>
    <ColumnLabel style={{flex: '0 0 10%'}}>
      VOYAGE NO.
    </ColumnLabel>
    <Row>
      {columnProperties.map(x =>
        <ColumnLabel
          key={x.label}
          style={{flex: `0 0 ${x.width}%`}}
          center={x.center}
        >
          {x.label}
        </ColumnLabel>,
      )}
    </Row>
    <ColumnLabel center style={{flex: '0 0 5%'}}>
      DETAILS
    </ColumnLabel>
  </Container>
);

LabelContainer.propTypes = {
  style: PropTypes.object,
};

export default LabelContainer;
