import {lensProp, compose, set, always} from 'ramda';
import {createReducer} from 'warped-reducers';

import {defaultTo} from '../utils/state';

const baseLens = lensProp('error');

export const errorLens = compose(
  baseLens,
  lensProp('message'),
  defaultTo(null),
);

export const {reducer, types, actions} = createReducer('error')({
  setError: set(errorLens),
  resetError: always(set(errorLens, null)),
});
