'use strict';

const tcomb = require ('tcomb');
const {model, list, alias, laden, maybe} = require ('../utils/domain');
const common = require ('./common');
const broker = module.exports;

broker.BrokerName = alias ('BrokerName') (laden (tcomb.String));

broker.ClientName = alias ('ClientName') (laden (tcomb.String));

broker.EoriNumber = alias ('EoriNumber') (tcomb.String);

broker.BrokerClient = model ('BrokerClient') ({
  name: broker.ClientName,
  broker: broker.BrokerName,
  eori: maybe (broker.EoriNumber),
  emails: list (common.Email),
});
