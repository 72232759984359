import {lensProp, compose, set, always} from 'ramda';
import {createReducer} from 'warped-reducers';

import {defaultTo} from '../utils/state';
import {SystemStatusTypes} from '../shapes';

export const baseLens = lensProp('overview');

export const statusState = compose(
  baseLens,
  lensProp('status'),
  defaultTo(SystemStatusTypes.RUNNING),
);

export const isPendingState = compose(
  baseLens,
  lensProp('isPending'),
  defaultTo(false),
);

export const dataState = compose(
  baseLens,
  lensProp('data'),
  defaultTo({
    processed: 0,
    rejected: 0,
    warnings: 0,
    accepted: 0,
    lastSent: null,
  }),
);

export const idleVesselsState = compose(
  baseLens,
  lensProp('idleVessels'),
  defaultTo([]),
);

export const lastFetchDateState = compose(
  baseLens,
  lensProp('lastFetchDate'),
);

export const {reducer, types, actions} = createReducer('overview')({
  getCurrentStatusData: always(set(isPendingState, true)),
  getIdleVessels: always(set(isPendingState, true)),
  setCurrentStatusData: payload => compose(
    set(dataState, payload),
    set(isPendingState, false),
  ),
  setIdleVessels: payload => compose(
    set(idleVesselsState, payload),
    set(isPendingState, false),
  ),
  setCurrentStatusDataError: always(set(isPendingState, false)),
  setIdleVesselsError: always(set(isPendingState, false)),
  setRunning: always(set(statusState, SystemStatusTypes.RUNNING)),
  setDown: always(set(statusState, SystemStatusTypes.DOWN)),
  setLastFetchDate: set(lastFetchDateState),
});
