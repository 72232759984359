import React from 'react';
import styled from 'styled-components';
import {
  ListContainer,
  ListHeader,
  Overflow,
  CellTitle,
  Flex,
  FlexWrap,
  Label,
  InputText,
  Link,
  UpdateTitleCell,
  UpdateTitle,
} from './styles';
import {FileUpdateShape} from '../../../shapes';
import {conventionalName} from '../../../../common/domain/file';

const FileLink = styled(Link)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const FileListHeader = styled(ListHeader)`
  padding-right: 0;
`;

const FileUpdateContainer = ({update}) => (
  <>
    <UpdateTitleCell half>
      <UpdateTitle>
        FILE UPDATE
      </UpdateTitle>
    </UpdateTitleCell>
    <ListContainer gridArea="files">
      <CellTitle>Files</CellTitle>
      <FileListHeader>
        <Flex flex={3}><Label>File</Label></Flex>
        <Flex flex={2}><Label>Type</Label></Flex>
        <Flex flex={5}><Label>Link</Label></Flex>
      </FileListHeader>
      <Overflow>
        <FlexWrap>
          <Flex flex={3}>
            <InputText fieldName="file.name" fieldValue={update.name} />
          </Flex>
          <Flex flex={2}>
            <InputText fieldName="file.type" fieldValue={conventionalName(update.type)} />
          </Flex>
          <Flex flex={5} style={{maxWidth: '50%'}}>
            <FileLink href={update.uri} target="_blank" rel="noreferrer">{update.uri}</FileLink>
          </Flex>
        </FlexWrap>
      </Overflow>
    </ListContainer>
  </>
);

FileUpdateContainer.propTypes = {
  update: FileUpdateShape,
};

export default FileUpdateContainer;
