import {PortType} from '../../../common/domain/shipping';
import {FilterType} from '../../shapes';
import * as voyage from '../../../common/domain/voyage.js';

const portTypeOptions = [PortType('load'), PortType('discharge')];

const contractTypeOptions = Object.keys(voyage.VoyageType.meta.map);

export const CustomerListField = Object.freeze({
  COMPANY: 'company',
  CARGO: 'cargo',
  EMAILS: 'emails',
  PORT_TYPE: 'portType',
  PORT_NAME: 'portName',
  CONTRACT_TYPE: 'contractType',
});

export const customerColumnDefinitions = [
  {
    prop: CustomerListField.COMPANY,
    sortable: true,
    filter: {
      type: FilterType.SEARCH,
      defaultValue: '',
    },
    label: 'Company',
    width: 20,
  },
  {
    prop: CustomerListField.CONTRACT_TYPE,
    sortable: true,
    filter: {
      type: FilterType.MULTI,
      defaultValue: [voyage.VoyageType('OVOV')],
      selectProps: {
        options: [...contractTypeOptions],
        labelSelector: option => option.label,
        valueSelector: option => option.value,
      },
    },
    label: 'Contract type',
    width: 20,
  },
  {
    prop: CustomerListField.PORT_NAME,
    sortable: true,
    filter: {
      type: FilterType.SEARCH,
      defaultValue: '',
    },
    label: 'Port',
    width: 15,
  },
  {
    prop: CustomerListField.PORT_TYPE,
    sortable: true,
    filter: {
      type: FilterType.MULTI,
      defaultValue: [],
      selectProps: {
        options: [...portTypeOptions],
        labelSelector: option => option.label,
        valueSelector: option => option.value,
      },
    },
    label: 'Port type',
    width: 15,
  },
  {
    prop: CustomerListField.CARGO,
    sortable: true,
    filter: {
      type: FilterType.SEARCH,
      defaultValue: '',
    },
    label: 'Cargo',
    width: 15,
  },
  {
    prop: CustomerListField.EMAILS,
    sortable: true,
    filter: {
      type: FilterType.SEARCH,
      defaultValue: '',
    },
    label: 'Emails',
    width: 25,
  },
];
