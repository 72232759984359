import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import Dropdown from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import {find} from 'lodash';
import {view} from 'ramda';
import {createStructuredSelector} from 'reselect';
import {Table, PageContainer, PageTitleSearchBar, FontIcon, Loader} from '../../components';
import {equal as vesselEqual} from '../../services/vessels.saga';
import {
  actions as operatorsActions,
  isPendingState as isOperatorsPendingState,
  listState as operatorsListState,
} from '../../reducers/operators';
import {hasWritePermission} from '../../reducers/session';
import {actions as documentActions} from '../../reducers/documentTitle';
import {actions, isPendingState, listState} from '../../reducers/vessels';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

class Vessels extends Component {

  static propTypes = {
    getVesselsList: PropTypes.func.isRequired,
    updateVessel: PropTypes.func.isRequired,
    getOperatorsList: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired,
    isPending: PropTypes.bool.isRequired,
    vessels: PropTypes.array.isRequired,
    operators: PropTypes.array.isRequired,
    isOperatorsPending: PropTypes.bool.isRequired,
    hasWritePermission: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.getVesselsList();
    this.props.getOperatorsList();
    this.props.setDocumentTitle('Vessels');
  }

  constructor(props) {
    super(props);
    this.state = {
      activeEdit: null,
      query: '',
      columns: [
        {prop: 'name', label: 'Vessel', width: 35, sortable: true},
        {prop: 'operator', label: 'Operator', width: 35, sortable: true},
        {prop: 'isEnabled', label: 'Update required', width: 20},
        {prop: 'icon', label: '', width: 10, align: 'center'},
      ],
    };
  }
  search = query => this.setState({query});
  onEdit = vessel => () => this.setState({activeEdit: vessel});
  setIsEnabled = vessel => () => {
    const update = Object.assign({}, vessel, {isEnabled: !vessel.isEnabled});
    this.props.updateVessel(update);
  };
  updateOperator = ({target}) => {
    const activeEdit = Object.assign({}, this.state.activeEdit, {operatorId: target.value});
    this.setState({activeEdit});
  };

  renderEditOperator = vessel => {
    const operators = this.props.operators.map(o => ({
      value: o.id,
      label: o.name,
    }));
    return (
      <Dropdown
        fullWidth
        source={operators}
        value={vessel.operatorId}
        onChange={this.updateOperator}
      >
        {operators.map(o => (
          <MenuItem key={`${o.value}-operator`} value={o.value}>{o.label}</MenuItem>
        ))}
      </Dropdown>
    );
  };

  saveEdit = () => {
    // patch
    this.props.updateVessel(this.state.activeEdit);
    this.setState({activeEdit: null});
  };
  cancelEdit = () => {
    this.setState({activeEdit: null});
  };

  saveIcons = () => (
    <div>
      <FontIcon
        onClick={this.saveEdit}
        value="check"
      />
      <FontIcon
        onClick={this.cancelEdit}
        value="close2"
      />
    </div>
  );

  isActiveEdit = vessel => vesselEqual(vessel, this.state.activeEdit);

  render() {

    if (this.props.isPending || this.props.isOperatorsPending) {
      return <Loader />;
    }

    const {hasWritePermission, operators} = this.props;
    const tableData = this.props.vessels.map(vessel => {
      const op = find(operators, {id: vessel.operatorId});
      const operatorName = op ? op.name : '';
      return Object.assign({}, vessel, {
        operator: this.isActiveEdit(vessel)
          ? this.renderEditOperator(this.state.activeEdit)
          : operatorName,
        isEnabled: (
          <Checkbox
            disabled={!hasWritePermission}
            checked={vessel.isEnabled}
            onChange={this.setIsEnabled(vessel)}
          />
        ),
        icon: this.isActiveEdit(vessel)
          ? this.saveIcons()
          : hasWritePermission && <FontIcon onClick={this.onEdit(vessel)} value="pencil" />,
        [Table.rawValues]: {
          operator: operatorName,
        },
      });
    });

    return (
      <PageContainer>
        <PageTitleSearchBar title="Vessels" onSearchChange={this.search} />
        <Content>
          <Table data={tableData} columns={this.state.columns} query={this.state.query} />
        </Content>
      </PageContainer>
    );
  }
}

export default connect(
  createStructuredSelector({
    isPending: view(isPendingState),
    vessels: view(listState),
    operators: view(operatorsListState),
    isOperatorsPending: view(isOperatorsPendingState),
    hasWritePermission,
  }),
  {
    ...actions,
    ...operatorsActions,
    ...documentActions,
  },
)(Vessels);
