import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Popover, Box, ClickAwayListener} from '@material-ui/core';
import {$text} from '../styles/variables';

const PopoverBody = styled.div`
  padding: 8px;
  font-size: 14px;
  line-height: 1.3;
  color: ${$text};
`;

export const PopoverColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
`;

export const PopoverLabel = styled.div`
  text-transform: uppercase;
  color: ${$text};
  font-weight: 600;
  min-width: 40px;
`;

const ClickPopover = ({setPopover, isOpen, anchorEl, children}) => (
  <Popover
    open={isOpen}
    onClose={() => setPopover(false)}
    disableRestoreFocus
    disableScrollLock
    anchorEl={anchorEl.current}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <ClickAwayListener onClickAway={() => setPopover(false)}>
      <Box
        p={2}
        boxShadow={3}
        style={{
          background: '#fff',
          borderRadius: '4px',
        }}
      >
        <PopoverBody>
          {children}
        </PopoverBody>
      </Box>
    </ClickAwayListener>
  </Popover>
);

ClickPopover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setPopover: PropTypes.func.isRequired,
  anchorEl: PropTypes.shape({current: PropTypes.instanceOf(Element)}),
  children: PropTypes.node.isRequired,
};

export default ClickPopover;
