import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import promiseMiddleware from 'redux-promise-middleware';
import createSagaMiddleware from 'redux-saga';
import {routerMiddleware} from 'connected-react-router';

import {rejectionHandler} from './rejectionHandler';
import rootReducer from '../reducers';
import sagas from '../services/sagas';
import history from '../history';
import previousPath from './previous-path';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware({
  onError: () => {
    //eslint-disable-next-line no-alert
    alert('An unexpected error has occurred. Your window will refresh now.');
    window.location.reload();
  },
});

export const configureStore = (initialState = {}) => {
  const store = createStore(rootReducer(history), initialState, composeWithDevTools(
    applyMiddleware(
      previousPath,
      // TODO: Move thunk to saga
      thunk,
      sagaMiddleware,
      rejectionHandler,
      promiseMiddleware,
      routerMiddleware(history),
    ),
  ));

  sagaMiddleware.run(sagas);
  return store;
};

const store = configureStore();
export {store};
