import {not} from 'ramda';

export const questionaire = [
  {
    id: 1,
    label: 'Voyage orders / Agents nominated / IMOS',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Sent',
          },
          {
            label: 'Confirmed',
          },
        ],
      },
      {
        type: 'checkbox',
        options: [
          {
            label: 'Sent',
          },
          {
            label: 'Confirmed',
          },
        ],
      },
    ],
    na: {
      has: false,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: true,
  },
  {
    id: 2,
    label: 'Additional voyage orders',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Sent',
          },
          {
            label: 'Confirmed',
          },
        ],
      },
      {
        type: 'checkbox',
        options: [
          {
            label: 'Sent',
          },
          {
            label: 'Confirmed',
          },
        ],
      },
    ],
    na: {
      has: true,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: true,
  },
  {
    id: 3,
    label: 'Cargo documents L / D',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Received',
          },
          {
            label: 'Sent',
          },
        ],
      },
      {
        type: 'checkbox',
        options: [
          {
            label: 'Received',
          },
          {
            label: 'Sent',
          },
        ],
      },
    ],
    na: {
      has: false,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: true,
  },
  {
    id: 4,
    label: 'Original B/L on board',
    columns: [
      {
        type: 'radio',
        options: [
          {
            label: 'Yes',
          },
          {
            label: 'No',
          },
        ],
      },
      {
        type: '',
        options: [],
      },
    ],
    na: {
      has: false,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: false,
  },
  {
    id: 5,
    label: 'LOI',
    columns: [
      {
        type: '',
        options: [],
      },
      {
        type: 'checkbox',
        options: [
          {
            label: 'Received',
          },
          {
            label: 'Sent',
          },
        ],
      },
    ],
    na: {
      has: true,
      label: '',
    },
    dividerBorder: true,
    ovovOnly: false,
  },
  {
    id: 6,
    label: 'Freight Invoice',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Made',
          },
        ],
      },
      {
        type: 'checkbox',
        options: [
          {
            label: 'Sent',
          },
        ],
      },
    ],
    na: {
      has: false,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: true,
  },
  {
    id: 7,
    label: 'AV Commision note Freight',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Made',
          },
        ],
      },
      {
        type: '',
        options: [],
      },
    ],
    na: {
      has: false,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: true,
  },
  {
    id: 8,
    label: 'Laytime calculation',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Made',
          },
        ],
      },
      {
        type: 'checkbox',
        options: [
          {
            label: 'Sent',
          },
        ],
      },
    ],
    na: {
      has: false,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: true,
  },
  {
    id: 9,
    label: 'Demurrage',
    columns: [
      {
        type: 'radio',
        options: [
          {
            label: 'Yes',
          },
          {
            label: 'No',
          },
        ],
      },
      {
        type: 'checkbox',
        options: [
          {
            label: 'Laytime Bank',
          },
        ],
      },
    ],
    na: {
      has: false,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: true,
  },
  {
    id: 10,
    label: 'Demurrage Invoice',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Made',
          },
        ],
      },
      {
        type: 'checkbox',
        options: [
          {
            label: 'Sent',
          },
        ],
      },
    ],
    na: {
      has: true,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: true,
  },
  {
    id: 11,
    label: 'AV commission note Demurrage',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Made',
          },
        ],
      },
      {
        type: '',
        options: [],
      },
    ],
    na: {
      has: true,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: true,
  },
  {
    id: 12,
    label: 'Period Journal',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Created',
          },
        ],
      },
      {
        type: 'checkbox',
        options: [
          {
            label: 'Posted',
          },
        ],
      },
    ],
    na: {
      has: false,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: true,
  },
  {
    id: 13,
    label: 'AV Cooling/Heating report',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Received',
          },
        ],
      },
      {
        type: 'checkbox',
        options: [
          {
            label: 'Saved',
          },
        ],
      },
    ],
    na: {
      has: true,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: false,
  },
  {
    id: 14,
    label: 'Charterers cooling report',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Received',
          },
        ],
      },
      {
        type: 'checkbox',
        options: [
          {
            label: 'Saved / Sent',
          },
        ],
      },
    ],
    na: {
      has: true,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: true,
  },
  {
    id: 21,
    label: 'GHG emission report',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Created',
          },
        ],
      },
      {
        type: 'checkbox',
        options: [
          {
            label: 'Sent',
          },
        ],
      },
    ],
    na: {
      has: true,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: true,
  },
  {
    id: 15,
    label: 'Bunkers',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Ordered',
          },
        ],
      },
      {
        type: 'checkbox',
        options: [
          {
            label: 'Req. next voyage',
          },
        ],
      },
    ],
    na: {
      has: true,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: true,
  },
  {
    id: 16,
    label: 'Bunker Surveyor',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Ordered',
          },
        ],
      },
      {
        type: 'checkbox',
        options: [
          {
            label: 'Report received',
          },
        ],
      },
    ],
    na: {
      has: true,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: true,
  },
  {
    id: 17,
    label: 'Purging Operation',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Plan received',
          },
          {
            label: 'Report sent to purging manager',
          },
        ],
      },
      {
        type: 'checkbox',
        options: [
          {
            label: 'Report received',
          },
        ],
      },
    ],
    na: {
      has: true,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: false,
  },
  {
    id: 18,
    label: 'Speed & Consumption',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Checked',
          },
        ],
      },
      {
        type: '',
        options: [],
      },
    ],
    na: {
      has: false,
      label: '',
    },
    dividerBorder: false,
    ovovOnly: false,
  },
  {
    id: 19,
    label: 'Off hire, out of operations & delays',
    columns: [
      {
        type: 'checkbox',
        options: [
          {
            label: 'Checked',
          },
        ],
      },
      {
        type: '',
        options: [],
      },
    ],
    na: {
      has: false,
      label: '',
    },
    dividerBorder: true,
    ovovOnly: false,
  },
];

export const isCompleted = {
  id: 20,
  label: 'Completed',
};

export const getChecklistStats = (ovovOnly, checklists) => {
  const finalQ = checklists.find(
    c => c.id === isCompleted.id &&
    c.answer1[0] !== undefined &&
    c.answer1[0] === isCompleted.label,
  );

  const allAnswers = questionaire.filter(voyage => voyage.ovovOnly ? ovovOnly : true).map(q => {
    const check = checklists.find(c => c.id === q.id);
    return check && (
      check.answer1.some(Boolean) ||
      check.answer2.some(Boolean) ||
      check.na
    );
  });

  return {
    isFinished: Boolean(finalQ),
    openQuestions: allAnswers.filter(not).length,
  };
};

export const createAnswer = (
  id,
  vessel,
  voyage,
  na,
  answer1,
  answer2,
) => ({
  id,
  na,
  vessel,
  voyage,
  answer1,
  answer2,
});

const getQuestion = cmpId => questionaire.find(({id}) => id === cmpId);
const compareAnswer = (question, value, column, index) => {
  const accesor = `answer${column + 1}`;
  const possibleValues = question.columns[column].options.map(({label}) => label);

  return value[accesor][index] !== undefined &&
    possibleValues[index] === value[accesor][index];
};

export const sideEffects = {
  //If 'Original B/L on board' is YES,
  //  -> then 'LOI' needs to be 'N/A'
  4: value => compareAnswer(getQuestion(4), value, 0, 0)
    ? [createAnswer(5, value.vessel, value.voyage, true, [], [])]
    : [],

  //If 'Demurrage' is NO,
  //  -> then 'Demurrage Invoice' and 'AV Commission note Demurrage' need to be 'N/A'
  //If 'Demurrage' is YES and 'Laytime bank' is checked,
  //  -> then 'Demurrage Invoice' needs to be 'N/A'
  9: value => {
    const question = getQuestion(9);
    const labels = question.columns[0].options.map(({label}) => label);

    if(value.answer1[0] === 'No') {
      return [
        createAnswer(10, value.vessel, value.voyage, true, [], []),
        createAnswer(11, value.vessel, value.voyage, true, [], []),
      ];
    }

    return labels.includes(value.answer1[0]) &&
      compareAnswer(question, value, 1, 0)
      ? [createAnswer(10, value.vessel, value.voyage, true, [], [])]
      : [];
  },

  //If 'Bunkers' is N/A
  //  -> then set 'Bunker Surveyor' to N/A as well
  15: value => value.na
    ? [createAnswer(16, value.vessel, value.voyage, true, [], [])]
    : [],
};
