import React from 'react';
import PropTypes from 'prop-types';
import {Router, Route} from 'react-router-dom';
import {Security, SecureRoute, ImplicitCallback} from '@okta/okta-react';
import {withRouter} from 'react-router';
import {App} from '../containers';

const onAuthRequired = ({auth, history}) => {
  const parsedHash = new URLSearchParams(history.location.hash.substr(1));
  const error = parsedHash.get('error');
  if(error) {
    //eslint-disable-next-line no-alert
    alert(parsedHash.get('error_description') || error);
    auth.logout();
  } else {
    auth.redirect();
  }
};

const AuthRouter = ({history}) =>
  <Router history={history}>
    <Security
      issuer={`https://${process.env.OKTA_DOMAIN}`}
      clientId={process.env.OKTA_CLIENT_ID}
      scopes={['openid', 'profile', 'groups']}
      response_type={['id_token']}
      redirectUri={`${window.location.origin}/implicit/callback`}
      onAuthRequired={onAuthRequired}
    >
      <SecureRoute path="/" component={App} />
      <Route path="/implicit/callback" component={ImplicitCallback} />
    </Security>
  </Router>;

AuthRouter.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(AuthRouter);
