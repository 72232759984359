import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {map, sum, compose, prop} from 'ramda';
import {PieCanvas} from '@nivo/pie';

import {$white, $gray, $gray3} from '../../../styles/variables';

const Container = styled.div`
  padding: 25px;
  display: flex;
  background-color: ${$white};
  flex-wrap: wrap;
`;

const Column = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 50%;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  padding-bottom: 25px;
  align-items: left;

  small {
    margin-left: 10px;
    font-weight: normal;
    color: ${$gray};
  }
`;

const PieSummary = styled.div`
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: bold;

  > span {
    display: flex;

    &:first-child {
      font-size: 50px;
    }

    &:last-child {
      font-size: 25px;
      margin-top: -10px;
    }
  }
`;

const Total = styled.span`
  font-weight: bold;
  min-width: 70px;
  font-size: 36px;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    width: 15px;
    height: 15px;
    background-color: ${$gray3};
    display: inline-block;
    border-radius: 100%;
    margin-right: 15px;
  }
`;

const Label = styled.span`
  font-size: 1.1em;
  color: ${$gray};
  padding-left: 10px;
`;

const Captions = styled.ul`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const Caption = styled.li`
  flex: 1;
  align-items: center;
  display: flex;

  ${Total} {
    ${props => props.color && `color: ${props.color};`}

    &:before {
      ${props => props.color && `background-color: ${props.color};`}
    }
  }
`;

const calcTotal = compose(sum, map(prop('value')));

const UpdatesStats = ({className, data}) => (
  <Container className={className}>
    <Title>Updates to be processed</Title>
    <Column>
      <PieCanvas
        data={data}
        colors={({color}) => color}
        pixelRatio={1}
        enableSlicesLabels={false}
        height={200}
        width={200}
        isInteractive={false}
        innerRadius={0.85}
      />
      <PieSummary>
        <span>{calcTotal(data)}</span>
        <span>total</span>
      </PieSummary>
    </Column>

    <Column>
      <Captions>
        {data.map(x => (
          <Caption key={x.label} color={x.color}>
            <Total>{x.value}</Total>
            <Label>{x.label}</Label>
          </Caption>
        ))}
      </Captions>
    </Column>

  </Container>
);

UpdatesStats.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    color: PropTypes.string.isRequired,
  })).isRequired,
};

export default UpdatesStats;
