import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {connect} from 'react-redux';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import {view} from 'ramda';
import Dialog from '@material-ui/core/Dialog';
import {actions, errorLens} from '../reducers/error';

const Content = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  color: #415868;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  margin: 0 0 20px;
  font-weight: 400;
`;

const Dismiss = styled.button`
  background: #F65656;
  border: none;
  color: #fff;
  width: 100%;
  padding: 20px;
  cursor: pointer;
  transition: background 0.2s ease;
  font-family: 'Roboto', sans-serif;
  &:hover {
    background: #d54a4a;
  }
`;

const Warning = styled(WarningRoundedIcon)`
  color: #F65656;
  font-size: 64px;
  margin-bottom: 20px;
`;

const Message = styled.p`
  line-height: 1.5;
  text-align: center;
`;

const Log = styled.pre``;

const ErrorHandler = ({error, resetError}) => {

  const onClose = () => {
    resetError();
  };

  return error && (
    <Dialog disableBackdropClick disableEscapeKeyDown onClose={onClose} open>
      <Content>
        <Warning />
        <Title>Error occured!</Title>
        <Message>
          Your request cannot be processed right now.<br />
          Please refresh page or try again later.
          If this problem still occurs please contact support for help.
        </Message>
        <Log>Code: {error.message}</Log>
      </Content>
      <Dismiss onClick={onClose}>Dismiss</Dismiss>
    </Dialog>
  );
};

ErrorHandler.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.instanceOf(Error),
    PropTypes.shape({
      name: PropTypes.string,
      message: PropTypes.string,
    }),
  ]),
  resetError: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    error: view(errorLens, state),
  }),
  actions,
)(ErrorHandler);
