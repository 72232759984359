import {put, takeEvery, call, select} from 'redux-saga/effects';
import axios from 'axios';
import {view} from 'ramda';

import {types, actions, teamState} from '../reducers/vessel';
import {actions as errorActions} from '../reducers/error';

const getTeam = function* ({payload}) {
  const team = yield select(view(teamState));

  if(team) {
    return;
  }

  yield put(actions.setTeam());
  yield put(actions.setTeamLoader(true));
  try {
    const response = yield call(axios.get, `/vessels/${payload}/team`);
    yield put(actions.setTeam(response.data));
    yield put(actions.setTeamServiceUnavailable(false));
  } catch (e) {
    e.response.status === 503
      ? yield put(actions.setTeamServiceUnavailable(true))
      : yield put(errorActions.setError(e.response.data));
  } finally {
    yield put(actions.setTeamLoader(false));
  }

};

const getVessel = function* ({payload}) {
  yield put(actions.setVessel());
  yield put(actions.setVesselLoader(true));
  try {
    const response = yield call(axios.get, `/vessels/${payload}`);
    yield put(actions.setVessel(response.data));
  } catch (e) {
    yield put(errorActions.setError(e.response.data));
  } finally {
    yield put(actions.setVesselLoader(false));
  }

};

export default function* () {
  yield takeEvery(types.loadTeam, getTeam);
  yield takeEvery(types.loadVessel, getVessel);
}
