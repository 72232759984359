import {lensProp, compose, set} from 'ramda';
import {createReducer} from 'warped-reducers';

export const baseLens = lensProp('documentTitle');

export const documentTitleState = compose(
  baseLens,
  lensProp('documentTitle'),
);

export const {reducer, types, actions} = createReducer('documentTitle')({
  setDocumentTitle: set(documentTitleState),
});
