import React from 'react';
import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import {intersperse} from 'ramda';

import {UpdateBundleShape, RecipientShape} from '../../../shapes';
import {formatTimestamp} from '../../../utils/time';
import {StatusIconMap} from '../constants';
import {
  UpdateDetailsContainer,
  CellTitle,
  FlexWrap,
  Flex,
  Label,
  Value,
  Status,
} from './styles';

const Validations = props => {
  const validations = props.validations
  .map(v =>
    <Tooltip key={`${v.reason}-${v.code}`} style={{cursor: 'pointer'}} title={v.reason}>
      <span>{v.code}</span>
    </Tooltip>,
  );
  return validations.length ? intersperse(', ', validations) : '-';
};

const Recipients = ({recipients}) => {
  if (recipients && recipients.length > 0) {
    return recipients.map(recipient => (
      <Value key={`${recipient.type}-${recipient.value}`}>{recipient.value}</Value>
    ));
  }

  return (
    <>
      <Value><strong>No recipients configured</strong></Value>
      <Link component={RouterLink} to="/settings/customers" style={{fontSize: '14px'}}>
        &gt; Change customer configuration
      </Link>
    </>
  );
};

Recipients.propTypes = {
  recipients: PropTypes.arrayOf(RecipientShape).isRequired,
};

const UpdateDetails = ({update}) => {
  const {status, recipients, createdAt, validations} = update;
  const Icon = StatusIconMap[status].icon;
  return (
    <UpdateDetailsContainer gridArea="details">
      <CellTitle>Update details</CellTitle>
      <FlexWrap direction="column">
        <Flex>
          <Label>Status</Label>
          <Status color={StatusIconMap[status].color}>
            <Icon /> {StatusIconMap[status].label}
          </Status>
        </Flex>
        <Flex>
          <Label>Date and time</Label>
          <Value>{formatTimestamp(createdAt)}</Value>
        </Flex>
        <Flex>
          <Label>Recipients</Label>
          <Recipients
            recipients={recipients}
            status={status}
          />
        </Flex>
        <Flex>
          <Label>Rejections</Label>
          <Value>
            <Validations
              validations={validations.filter(v => v.status === 'rejected')}
            />
          </Value>
        </Flex>
        <Flex>
          <Label>Warnings</Label>
          <Value>
            <Validations
              validations={validations.filter(v => v.status === 'warning')}
            />
          </Value>
        </Flex>
      </FlexWrap>
    </UpdateDetailsContainer>
  );
};

UpdateDetails.propTypes = {
  update: UpdateBundleShape.isRequired,
};

export default UpdateDetails;
