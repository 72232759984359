import React from 'react';
import PropTypes from 'prop-types';
import styled, {css, keyframes} from 'styled-components';
import RefreshIcon from '@material-ui/icons/Refresh';
import {$nav} from '../styles/variables';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const animation = () => css`${rotate} 1s linear infinite;`;

const Icon = styled.div`
  cursor: ${({isLoading}) => isLoading ? 'not-allowed' : 'pointer'};
  color: ${$nav};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    pointer-events: ${({isLoading}) => isLoading ? 'none' : 'all'};
    animation: ${({isLoading}) => isLoading ? animation : 'none'};
  }
`;

const RefreshLoader = ({isLoading, onClick}) => (
  <Icon isLoading={isLoading}><RefreshIcon onClick={onClick} /></Icon>
);

RefreshLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RefreshLoader;
