import {createGlobalStyle, css} from 'styled-components';

import SourceSansProRegular from '../fonts/SourceSansPro-Regular.ttf';
import SourceSansProBold from '../fonts/SourceSansPro-Bold.ttf';

import MaterialAwesomeEot from '../fonts/materialAwesome.eot';
import MaterialAwesomeTtf from '../fonts/materialAwesome.ttf';
import MaterialAwesomeWoff from '../fonts/materialAwesome.woff';
import MaterialAwesomeSvgf from '../fonts/materialAwesome.svg';

import {$bg, $white, $blue} from './variables';

import iconsStyles from './icon-font';

export const ElevatedBoxShadow = css`
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.05);
`;

export const ElevatedContainerStyle = css`
  border-radius: 5px;
  ${ElevatedBoxShadow};
`;

export default createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(${SourceSansProRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(${SourceSansProBold}) format('truetype');
  }

  /* TEMP: Remove the materialAwesome after migration to Material UI Icons */
  ${iconsStyles}
  @font-face {
    font-family: 'materialAwesome';
    font-style: normal;
    font-weight: normal;
    src: url(${MaterialAwesomeEot});
    src: url(${MaterialAwesomeEot}) format('embedded-opentype'),
        url(${MaterialAwesomeTtf}) format('truetype'),
        url(${MaterialAwesomeWoff}) format('woff'),
        url(${MaterialAwesomeSvgf}) format('svg');
  }

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    background: ${$bg};
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
  }

  a {
    text-decoration: none;
  }

  .link {
    color: ${$blue};
    text-decoration: none;
    transition: 0.2s ease color;

    &:hover {
      color: darken(${$blue}, 10%);
    }
  }

  // remove ugly yellow background from autofilled inputs
  input:-webkit-autofill {
    box-shadow: 0 0 0 30px ${$white} inset;
  }
`;
