import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {view} from 'ramda';
import {Button, TextField} from '@material-ui/core/';
import {Checkbox, Radio} from '..';
import {$nav, $text, $avOrange, $gray3} from '../../styles/variables';

import {
  actions,
  selectedVesselState,
  selectedVoyageState,
  selectedChecklistState,
  checklistSavingState,
  remarksState,
  checklistFormLoadingState,
  checklistFormErrorState,
} from '../../reducers/checklists';
import {questionaire, isCompleted, getChecklistStats} from './questionaire';
import {filter, compareVoyageNumbers} from './filter';
import {actions as voyagesActions, loaderState} from '../../reducers/voyages';
import RefreshLoader from '../RefreshLoader';
import {ElevatedContainerStyle} from '../../styles/global';
import {MAX_REMARKS_LENGTH} from '../../../common/domain/checklist';

const headerHeight = 60;
const columnHeaderHeight = 24;
const columnHeaderPadding = 18;

const Container = styled.div`
  overflow: hidden;
  max-height: calc(100vh - ${headerHeight}px);
  height: calc(100vh - ${headerHeight}px);
  max-width: 1280px;
  min-width: 720px;
  width: 80%;
  padding: 24px 0;
  margin: 0 auto;
`;

const Content = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  ${ElevatedContainerStyle};
  margin: 4px;
`;

const Scroll = styled.div`
  max-height: calc(100% - ${columnHeaderHeight}px - ${columnHeaderPadding * 2}px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px 0;
`;

const PaddedContainer = styled.div`
  padding: 0 16px;
`;

const Row = styled.div`
  display: flex;
  height: 100%;
`;

const Column = styled.div`
  width: ${props => props.width}%;
  background: #${props => props.bg};
  border-right: ${props => props.border ? `solid 1px ${$nav}` : 'none'};
  height: 100%;
`;

const ColumnHeader = styled.h3`
  font-weight: bold;
  color: ${$nav};
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  margin: 0;
  height: 56px;
`;

const Title = styled.div`
  margin-right: 8px;
`;

const Selector = styled.div`
  padding: 10px 18px;
  font-size: 14px;
  cursor: pointer;
  min-height: 40px;
  color: ${props => props.selected ? '#fff' : $text};
  background: ${props => props.selected ? $nav : 'none'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    color: #fff;
    background: ${$nav};
  }
`;

const QuestionaireHeader = styled.h4`
  padding-bottom: 16px;
  margin: 0;
  color: ${$nav};
  width: ${props => props.width}%;
`;

const Answers = styled.div`
  font-size:12px;
  color: ${$text};
  width: ${props => props.width}%;
  display: flex;
  flex-direction: column;
`;

const BottomBorder = styled.div`
  margin: 8px 0;
  height: ${props => props.isDivider ? 2 : 1}px;
  background: ${props => props.isDivider ? $nav : '#f0f0f0'};
`;

const Counter = styled.div`
  border-radius: 50%;
  background: ${$avOrange};
  width: 20px;
  height: 20px;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${$gray3};
  min-height: 200px;
`;

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: $nav,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor:  $nav,
  },
});

const RemarksMaxCharsMessage = styled.div`
  font-size: 10px;
  color: '#ccc';
  margin-top: -14px;
`;

const getVoyagesForVessel = (voyages, vessel, filterVoyages) =>
  voyages
  .filter(v => v.vessel === vessel.name)
  .filter(v => compareVoyageNumbers(
    v.voyage,
    filterVoyages.voyageStart,
    filterVoyages.yearDigits,
  ));

// eslint-disable-next-line complexity
const VoyageChecklist = ({
  vessels,
  voyages,
  checklists,
  selectedChecklist,
  selectedVessel,
  selectedVoyage,
  setSelectedVessel,
  setSelectedVoyage,
  setSelectedChecklist,
  updateAnswer,
  saveUpdate,
  isSaving,
  isLoading,
  loadVesselVoyages,
  remarks,
  setRemarks,
  loadChecklistForm,
  setChecklistFormData,
  isChecklistFormLoading,
  checklistFormError
}) => {

  const defaultFilter = {name: '', voyageStart: 0};
  const filterVoyages = selectedVessel
    ? (filter.find(f => f.name.toUpperCase() === selectedVessel.name) || defaultFilter)
    : defaultFilter;

  const voyagesForVessel = selectedVessel
    ? getVoyagesForVessel(voyages, selectedVessel, filterVoyages).reverse()
    : [];

  const selectVoyage = (voyage, vessel) => () => {
    setChecklistFormData({voyage: voyage.voyage, vessel: vessel.name, remarks: ''});
    loadChecklistForm({vessel, voyage});
    const checklist = checklists.filter(
      c => c.vessel === vessel.name && c.voyage === voyage.voyage,
    );
    setSelectedVoyage(voyage);
    setSelectedChecklist(checklist);
  };

  const selectVessel = vessel => () => {
    setChecklistFormData({});
    setSelectedChecklist([]);
    setSelectedVoyage(null);
    setSelectedVessel(vessel);
  };

  const getAnswer = (answers, questionId) => answers.find(({id}) => id === questionId);
  const getNA = questionId => {
    const q = getAnswer(selectedChecklist, questionId);
    if(!q) {
      return false;
    }
    return q.na;
  };
  const getValue = (questionId, accesor, index) => {
    const q = getAnswer(selectedChecklist, questionId);
    if(!q) {
      return false;
    }

    const values = q[accesor];
    return values[index] !== undefined && values[index];
  };

  const createAnswer = id => ({
    id,
    na: false,
    vessel: selectedVessel.name,
    voyage: selectedVoyage.voyage,
    answer1: [],
    answer2: [],
    ...getAnswer(selectedChecklist, id),
  });

  const setCheckbox = (id, accesor, index, label) => value => {

    const current = createAnswer(id);
    const mod = [...current[accesor]];

    mod[index] = value ? label : null;
    const up = {
      ...current,
      [accesor]: mod,
    };

    updateAnswer(up);
  };

  const setNA = id => value => {
    const current = createAnswer(id);
    const up = {
      ...current,
      answer1: [],
      answer2: [],
      na: value,
    };

    updateAnswer(up);
  };

  const save = () => {
    saveUpdate(selectedChecklist);
  };

  const countInVoyage = (vessel, voyage) => {
    const matchingChecklists = checklists.filter(
      c => c.vessel === vessel.name && c.voyage === voyage.voyage,
    );
    return getChecklistStats(voyage.voyageType === 'OVOV', matchingChecklists);
  };

  const countOpenChecklists = vessel => {
    const filterVoyages = filter.find(f => f.name.toUpperCase() === vessel.name) || defaultFilter;
    const matchingVoyages = getVoyagesForVessel(voyages, vessel, filterVoyages);
    const counts = matchingVoyages.map(voyage => countInVoyage(vessel, voyage));

    return counts.filter(c => !c.isFinished).length;
  };

  const renderVesselCounter = vessel => {
    const counter = countOpenChecklists(vessel);
    return counter > 0 && <Counter>{counter}</Counter>;
  };

  const renderVoyageCounter = (vessel, voyage) => {
    const counter = countInVoyage(vessel, voyage);

    return !counter.isFinished &&
      counter.openQuestions > 0 &&
        <Counter>{counter.openQuestions}</Counter>;
  };

  const renderAnswersCol = (question, column, c, isDisabled) => {
    const accesor = `answer${c + 1}`;
    return (
      <Answers key={c} width="25">
        {
          column.type === 'checkbox' ? (
            column.options.map(({label, f}, index) => (
              <div key={label}>
                <Checkbox
                  disabled={isDisabled}
                  checked={getValue(question.id, accesor, index) === label}
                  label={label}
                  onClick={setCheckbox(question.id, accesor, index, label, f)}
                />
              </div>
            ))
          ) : column.type === 'radio' ? (
            <>
              {column.options.map(({label, f}) =>
                <div key={label}>
                  <Radio
                    disabled={isDisabled}
                    checked={getValue(question.id, accesor, 0) === label}
                    label={label}
                    onClick={setCheckbox(question.id, accesor, 0, label, f)}
                  />
                </div>,
              )}
            </>
          ) : (
            null
          )
        }
      </Answers>
    );
  };

  const hasChecklistFormError = Boolean(checklistFormError);

  return (
    <Container>
      <Content>
        <Row>
          <Column width="25" bg="ffffff" border>
            <ColumnHeader>Vessel</ColumnHeader>
            <Scroll>
              {vessels.map(vessel =>
                <Selector
                  selected={selectedVessel && selectedVessel.name === vessel.name}
                  key={vessel.name}
                  onClick={selectVessel(vessel)}
                >
                  {vessel.name}
                  {renderVesselCounter(vessel)}
                </Selector>,
              )}
            </Scroll>
          </Column>
          <Column width="15" bg="ffffff" border>
            <ColumnHeader>
              <Title>Voyage</Title>
              {selectedVessel && (
                <RefreshLoader
                  isLoading={isLoading > 0}
                  onClick={() => loadVesselVoyages(selectedVessel.name)}
                />
              )}
            </ColumnHeader>
            <Scroll>
              {voyagesForVessel && voyagesForVessel.map(voyage =>
                <Selector
                  selected={selectedVoyage && selectedVoyage.voyage === voyage.voyage}
                  key={voyage.voyage}
                  onClick={selectVoyage(voyage, selectedVessel)}
                >
                  {voyage.voyage}
                  {renderVoyageCounter(selectedVessel, voyage)}
                </Selector>,
              )}
            </Scroll>
          </Column>
          <Column width="60" bg="ffffff">
            {selectedVessel && selectedVoyage ? (
              <>
                <ColumnHeader style={{justifyContent: 'space-between'}}>
                  Checklist
                  <Button
                    disabled={isSaving || isChecklistFormLoading}
                    variant="contained"
                    onClick={save}
                  >
                    Save
                  </Button>
                </ColumnHeader>
                <Scroll>
                  <PaddedContainer>
                    <Row>
                      <QuestionaireHeader width="42" />
                      <QuestionaireHeader width="25">Loading port</QuestionaireHeader>
                      <QuestionaireHeader width="25">Discharge port</QuestionaireHeader>
                      <QuestionaireHeader width="8">N/A</QuestionaireHeader>
                    </Row>
                    {questionaire.filter(question => (
                      question.ovovOnly ? selectedVoyage.voyageType === 'OVOV' : true
                    )).map(question => (
                      <div key={question.id}>
                        <Row style={{alignItems: 'center'}}>
                          <Answers width="42">{question.label}</Answers>
                          {question.columns.map((column, c) =>
                            renderAnswersCol(question, column, c, getNA(question.id)),
                          )}
                          <Answers width="8">
                            {question.na.has &&
                              <div>
                                <Checkbox
                                  checked={getNA(question.id)}
                                  onClick={setNA(question.id)}
                                />
                              </div>}
                          </Answers>
                        </Row>
                        <BottomBorder isDivider={question.dividerBorder} />
                      </div>
                    ))}
                    <Answers width="100" style={{marginBottom: '16px'}}>
                      <div>
                        <Checkbox
                          checked={getValue(isCompleted.id, 'answer1', 0) === isCompleted.label}
                          label="Checklist completed"
                          onClick={setCheckbox(
                            isCompleted.id,
                            'answer1',
                            0,
                            isCompleted.label,
                          )}
                        />
                      </div>
                    </Answers>
                    <StyledTextField
                      disabled={isChecklistFormLoading || hasChecklistFormError}
                      inputProps={{style: {fontSize: 14}, maxLength: MAX_REMARKS_LENGTH}}
                      fullWidth
                      multiline
                      value={
                        hasChecklistFormError
                          ? 'Failed to load remarks.'
                          : isChecklistFormLoading ? 'loading...' : remarks}
                      label="Remarks"
                      onChange={evt => setRemarks(evt.target.value)}
                    />
                    {/*
                      Proper form validation / error message is generally preferred but
                      out of scope for just this single field.
                      Chose for maxLength and 'characters remaining' to save time.
                    */}
                    {!isChecklistFormLoading && remarks.length > 0.5 * MAX_REMARKS_LENGTH && (
                      <RemarksMaxCharsMessage>
                        {Math.max(0, MAX_REMARKS_LENGTH - remarks.length)}
                        {' '}of {MAX_REMARKS_LENGTH} characters remaining
                      </RemarksMaxCharsMessage>
                    )}
                  </PaddedContainer>
                </Scroll>
              </>
            ) : (
              <TextPlaceholder>Please select voyage</TextPlaceholder>
            )}
          </Column>
        </Row>
      </Content>
    </Container>
  );
};

VoyageChecklist.propTypes = {
  vessels: PropTypes.array.isRequired,
  voyages: PropTypes.array.isRequired,
  checklists: PropTypes.array.isRequired,
  selectedVessel: PropTypes.object,
  selectedVoyage: PropTypes.object,
  selectedChecklist: PropTypes.array.isRequired,
  setSelectedVessel: PropTypes.func.isRequired,
  setSelectedVoyage: PropTypes.func.isRequired,
  setSelectedChecklist: PropTypes.func.isRequired,
  updateAnswer: PropTypes.func.isRequired,
  saveUpdate: PropTypes.func.isRequired,
  loadVesselVoyages: PropTypes.func.isRequired,
  isLoading: PropTypes.number.isRequired,
  isSaving: PropTypes.bool.isRequired,
  loadChecklistForm: PropTypes.func.isRequired,
  isChecklistFormLoading: PropTypes.bool.isRequired,
  checklistFormError: PropTypes.any,
  setChecklistFormData: PropTypes.func.isRequired,
  remarks: PropTypes.string.isRequired,
  setRemarks: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    isLoading: view(loaderState, state),
    isSaving: view(checklistSavingState, state),
    selectedVessel: view(selectedVesselState, state),
    selectedVoyage: view(selectedVoyageState, state),
    selectedChecklist: view(selectedChecklistState, state),
    isChecklistFormLoading: view(checklistFormLoadingState, state),
    checklistFormError: view(checklistFormErrorState, state),
    remarks: view(remarksState, state),
  }),
  {
    ...actions,
    ...voyagesActions,
  },
)(VoyageChecklist);
