import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import TableFilter from '../../components/Table/TableFilter';
import {customerColumnDefinitions} from './constants';
import {
  Table,
  PageContainer,
  Loader,
  FontIcon,
} from '../../components';
import {
  list as listClients,
  update as updateClient,
  equal as clientEqual,
  setClientColumnFilter,
  clearClientColumnFilter,
} from '../../services/clients';
import {hasWritePermission} from '../../reducers/session';
import {actions} from '../../reducers/documentTitle';
import {FilterType} from '../../shapes';

const Content = styled.div`
  display: flex;
  justify-content: center;
`;

const Input = styled(TextField)`
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
`;

const EmailList = styled.div`
  width: 100%;
`;

const EmailItem = styled.div`
  min-height: 40px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 100%;
  line-height: 40px;
  &:first-child {
    margin-top: -8px;
  }
  &:last-child {
    margin-bottom: -8px;
  }
`;
class Customers extends Component {
  static propTypes = {
    listClients: PropTypes.func.isRequired,
    setClientColumnFilter: PropTypes.func.isRequired,
    clearClientColumnFilter: PropTypes.func.isRequired,
    updateClient: PropTypes.func.isRequired,
    setDocumentTitle: PropTypes.func.isRequired,
    clients: PropTypes.shape({
      list: PropTypes.array,
      clientColumnFilters: PropTypes.object,
      selectFieldOptions: PropTypes.object,
      isPending: PropTypes.bool,
    }),
    hasWritePermission: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.listClients();
    this.props.setDocumentTitle('Customers');
  }

  constructor(props) {
    super(props);
    this.state = {
      activeEdit: null,
    };
  }

  saveEdit = () => {
    const {activeEdit} = this.state;
    const emails =
      (activeEdit.emails || '').trim().replace(/[\s,]+/g, ',') || null;
    const update = Object.assign({}, activeEdit, {emails});
    this.props.updateClient(update);
    this.setState({activeEdit: null});
  };
  cancelEdit = () => {
    this.setState({activeEdit: null});
  };
  updateEmails = ({target}) => {
    const activeEdit = Object.assign({}, this.state.activeEdit, {
      emails: target.value,
    });
    this.setState({activeEdit});
  };

  onEdit = client => () => {
    const emails = (client.emails || '').replace(/,/g, '\n');
    const activeEdit = Object.assign({}, client, {emails});
    this.setState({activeEdit});
  };

  emailEdit = emails => (
    <Input multiline onChange={this.updateEmails} value={emails} />
  );
  emailList = list => (
    <EmailList>
      {list.split(',').map(d => (
        <EmailItem key={d} title={d}>
          {d}
        </EmailItem>
      ))}
    </EmailList>
  );

  saveIcons = () => (
    <div>
      <FontIcon onClick={this.saveEdit} value="check" />
      <FontIcon onClick={this.cancelEdit} value="close2" />
    </div>
  );

  editIcon = d => <FontIcon onClick={this.onEdit(d)} value="pencil" />;

  isActiveEdit = client => clientEqual(client, this.state.activeEdit);

  render() {
    const {
      hasWritePermission,
      clients,
      clearClientColumnFilter,
      setClientColumnFilter,
    } = this.props;

    const filterState = clients.clientColumnFilters;

    const filteredTableData = clients.list.filter(row => {
      const matched = Object.entries(filterState).every(([key, filterValue]) => {
        if (Array.isArray(filterValue) && filterValue.length) {
          const someArrayMatch = filterValue.some(value =>
            String(row[key])
            .toLowerCase()
            .includes(String(value).toLowerCase().trim())
          );
          return someArrayMatch;
        }
        const stringMatch = String(row[key])
        .toLowerCase()
        .includes(String(filterValue).toLowerCase().trim());
        return stringMatch;
      });
      return matched;
    });

    const isSelectableFilter = column =>
      column.filter.type === FilterType.MULTI ||
      column.filter.type === FilterType.MULTI_ALL;

    const filters = customerColumnDefinitions.reduce((acc, column) => {
      const {clientColumnFilters} = clients;

      return {
        ...acc,
        [column.prop]: (
          <TableFilter
            {...column.filter}
            type={column.filter.type}
            value={clientColumnFilters[column.prop]}
            onChange={newValue =>
              setClientColumnFilter({field: column.prop, value: newValue})
            }
            onClear={() => clearClientColumnFilter(column.prop)}
            selectProps={{
              options: isSelectableFilter(column)
                ? column.filter.selectProps.options
                : [],
              labelSelector: option => option,
              valueSelector: option => option,
            }}
          />
        ),
      };
    }, {});

    const columnsWithEditColumn = [
      ...customerColumnDefinitions,
      {
        prop: 'icon',
        label: '',
        width: 10,
        align: 'center',
      },
    ];

    const tableData = filteredTableData.map(row =>
      Object.assign(
        {},
        row,
        this.isActiveEdit(row)
          ? {
            emails: this.emailEdit(this.state.activeEdit.emails || ''),
            icon: this.saveIcons(),
          }
          : {
            emails: this.emailList(row.emails || ''),
            icon: hasWritePermission && this.editIcon(row),
          }
      )
    );

    if (clients.isPending) {
      return <Loader />;
    }

    return (
      <PageContainer>
        <Content>
          <Table
            data={tableData}
            columns={columnsWithEditColumn}
            onRowClick={this.onRowClickHandler}
            filters={filters}
          />
        </Content>
      </PageContainer>
    );
  }
}

export default connect(
  storeState => ({
    clients: storeState.clients,
    hasWritePermission: hasWritePermission(storeState),
  }),
  dispatch => ({
    listClients: () => dispatch(listClients()),
    updateClient: update => dispatch(updateClient(update)),
    setDocumentTitle: title => dispatch(actions.setDocumentTitle(title)),
    setClientColumnFilter: filter => dispatch(setClientColumnFilter(filter)),
    clearClientColumnFilter: field =>
      dispatch(clearClientColumnFilter(field)),
  })
)(Customers);
