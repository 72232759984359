import {
  always,
  append,
  compose,
  dec,
  inc,
  intersection,
  lensPath,
  lensProp,
  map,
  mergeLeft,
  over,
  prop,
  propEq,
  reject,
  set,
  view,
  without,
} from 'ramda';
import {createReducer, noopAction} from 'warped-reducers';

import {defaultTo, unset} from '../utils/state';
import {FilterType} from '../shapes';
import {
  UpdatesListField,
  updatesColumnDefintions,
  TabFilterType,
} from '../containers/Updates/constants';

export const baseLens = lensProp('updates');

export const listState = compose(
  baseLens,
  lensProp('list'),
  defaultTo({
    updates: [],
    total: 0,
  }),
);

export const updatesState = compose(
  listState,
  lensProp('updates'),
);

export const totalUpdatesState = compose(
  listState,
  lensProp('total'),
);

export const isPendingState = compose(
  baseLens,
  lensProp('isPending'),
  defaultTo(false),
);

const filterState = compose(
  baseLens,
  lensProp('filters'),
);

export const activeTabFilterState = compose(
  baseLens,
  lensProp('activeTab'),
  defaultTo(TabFilterType.UNHANDLED),
);

export const columnFilterState = compose(
  filterState,
  lensProp('columnFilters'),
  defaultTo(
    updatesColumnDefintions.reduce((acc, column) =>
      column.filter.type === FilterType.NONE
        ? acc
        : {...acc, [column.prop]: column.filter.defaultValue}
    , {}),
  ),
);

const paginationState = compose(
  baseLens,
  lensProp('pagination'),
);

export const pageNumberState = compose(
  paginationState,
  lensProp('pageNumber'),
  defaultTo(0),
);

export const rowsPerPageState = compose(
  paginationState,
  lensProp('rowsPerPage'),
  defaultTo(10),
);

export const sortingState = compose(
  baseLens,
  lensProp('sorting'),
  defaultTo({
    sortBy: UpdatesListField.CREATED_AT,
    isSortAscending: false,
  }),
);

export const openResendDialogState = compose(
  baseLens,
  lensProp('openResendDialog'),
  defaultTo(false),
);

export const updateState = compose(
  baseLens,
  lensProp('update'),
);

// intial state for comparing if the user made any changes
export const updateInitialState = compose(
  baseLens,
  lensProp('updateInitial'),
);

export const timelineState = compose(
  updateState,
  lensPath(['portUpdate', 'xtx']),
);

export const updateHandledState = compose(
  updateState,
  lensProp('handled'),
);

export const selectedItemsState = compose(
  baseLens,
  lensProp('selected'),
  defaultTo([]),
);

export const archivalState = compose(
  baseLens,
  lensProp('archival'),
);

export const isArchivingState = compose(
  archivalState,
  lensProp('active'),
  defaultTo(false),
);

export const archivalErrorsState = compose(
  archivalState,
  lensProp('errors'),
  defaultTo([]),
);

export const isConfirmingArchivalState = compose(
  archivalState,
  lensProp('confirming'),
  defaultTo(false),
);

const updateSelectedItems = state => {
  const actual = map(prop('messageId'), view(updatesState, state));
  return over(selectedItemsState, intersection(actual), state);
};

const itemsWillBeFetched = compose(
  unset(listState),
  unset(pageNumberState),
  set(isPendingState, true),
);

const pageWillBeFetched = compose(
  unset(listState),
  set(isPendingState, true),
);

export const {reducer, types, actions} = createReducer('updates')({
  openResendDialog: always(set(openResendDialogState, true)),
  closeResendDialog: always(unset(openResendDialogState)),
  getUpdatesList: always(itemsWillBeFetched),
  setUpdatesList: payload => compose(
    updateSelectedItems,
    set(listState, payload),
    set(isPendingState, false),
  ),
  removeUpdate: id => compose(
    over(selectedItemsState, without([id])),
    over(updatesState, reject(propEq('messageId', id))),
    over(totalUpdatesState, dec),
  ),
  setTabFilter: payload => compose(
    itemsWillBeFetched,
    set(activeTabFilterState, payload),
  ),
  initColumnFilters: noopAction,
  restoreFilters: payload => over(columnFilterState, mergeLeft(payload)),
  setColumnFilter: ({columnDef, value}) => compose(
    itemsWillBeFetched,
    set(compose(columnFilterState, lensProp(columnDef.prop)), value),
  ),
  clearColumnFilter: columnDef => compose(
    itemsWillBeFetched,
    set(
      compose(columnFilterState, lensProp(columnDef.prop)),
      columnDef.filter.defaultValue,
    ),
  ),
  setRowsPerPage: payload => compose(
    itemsWillBeFetched,
    set(rowsPerPageState, payload),
  ),
  setPageNumber: payload => compose(
    set(pageNumberState, payload),
    itemsWillBeFetched,
  ),
  goToFirstOfNextPage: () => compose(
    over(pageNumberState, inc),
    pageWillBeFetched,
  ),
  goToLastOfPrevPage: () => compose(
    over(pageNumberState, dec),
    pageWillBeFetched,
  ),
  setSorting: payload => compose(
    itemsWillBeFetched,
    set(sortingState, payload),
  ),
  addSelection: id => over(selectedItemsState, append(id)),
  removeSelection: id => over(selectedItemsState, without([id])),
  selectAll: isHandled => state => set(
    selectedItemsState,
    view(updatesState, state)
    .filter(update => isHandled ? update.status === 'rejected' : true)
    .map(update => update.messageId),
    state
  ),
  selectNone: always(unset(selectedItemsState)),
  archiveSelected: always(compose(
    set(isArchivingState, true),
    unset(isConfirmingArchivalState),
  )),
  unarchiveSelected: always(compose(
    set(isArchivingState, true),
    unset(isConfirmingArchivalState),
  )),
  pushArchivalError: e => over(archivalErrorsState, append(e)),
  finishArchival: always(unset(archivalState)),
  confirmArchival: always(set(isConfirmingArchivalState, true)),
  setUpdateInitial: payload => compose(
    set(updateInitialState, payload),
    set(updateState, payload),
    set(isPendingState, false),
  ),
  setUpdate: payload => compose(
    set(updateState, payload),
  ),
  setUpdateTimeline: payload => over(
    timelineState,
    xtx => Object.assign({}, xtx, {...payload}),
  ),
  setUpdateError: always(set(isPendingState, false)),
  setUpdatesListError: always(set(isPendingState, false)),
  getUpdateById: always(set(isPendingState, true)),
  pushUpdate: always(set(isPendingState, true)),
  pushUpdateSuccess: always(compose(
    set(updateHandledState, true),
    set(isPendingState, false),
  )),
  pushUpdateError: always(set(isPendingState, false)),
  archiveUpdate: always(set(isPendingState, true)),
  archiveUpdateSuccess: always(compose(
    set(updateHandledState, true),
    set(isPendingState, false),
  )),
  archiveUpdateError: always(set(isPendingState, false)),
  unarchiveUpdate: always(set(isPendingState, true)),
  unarchiveUpdateSuccess: always(compose(
    set(updateHandledState, true),
    set(isPendingState, false),
  )),
  unarchiveUpdateError: always(set(isPendingState, false)),
});
